import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useMoralis, useNFTBalances } from 'react-moralis';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ErrorPage from '../components/Error';
import moment from 'moment';
import ListItem from '../components/ListItem';
import Collection from '../components/Collection';
import '../App.css';
import {
 ITEM_DETAIL_PAGE,
 START_INDEX,
 COLLECTION_LAST_INDEX,
 CREATOR_COLLECTION,
 PROFILE_PAGE,
 CREATE_COLLECTION_SCREEN,
 MY_COLLECTION_SCREEN,
 ETHEREUM_ERROR,
 NO_COLLECTION_ERROR,
} from '../libs/Constants';
import {
 getUserByToken,
 getUserAllCollectionByToken,
 getUserNFTList,
 getUserPurchasedNFTList,
 getUserFavouritesList,
 getUserSoldNFTList,
} from '../service/ApiService';
import UserData from '../service/UserData';
import SuccessModal from '../components/SuccessModal';
import PopUp from '../components/PopUp';
import Strings from '../libs/Strings';
import UserProfile from '../components/UserProfile';
import UploadNftModal from '../components/UploadNftModal';
import WalletItem from '../components/WalletItem';
import { GET_REQUEST } from '../libs/Environment';

function UerProfileScreen() {
 const { account } = useMoralis();
 const { data: NFTBalances } = useNFTBalances();
 const navigate = useNavigate();
 let params: any = useLocation();
 const [error, setError] = useState(false);
 const [startIndex, setStartIndex] = useState(START_INDEX);
 const [lastIndex, setLastIndex] = useState(COLLECTION_LAST_INDEX);
 const [collectionType, setCollectionType] = useState(Strings.MyNFT);
 const [loading, setLoading] = useState(false);
 const [userData, setUserData] = useState<any>([]);
 const [userCollection, setUserCollection] = useState<any>([]);
 const [createdItems, setCreatedItems] = useState<any>(0);
 const [purchasedItems, setPurchasedItems] = useState<any>(0);
 const [showWalletNft, setShowWalletNFT] = useState(false);
 const [showUploadNFTModal, setShowUploadNFTModal] = useState(false);
 const [myNftTabActive, setMyNftTabActive] = useState(true);
 const [loadMoreData, setLoadMoreData] = useState(false);
 const [showNoCollectionModal, setShowNoCollectionModal] = useState(false);
 const [hideLoad, setHideLoad] = useState(false);
 const [isCopy, setIsCopy] = useState(false);
 const [errorAlert, setErrorAlert] = useState(false);
 const [isChecked, setIsChecked] = useState(false);
 const [alertMessage, setAlertMessage] = useState('');
 const [showItemModal, setShowItemModal] = useState(false);
 const [walletNftData, setWalletNftData] = useState<any>({});
 const [completeUserCollection, setCompleteUserCollection] = useState<any>([]);
 const userProfileDetails: any = localStorage.getItem('userNFts');

 const userid = UserData.getUserId()
  ? UserData.getUserId()
  : localStorage.getItem('userId');
 const userToken = UserData.getUserToken()
  ? UserData.getUserToken()
  : localStorage.getItem('userToken');

 const onButtonPress = (type: any) => {
  let isExists: boolean = false;
  let itemIndex: any = null;
  completeUserCollection.forEach(async (item: any, i: any) => {
   if (item.type === type) {
    isExists = true;
    itemIndex = i;
   }
  });
  if (isExists) {
   setCollectionType(type);
   if (completeUserCollection[itemIndex].data.length == 0) {
    setHideLoad(true);
   } else {
    setHideLoad(false);
   }
   if (completeUserCollection[itemIndex].type === Strings.MyNFT) {
    setMyNftTabActive(true);
    setShowWalletNFT(false);
    var chkYes = document.getElementById('mintish') as HTMLInputElement;
    if (chkYes != null) {
     chkYes.checked = true;
    }

    setUserCollection(completeUserCollection[itemIndex].data);
    setIsChecked(true);
   } else {
    setMyNftTabActive(false);
    setShowWalletNFT(false);
    setUserCollection(completeUserCollection[itemIndex].data);
   }
  } else {
   if (params && params.state) {
    let routeParams = params.state;
    getUserCollectionByToken(routeParams[0].id, type, routeParams[0].token);
   } else {
    getUserCollectionByToken(userid, type, userToken);
   }
  }
 };
 const getMoreItems = async () => {
  setLoadMoreData(true);
  if (params && params.state) {
   let routeParams = params.state;
   setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
   if (collectionType === "My NFT's") {
    const response = await getUserNFTList(
     routeParams[0].id,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   }
   if (collectionType === 'Collection') {
    const response = await getUserAllCollectionByToken(
     routeParams[0].id,
     routeParams[0].token,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   } else if (collectionType === 'Favourites') {
    const response = await getUserFavouritesList(
     routeParams[0].id,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   } else if (collectionType === 'Purchased') {
    const response = await getUserPurchasedNFTList(
     routeParams[0].id,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
    }
   } else if (collectionType === 'Sold') {
    const response = await getUserSoldNFTList(
     routeParams[0].id,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   }
   return null;
  } else {
   setStartIndex(lastIndex + START_INDEX);
   setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
   if (collectionType === "My NFT's") {
    const response = await getUserNFTList(
     userid,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   }
   if (collectionType === 'Collection') {
    const response = await getUserAllCollectionByToken(
     userid,
     userToken,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   } else if (collectionType === 'Favourites') {
    const response = await getUserFavouritesList(
     userid,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   } else if (collectionType === 'Purchased') {
    const response = await getUserPurchasedNFTList(
     userid,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   } else {
    const response = await getUserSoldNFTList(
     userid,
     lastIndex + START_INDEX,
     lastIndex + COLLECTION_LAST_INDEX
    );
    if (response) {
     let arr = response[0];
     if (arr.length == 0) {
      setHideLoad(true);
     }
     setUserCollection([...userCollection, ...arr]);
     setLoadMoreData(false);
    } else {
     setError(true);
     setLoadMoreData(false);
    }
   }
   return null;
  }
 };

 const getUserByTok = async (id: any, token: any) => {
  const response = await getUserByToken(id, token);
  if (response) {
   setUserData(response[0]);
   UserData.updateUserDetail(response[0]);
   localStorage.setItem('userDetails', JSON.stringify(response[0]));
  } else {
   setError(true);
  }
  return null;
 };

 const CheckUserCollection = () => {
  if (Object.keys(UserData.getuserNFt()).length > 0) {
   let userCollection = UserData.getuserNFt();
   if (userCollection.userCollection > 0) {
    setShowUploadNFTModal(true);
   } else {
    setShowNoCollectionModal(true);
   }
  } else if (userProfileDetails) {
   let userCollection = userProfileDetails;
   if (userCollection.userCollection > 0) {
    setShowUploadNFTModal(true);
   } else {
    setShowNoCollectionModal(true);
   }
  }
 };

 const getUserCollectionByToken = async (Id: any, Type: any, token: any) => {
  setCollectionType(Type);
  setLoading(true);
  setLastIndex(COLLECTION_LAST_INDEX);
  setHideLoad(false);
  if (Type === "My NFT's") {
   setShowWalletNFT(false);
   setMyNftTabActive(true);
   setIsChecked(true);
   var chkYes = document.getElementById('mintish') as HTMLInputElement;
   if (chkYes != null) {
    chkYes.checked = true;
   }
   const response = await getUserNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const purchaseResponse = await getUserPurchasedNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const response2 = await getUserAllCollectionByToken(
    Id,
    token,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: "My NFT's",
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setCreatedItems(response[1][0].total_items);
     setPurchasedItems(purchaseResponse[1][0].total_items);
     let UserNft: object = {
      createdItems: response[1][0].total_items,
      purchasedItmes: purchaseResponse[1][0].total_items,
      userCollection: response2[1][0].total_collections,
     };
     UserData.updateUserNft(UserNft);
     localStorage.setItem('userNFts', JSON.stringify(UserNft));
     setLoading(false);
    } else {
     let obj: any = {
      type: "My NFT's",
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     let UserNft: object = {
      createdItems: response[1][0].total_items,
      purchasedItmes: purchaseResponse[1][0].total_items,
      userCollection: response2[1][0].total_collections,
     };
     UserData.updateUserNft(UserNft);
     localStorage.setItem('userNFts', JSON.stringify(UserNft));
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Collection') {
   setMyNftTabActive(false);
   setShowWalletNFT(false);
   const response1 = await getUserNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const response = await getUserAllCollectionByToken(
    Id,
    token,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const purchaseResponse = await getUserPurchasedNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Collection',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setCreatedItems(response1[1][0].total_items);
     setPurchasedItems(purchaseResponse[1][0].total_items);
     let userNFT = UserData.getuserNFt();
     if (userNFT.userCollection !== null) {
      userNFT.userCollection = response[1][0].total_collections;
     }
     UserData.updateUserNft(userNFT);
     let userNftDetails = JSON.parse(localStorage.getItem('userNFts') as any);
     if (userNftDetails && userNftDetails !== null) {
      userNftDetails.userCollection = response[1][0].total_collections;
      localStorage.setItem('userNFts', JSON.stringify(userNftDetails));
     }
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Collection',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Favourites') {
   setMyNftTabActive(false);
   setShowWalletNFT(false);
   const response1 = await getUserNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const response = await getUserFavouritesList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const purchaseResponse = await getUserPurchasedNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Favourites',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setCreatedItems(response1[1][0].total_items);
     setPurchasedItems(purchaseResponse[1][0].total_items);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Favourites',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Purchased') {
   setShowWalletNFT(false);
   setMyNftTabActive(false);
   const response = await getUserPurchasedNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Purchased',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Purchased',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else {
   setMyNftTabActive(false);
   setShowWalletNFT(false);
   const response = await getUserSoldNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Sold',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Sold',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  }
  return null;
 };

 useEffect(() => {
  setLoading(true);
  var chkYes = document.getElementById('mintish') as HTMLInputElement;
  if (chkYes != null) {
   chkYes.checked = true;
  }
  if (params && params.state) {
   let Type = params.state;
   getUserByTok(Type[0].id, Type[0].token);
   getUserCollectionByToken(Type[0].id, Type[0].type, Type[0].token);
  } else if (localStorage.getItem('userId')) {
   let id = localStorage.getItem('userId');
   let token = localStorage.getItem('userToken');
   getUserByTok(id, token);
   getUserCollectionByToken(id, "My NFT's", token);
  } else {
   navigate('/');
  }
 }, []);

 const showMintishNFt = () => {
  setShowWalletNFT(false);
  setIsChecked(true);
  var chkYes = document.getElementById('Wallet') as HTMLInputElement;
  if (chkYes != null) {
   chkYes.checked = false;
  }
  if (params && params.state) {
   let Type = params.state;
   getUserCollectionByToken(Type[0].id, Type[0].type, Type[0].token);
  } else {
   let id = localStorage.getItem('userId');
   let token = localStorage.getItem('userToken');
   getUserByTok(id, token);
   getUserCollectionByToken(id, "My NFT's", token);
  }
 };
 const getImagetype = async (data: any) => {
  let newData: any = [];
  for (let index = 0; index < data.length; index++) {
   try {
    if (data[index] && data[index].token_uri !== null) {
     let category: String = '';
     const response = await fetch(data[index].token_uri, {
      method: GET_REQUEST,
     });
     const json = await response.json();
     const response1 = await fetch(json.image, {
      method: GET_REQUEST,
     });
     if (response1.headers.get('content-type') == 'audio/mpeg') {
      category = 'Music';
     } else if (response1.headers.get('content-type') == 'video/mp4') {
      category = 'Video';
     } else {
      category = 'Image';
     }
     newData.push({
      name: json.name,
      description: json.description,
      createdAt: data[index].synced_at,
      imageUrl: json.image,
      Category: category,
     });
    }
   } catch (error) {
    console.log('error', error);
    if (
     error == 'TypeError: Failed to fetch' ||
     error == "TypeError: Cannot read properties of undefined (reading 'image')"
    ) {
     setErrorAlert(true);
     setAlertMessage(ETHEREUM_ERROR);
     break;
    }
   }
  }
  setUserCollection(newData);
  setLoading(false);
 };
 const showWalletNFt = () => {
  setIsChecked(false);
  setShowWalletNFT(true);
  if (NFTBalances != null) {
   setCollectionType(Strings.MyNFT);
   setLoading(true);
   getImagetype(NFTBalances?.result);
  }
 };

 const ShowModalOnNFtClick = (item: any) => {
  setWalletNftData(item);
  setShowItemModal(true);
 };

 return (
  <div className="darker">
   <div className="darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="darker">
      <Header
       favourite={() => onButtonPress('Favourites')}
       collection={() => onButtonPress('Collection')}
       userInfo={userData}
      />

      <div className="breadcumb-area clearfix creator-Breadcumb">
       <div className="breadcumb-content">
        <div className="container h-100">
         <div className="row h-100 align-items-center">
          <div className="col-12">
           <nav aria-label="breadcrumb" className="breadcumb--con text-center">
            <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
             {Strings.AuthorProfile}
            </h2>
            <ol
             className="breadcrumb justify-content-center wow fadeInUp"
             data-wow-delay="0.4s"
            >
             <li className="breadcrumb-item">
              <Link to="/">{Strings.Home}</Link>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
              {Strings.AuthorProfile}
             </li>
            </ol>
           </nav>
          </div>
         </div>
        </div>
       </div>
      </div>

      <section className="blog-area1 section-padding-100">
       <div className="container">
        <div className="row">
         <UserProfile
          UserDetails={userData}
          userNFTdetail={{
           createdItems: createdItems,
           purchasedItmes: purchasedItems,
          }}
          Account={account}
          onCopy={() => setIsCopy(true)}
         />

         <div className="col-12 col-md-9 colCenter">
          <div className="dream-projects-menu dreamProject mb-50">
           <div className="text portfolio-menu" id="changeNfts">
            {CREATOR_COLLECTION.map((item) => {
             return (
              <div
               key={item.key}
               className={
                collectionType === item.type
                 ? 'collectionTypeButton'
                 : 'collectionTypeButtonDisable'
               }
               onClick={() => onButtonPress(item.type)}
              >
               <p className="collectionTitle">{item.type}</p>
              </div>
             );
            })}
            {myNftTabActive ? (
             <div className="radioBtns">
              <div className="radioBtns-div">
               <div className="radio1">
                <label>
                 <input
                  type="radio"
                  className="mintishSelect"
                  id="mintish"
                  checked={isChecked}
                  onClick={() => showMintishNFt()}
                 />
                 <span>{Strings.Mintish}</span>
                </label>
               </div>
              </div>
              <div className="radioBtns-div">
               <div className="radio1">
                <label>
                 <input
                  type="radio"
                  className="walletSelect"
                  id="Wallet"
                  onClick={() => showWalletNFt()}
                 />
                 <span>{Strings.Wallet}</span>
                </label>
               </div>
              </div>
             </div>
            ) : null}
           </div>

           <div className="moreBtnOuter" id="createUploadBtn">
            <div
             onClick={() => navigate(CREATE_COLLECTION_SCREEN)}
             className="btn more-btn fadeInUp btn__div "
             data-wow-delay="0.6s"
            >
             <span>{Strings.CreateCollection}</span>
            </div>
            <div
             onClick={() => CheckUserCollection()}
             className={
              loading
               ? 'btn more-btn fadeInUp disabled upload__Btn'
               : 'btn more-btn fadeInUp upload__Btn'
             }
             data-wow-delay="0.6s"
            >
             <span>{Strings.UploadNFT}</span>
            </div>
           </div>
          </div>

          <div className="row" id="myprofile-collections">
           <div className="container">
            {loading ? (
             <div className="amountS2">
              <div id="preloaderloadAreaSpecific ">
               <div className="preload-contentAreaSpecific ">
                <div className="amountS2">
                 <img
                  className="animation-loader"
                  src="../img/icons/animationloader.gif"
                  alt=""
                 />
                </div>
               </div>
               {showWalletNft ? (
                <div className="noData">{Strings.Iswearitsalmostdone} </div>
               ) : null}
              </div>
             </div>
            ) : userCollection.length >= 1 ? (
             <div className="container2">
              {userCollection.map((item: any, index: any) => {
               return collectionType === 'Collection' ? (
                <Collection
                 key={item.collection_id}
                 collectionImage={item.image_url}
                 onCardClick={() =>
                  navigate(MY_COLLECTION_SCREEN, {
                   state: [
                    {
                     id: item.collection_id,
                     isUserProfileScreen: true,
                    },
                   ],
                  })
                 }
                 ownerName={
                  userData.firstname === null
                   ? Strings.MintishUser
                   : userData.firstname
                 }
                 collectionName={item.collection_name}
                />
               ) : (
                <>
                 <ListItem
                  userProfileScreen={{ paddingLeft: '5px' }}
                  isProfileScreen={true}
                  key={item.id}
                  category={showWalletNft ? item.Category : item.category}
                  ownerName={
                   showWalletNft
                    ? userData.firstname === null
                      ? Strings.MintishUser
                      : userData.firstname
                    : item.ownername === null
                    ? Strings.MintishUser
                    : item.ownername
                  }
                  onWalletClick={() =>
                   showWalletNft ? ShowModalOnNFtClick(item) : null
                  }
                  ownerImage={
                   showWalletNft
                    ? userData.imageurl === null
                      ? 'img/art-work/user.png'
                      : userData.imageurl
                    : item.ownerimage
                    ? item.ownerimage
                    : 'img/authors/round2.jpg'
                  }
                  itemName={showWalletNft ? item.name : item.itemname}
                  itemPrice={item.itemamount}
                  itemCreatedTime={
                   showWalletNft
                    ? moment
                       .utc(item.createdAt)
                       .local()
                       .startOf('seconds')
                       .fromNow()
                    : moment
                       .utc(item.createdat)
                       .local()
                       .startOf('seconds')
                       .fromNow()
                  }
                  itemLike={0}
                  itemBlockChain={item.blockchain}
                  itemImage={showWalletNft ? item.imageUrl : item.tokenpath}
                  navigateToProfile={PROFILE_PAGE}
                  isShowWalletNft={showWalletNft}
                  navigateToDetailPage={
                   showWalletNft ? PROFILE_PAGE : ITEM_DETAIL_PAGE
                  }
                  routesIs={{ Id: item.id }}
                 />
                </>
               );
              })}
              ;
             </div>
            ) : (
             <>
              {loading ? null : (
               <div className="noData">{Strings.NODATAFOUND}</div>
              )}
              :
             </>
            )}
            {showUploadNFTModal ? (
             <UploadNftModal
              onCrossButtonPress={() => setShowUploadNFTModal(false)}
             />
            ) : null}

            {showWalletNft ? null : hideLoad || loading ? null : (
             <div className="col-12 col-lg-12 text-center">
              {loadMoreData ? (
               <div id="SpecificAreaLoaderUserProfile">
                <div>
                 <img
                  className="animation-loader2"
                  src="../img/icons/animationloader.gif"
                  alt=""
                 />
                </div>
               </div>
              ) : null}
              <div
               className="btn more-btn fadeInUp showMorebtn1"
               data-wow-delay="0.6s"
               onClick={() => getMoreItems()}
              >
               {Strings.ShowMore}
              </div>
             </div>
            )}
           </div>
          </div>
         </div>
        </div>
       </div>

       {showNoCollectionModal ? (
        <SuccessModal
         style={true}
         isUserProfile={true}
         close={() => setShowNoCollectionModal(false)}
         ModalText={NO_COLLECTION_ERROR}
        />
       ) : null}
       {errorAlert ? (
        <PopUp
         isProfileScreen={true}
         message={alertMessage}
         navigate={() => {
          setErrorAlert(false);
         }}
        />
       ) : null}
       {isCopy ? (
        <PopUp
         message={Strings.Copied}
         navigate={() => {
          setIsCopy(false);
         }}
        />
       ) : null}

       {showItemModal ? (
        <WalletItem
         onCrossClick={() => setShowItemModal(false)}
         Category={walletNftData.Category}
         Source={walletNftData.imageUrl}
        />
       ) : null}
      </section>

      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default UerProfileScreen;
