import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import {
 getItemDetail,
 buyNFT,
 getItemsByType,
 listNFT,
 soldNFT,
 getHighestCollection,
 sellNFT,
 getUserById,
 onPlaceBid,
 CheckItemStatus,
 updateItemStatus,
} from '../service/ApiService';
import { useMoralis, useNativeBalance } from 'react-moralis';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { getEllipsisTxt } from '../helpers/formatters';
import { getExplorer } from '../helpers/networks';
import ErrorPage from '../components/Error';
import '../App.css';
import Header from '../components/Header';
import Item from '../components/Item';
import Collection from '../components/Collection';
import {
 WALLET_CONNECT,
 DEFAULT_INDEX,
 ITEM_DETAIL_PAGE,
 MY_COLLECTION_SCREEN,
 CREATOR_PROFILE_SCREEN,
 PROFILE_PAGE,
 BUY_ABI,
 OWNER_WALLET_ADDRESS,
 OFFER_REQUEST,
 USER_DENIED_TRANSACTION,
 BLOCKS_ERROR,
 UNSUCCESSFUL_TRANSACTION,
 MORE_RELEVANT_LAST_INDEX,
 MORE_RELEVANT_FIRST_INDEX,
 API_SUCCESS_MSG,
 MORALIS_ERROR_CODE,
 USER_DOES_NOT_EXIST,
 NFT_NOT_AVAILABLE,
} from '../libs/Constants';
import UserData from '../service/UserData';
import ReactAudioPlayer from 'react-audio-player';
import CustomAlert from '../components/CustomAlert';
import WrongEventModal from '../components/WrongEvent';
import { abiBuy } from '../libs/Contracts';
import PopUp from '../components/PopUp';
import SuccessModal from '../components/SuccessModal';
import Strings from '../libs/Strings';

function ItemDetailScreen() {
 const navigate = useNavigate();
 let params: any = useLocation();
 const [itemData, setItemData] = React.useState<any>([]);
 const [isPopularCollectionLoading, setIsPopularCollectionLoading] =
  useState(false);
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [popularCollection, setPopularCollection] = useState([]);
 const [loading, setLoading] = useState(false);
 const [bid, setBid] = useState<any>(0);
 const [error, setError] = useState(false);
 const [myNFT, setMyNFT] = useState<any>(false);
 const [isImageLoaded, setIsImageLoaded] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [relatedItemsData, setRelatedItemsData] = React.useState([]);
 const { isAuthenticated, web3, chainId } = useMoralis();
 const [showSuccess, setShowSuccess] = useState(false);
 const [showWrong, setShowWrong] = useState(false);
 const [UserDetails, setUserDetails] = useState<any>({});
 const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);
 const [isNavigateHome, setIsNavigateHome] = useState(false);
 const [isValidAmount, setIsValidAmount] = useState(false);
 const { data: balance } = useNativeBalance();
 const itemId = params.state.Id;
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );
 const userProfileDetails: any = localStorage.getItem('userDetails') as any;
 const contract = new (web3 as any).eth.Contract(abiBuy, BUY_ABI);

 const getItems = async (id: any) => {
  const response = await getItemDetail(id);
  if (response) {
   setMyNFT(
    response[DEFAULT_INDEX][DEFAULT_INDEX].userid == userLoginDetail?.userId
   );
   setItemData(response[DEFAULT_INDEX][DEFAULT_INDEX]);
   getRelatedItems(response[DEFAULT_INDEX][DEFAULT_INDEX].category);
  }
 };

 const listing = async () => {
  setLoading(true);
  try {
   const priceHexString = (web3 as any).utils.numberToHex(
    1000000000000000000 * itemData.itemamount
   );
   const receipt = await contract.methods
    .putItemForSale(itemData.tokenid, priceHexString, OWNER_WALLET_ADDRESS)
    .send({ from: itemData.walletaddress });
   const gasFee = (
    (receipt.effectiveGasPrice * receipt.gasUsed) /
    1000000000000000000
   ).toFixed(6);
   const saleId = receipt.events.itemAddedForSale.returnValues.id;
   const response1 = await listNFT(
    itemData.id,
    itemData.tokenid,
    saleId,
    userLoginDetail?.userToken,
    gasFee
   );
   if (itemData.collectionid !== null) {
    const response = await sellNFT(
     itemData.collectionid,
     userLoginDetail?.userToken
    );
   }
   setLoading(false);
   setShowSuccess(true);
  } catch (error: unknown) {
   setLoading(false);
   console.log('error ', error);
   if (JSON.stringify((error as any).code) == MORALIS_ERROR_CODE) {
    setValidationMsg(USER_DENIED_TRANSACTION);
    setCreateValidItemModal(true);
   } else if (error == BLOCKS_ERROR) {
    setValidationMsg(UNSUCCESSFUL_TRANSACTION);
    setCreateValidItemModal(true);
   } else {
    alert(error);
   }
  }
 };

 const navigationHome = () => {
  setShowSuccess(false);
  navigate('/');
 };

 const getRelatedItems = async (category: any) => {
  const response = await getItemsByType(
   category,
   MORE_RELEVANT_FIRST_INDEX,
   MORE_RELEVANT_LAST_INDEX
  );
  if (response) {
   setRelatedItemsData(response[DEFAULT_INDEX]);
  } else {
   setError(true);
  }
 };

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserDetails(UserData.getuserDetail());
  } else if (JSON.parse(userProfileDetails)) {
   setUserDetails(JSON.parse(userProfileDetails));
  }
 };

 useEffect(() => {
  getUserprofile();
 }, []);

 const getPopularCollection = async () => {
  if (Object.keys(UserData.getPopularCollection()).length > 0) {
   setPopularCollection(UserData.getPopularCollection());
  } else {
   setIsPopularCollectionLoading(true);
   const response = await getHighestCollection();
   if (response && response.length > 0) {
    setPopularCollection(response);
    UserData.updatePopularCollections(response);
    setIsPopularCollectionLoading(false);
   } else {
    setIsPopularCollectionLoading(false);
   }
  }
 };

 useEffect(() => {
  setLoading(true);
  getItems(itemId ? itemId : null);
  getPopularCollection();
  setLoading(false);
 }, []);

 const buyNow = async () => {
  if (isAuthenticated) {
   let str = balance.formatted;
   let price = (str as any).substr(0, (str as any).indexOf(' '));
   if (itemData.itemamount > price) {
    setShowWrong(true);
   } else {
    setLoading(true);
    try {
     const response = await CheckItemStatus(
      '1',
      itemData.id,
      userLoginDetail?.userToken
     );
     if (response.length == 0) {
      const response = await updateItemStatus(
       '1',
       itemData.id,
       userLoginDetail?.userToken
      );
      if (response[0].msg == 'success') {
       const priceHexString = (web3 as any).utils.numberToHex(
        1000000000000000000 * itemData.itemamount
       );
       const receipt = await contract.methods.buyItem(itemData.saleid).send({
        value: priceHexString,
        from: window.ethereum.selectedAddress,
       });
       const gasFee = (
        (receipt.effectiveGasPrice * receipt.gasUsed) /
        1000000000000000000
       ).toFixed(6);

       let buyDetail = {
        UserIdFrom: itemData.userid,
        UserIdTo: userLoginDetail?.userId,
        ItemId: itemData.id,
        WalletAddressFrom: itemData.walletaddress,
        WalletAddressTo: window.ethereum.selectedAddress,
        Signature: receipt.transactionHash,
        Amount: itemData.itemamount,
        GasFee: gasFee,
        CollectionId: itemData.collectionid,
       };
       const response = await buyNFT(buyDetail, userLoginDetail?.userToken);
       const response1 = await soldNFT(
        itemData.id,
        itemData.tokenid,
        itemData.saleid,
        userLoginDetail?.userToken
       );
       setLoading(false);
       setShowSuccess(true);
      }
     } else {
      setValidationMsg(NFT_NOT_AVAILABLE);
      setCreateValidItemModal(true);
      setLoading(false);
     }
    } catch (error: unknown) {
     setLoading(false);
     if (JSON.stringify((error as any).code) == MORALIS_ERROR_CODE) {
      const response = await updateItemStatus(
       '0',
       itemData.id,
       userLoginDetail?.userToken
      );
      setValidationMsg(USER_DENIED_TRANSACTION);
      setCreateValidItemModal(true);
     } else if (error == BLOCKS_ERROR) {
      setValidationMsg(UNSUCCESSFUL_TRANSACTION);
      setCreateValidItemModal(true);
     } else {
      alert(error);
     }
    }
   }
  } else {
   navigate(WALLET_CONNECT);
  }
 };

 const navigateToCreatorProfile = () => {
  navigate(CREATOR_PROFILE_SCREEN, {
   state: { CreatorId: itemData.userid },
  });
 };

 const checkUserExists = async () => {
  const response = await getUserById(itemData.userid);
  if (response && response[0].length == 0) {
   setValidationMsg(USER_DOES_NOT_EXIST);
   setCreateValidItemModal(true);
  } else if (isAuthenticated) {
   let localId = userLoginDetail?.userId;
   if (localId == itemData.userid) {
    navigate(PROFILE_PAGE);
   } else {
    navigateToCreatorProfile();
   }
  } else {
   navigateToCreatorProfile();
  }
 };

 const navigateToCollection = () => {
  navigate(MY_COLLECTION_SCREEN, {
   state: [
    {
     id: itemData.collectionid,
     isCreatorProfile: true,
     CreatorId: itemData.userid,
    },
   ],
  });
 };

 const onMakeOffer = async () => {
  let str = balance.formatted;
  let price = (str as any).substr(0, (str as any).indexOf(' '));
  if (bid > price) {
   setShowWrong(true);
  } else {
   const response = await onPlaceBid(
    bid,
    itemData.id,
    window.ethereum.selectedAddress
   );
   if (response && response[DEFAULT_INDEX].msg == API_SUCCESS_MSG) {
    setIsNavigateHome(true);
   }
  }
 };

 const onPlaceBidClick = () => {
  if (isAuthenticated) {
   setShowPlaceBidModal(true);
  } else {
   navigate(WALLET_CONNECT);
  }
 };

 const onCollectionClick = () => {
  if (isAuthenticated) {
   let localId = userLoginDetail?.userId;
   if (itemData.userid == localId) {
    navigate(MY_COLLECTION_SCREEN, {
     state: [
      {
       id: itemData.collectionid,
       isUserProfileScreen: true,
      },
     ],
    });
   } else {
    navigateToCollection();
   }
  } else {
   navigateToCollection();
  }
 };

 const onOkButttonPress = () => {
  setCreateValidItemModal(false);
  if (isNavigateHome) {
   navigate('/');
  }
 };

 const onTextInputChange = (txt: any) => {
  if (txt && txt > 0) {
   setIsValidAmount(true);
   setBid(txt);
  } else {
   setIsValidAmount(false);
  }
 };

 return (
  <div className="App darker">
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       {' '}
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}
   <div className="darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div>
      <Header userInfo={UserDetails} />
      <div className="breadcumb-area clearfix contactBreadcumb">
       <div className="breadcumb-content">
        <div className="container h-100">
         <div className="row h-100 align-items-center">
          <div className="col-12">
           <nav aria-label="breadcrumb" className="breadcumb--con text-center">
            <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
             {Strings.ItemDetails}
            </h2>
            <ol
             className="breadcrumb justify-content-center wow fadeInUp"
             data-wow-delay="0.4s"
            >
             <li className="breadcrumb-item">
              <Link to="/">{Strings.Home}</Link>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
              {Strings.ItemDetails}
             </li>
            </ol>
           </nav>
          </div>
         </div>
        </div>
       </div>
      </div>
      <section className="section-padding-100">
       <div className="container">
        <div className="row">
         <div className="col-12 col-lg-4" id="detailImage">
          <div className="detailed-img">
           {itemData.category === 'Music' ? (
            <div className="detailImg image_Details">
             <ReactAudioPlayer
              src={itemData.tokenpath}
              controls
              controlsList="nodownload"
             />
            </div>
           ) : itemData.category === 'Video' ? (
            <div className="detailImg">
             <video
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
             >
              <source src={itemData.tokenpath} type="video/mp4" />
             </video>
            </div>
           ) : (
            <>
             {isImageLoaded ? (
              <img src={itemData.tokenpath} alt="" className="detailImg" />
             ) : (
              <div className="detailImg">
               <div
                className="loading_for_details"
                id="preloaderloadForDeatail"
               >
                <div className="preload-contentAreaSpecific">
                 <div>
                  <img
                   className="animation-loader"
                   src="../img/icons/animationloader.gif"
                   alt=""
                  />
                 </div>
                </div>
               </div>{' '}
               <img
                alt=""
                className="image_loaded"
                src={itemData.tokenpath}
                onLoad={() => setIsImageLoaded(true)}
               />
              </div>
             )}
            </>
           )}
          </div>
         </div>

         <div className="col-12 col-lg-8 mt-s">
          <div className="sidebar-area">
           <div className="donnot-miss-widget">
            <div className="who-we-contant">
             <div className="filers-list filerList3">
              <div className="filter-item">
               <img src="img/icons/f1.png" alt="" />
               {itemData.category}
              </div>
              <div className="author-item autItem2 ">
               <p className="author-earn mb-0">{Strings.CreatedBy}</p>
               <div className="author-info autInfo2">
                <div onClick={() => checkUserExists()}>
                 <h5 className="author-name auth_name">
                  {itemData.creatorname === null
                   ? Strings.MintishUser
                   : itemData.creatorname}
                 </h5>
                </div>
               </div>
              </div>
             </div>
             <h4 className="fadeInUp aboutText aboutt4" data-wow-delay="0.3s">
              {itemData.itemname}
             </h4>
            </div>
            <div className="mb-15 gray-text">
             <p className="w-text mr-15 priceText aboutt4">
              {Strings.Price}
              <img src="img/art-work/ethimage.png" alt="" />
              <span className="sec">
               {itemData.itemamount}
               <span className="price"></span>
              </span>
             </p>
             <p className="content2">
              {' '}
              {Strings.Collection}
              {itemData.collectionname ? (
               <span
                className="sec2 sec-tion"
                onClick={() => onCollectionClick()}
               >
                {itemData.collectionname}
               </span>
              ) : (
               <span className="sec2">{Strings.NoCollectionFound}</span>
              )}
             </p>
             <p className="content2">{itemData.itemdesc}</p>
            </div>

            <div className="details-list detailsList">
             <div className="details detailsItem">
              <p>
               {Strings.ContractAddress}
               <span>
                <a
                 href={`${getExplorer(chainId)}/address/${
                  itemData.contractaddress
                 }`}
                 target="_blank"
                 rel="noreferrer"
                >
                 {getEllipsisTxt(itemData.contractaddress, 6)}{' '}
                </a>{' '}
               </span>
              </p>

              <p>
               {Strings.TokenID}
               <span className="tokenId">{itemData.tokenid}</span>
              </p>
             </div>
             <div className="details detailsItem">
              <p>
               {Strings.TokenStandard}:<span>{Strings.ERC}</span>
              </p>
              <p>
               {Strings.Blockchain}
               <span>{Strings.ETH}</span>
              </p>
             </div>
            </div>
            <div className="author-item mb-30">
             <div className="author-img ml-0">
              <img
               className="aut__img"
               src={
                itemData.ownerimage === null
                 ? 'img/art-work/user.png'
                 : itemData.ownerimage
               }
               width="50"
               alt=""
              />
             </div>
             <div className="author-info disabled">
              <a href="profile.html">
               <h5 className="author-name">
                {itemData.ownername === null
                 ? Strings.MintishUser
                 : itemData.ownername}
               </h5>
              </a>
              <p className="author-earn mb-0">{Strings.ItemOwner}</p>
             </div>

             <div className="col-md-9 buttonOuter">
              {myNFT ? (
               <div>
                {itemData.tokenid == 0 ? (
                 <h5 className="author-name">{Strings.PleasecontactAdmin}</h5>
                ) : itemData.status == 'nft Created' ? (
                 <button
                  className="btn more-btn more-btn2"
                  onClick={() => listing()}
                 >
                  {Strings.SellNow}
                 </button>
                ) : null}
               </div>
              ) : (
               <div>
                {itemData.status == 'approved' ? (
                 itemData.offerflag == '0' ? (
                  <div className="col-md-9 buttonOuter">
                   {/* TEMP */}
                   {/* <button
                    className="btn placeBtn more-btn"
                    onClick={() => onPlaceBidClick()}
                   >
                    {Strings.PlaceBid}
                   </button> */}
                   <button
                    className="btn more-btn more-btn2"
                    onClick={() => buyNow()}
                   >
                    {Strings.BuyNow}
                   </button>
                  </div>
                 ) : (
                  <div className="col-md-9 buttonOuter">
                   {/* TEMP */}
                   {/* <button
                    className="btn placeBtn more-btn disabled"
                    onClick={() => onPlaceBidClick()}
                   >
                    {Strings.PlaceBid}
                   </button> */}
                   <button
                    className="btn more-btn more-btn2 disabled"
                    onClick={() => buyNow()}
                   >
                    {Strings.BuyNow}
                   </button>
                  </div>
                 )
                ) : null}
               </div>
              )}
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </section>

      <section
       className="features section-padding-0-100 "
       id="exploreMoreSection"
      >
       <div className="container">
        <div className="section-heading text-center">
         <div
          className="dream-dots justify-content-center fadeInUp"
          data-wow-delay="0.2s"
         >
          <span>{Strings.MoreRelevant}</span>
         </div>
         <h2 className="fadeInUp" data-wow-delay="0.3s">
          {Strings.MoreRelevant}
         </h2>
        </div>
        <div className="row">
         <div className="col-12 col-md-12" id="topCollectionpanels">
          <div className="dream-projects-menu mb-50">
           <div className="text portfolio-menu"></div>
          </div>
          <div className="wholeRow">
           <div className="list4" id="c2">
            <div className="row align-items-center">
             <div className="collection-items2">
              {relatedItemsData.length > 0 ? (
               relatedItemsData.map((item: any, index: any) => {
                return (
                 <Item
                  key={item.id}
                  category={item.category}
                  itemName={item.itemname}
                  ownerName={
                   item.ownername === null
                    ? Strings.MintishUser
                    : item.ownername
                  }
                  itemImage={item.tokenpath}
                  navigateToDetailPage={ITEM_DETAIL_PAGE}
                  routesIs={{ Id: item.id }}
                  onImageClick={() => getItems(item.id)}
                 />
                );
               })
              ) : (
               <div className="noData">{Strings.NODATAFOUND}</div>
              )}
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
       {showPlaceBidModal ? (
        <div className="modalOverlay">
         <div className="requestModal2">
          <div className="modalHeader">
           <span
            className="crossBtnOuter closeIconOuter cross-Icon"
            onClick={() => setShowPlaceBidModal(false)}
           >
            <img src="img/art-work/crossBtn.png" alt="" />
           </span>
          </div>
          <div className="align-items-center amount-sec">
           <img
            className="placeBid-modalimg"
            src="../img/art-work/placeBidImg.png"
            alt=""
           />
           <img
            className="placeBidmodal-bgimg"
            src="../img/art-work/Vector11.png"
            alt=""
           />
          </div>
          <div className="inputHeading">
           <p className="input-for in__put">{Strings.EnterAmount}</p>
           <input
            type="number"
            className="inputClass"
            placeholder="Enter your amount"
            onChange={(e: any) => onTextInputChange(e.target.value)}
           />
          </div>
         </div>
         <div>
          <a
           className={
            isValidAmount
             ? 'btn more-btn exploreBtn'
             : 'btn more-btn exploreBtn disabled'
           }
           onClick={() => onMakeOffer()}
          >
           {Strings.MAKEOFFER}
          </a>
         </div>
        </div>
       ) : null}

       {showSuccess ? (
        <CustomAlert
         onCrossButtonClick={() => navigationHome()}
         message={'Success'}
         navigate={() => navigationHome()}
        />
       ) : null}
       {showWrong ? (
        <WrongEventModal
         message={'Insufficient Amount in Wallet'}
         navigate={() => setShowWrong(false)}
        />
       ) : null}
      </section>
      <section className="section-padding-0-70 clearfix">
       <div className="container">
        <div className="section-heading text-center">
         <div
          className="dream-dots justify-content-center fadeInUp"
          data-wow-delay="0.2s"
         >
          <span>{Strings.OurTopCollection}</span>
         </div>
         <h2 className="fadeInUp" data-wow-delay="0.3s">
          {Strings.PopularCollections}
         </h2>
        </div>

        <div className="row">
         {isPopularCollectionLoading ? (
          <div id="preloaderloadAreaSpecific">
           <div className="preload-contentAreaSpecific">
            <div>
             <img
              className="animation-loader"
              src="../img/icons/animationloader.gif"
              alt=""
             />
            </div>
           </div>
          </div>
         ) : popularCollection.length >= 1 ? (
          <div className="container2">
           {popularCollection.map((item: any, index: any) => {
            return (
             <>
              <Collection
               key={item.collection_id}
               collectionImage={item.image_url}
               onCardClick={() =>
                navigate(MY_COLLECTION_SCREEN, { state: item.collection_id })
               }
               ownerName={
                item.ownername === null ? Strings.MintishUser : item.ownername
               }
               collectionName={item.collection_name}
              />
             </>
            );
           })}
          </div>
         ) : (
          <>
           {isPopularCollectionLoading ? null : (
            <div className="noData">{Strings.NODATAFOUND}</div>
           )}
           :
          </>
         )}
        </div>
       </div>
      </section>

      {isNavigateHome ? (
       <SuccessModal
        close={() => setIsNavigateHome(false)}
        modalImage={'img/art-work/successbgimg.svg'}
        ModalText={OFFER_REQUEST}
        buttonText={Strings.EXPLOREMORE}
        navigate={() => navigate('/')}
       />
      ) : null}
      {createValidItemModal ? (
       <PopUp
        message={validationMsg}
        navigate={() => {
         onOkButttonPress();
        }}
       />
      ) : null}
      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default ItemDetailScreen;
