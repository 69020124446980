import { useEffect, useState } from 'react';
import UserData from '../service/UserData';
import '../App.css';
import { useMoralis } from 'react-moralis';
import { abiCreate } from '../libs/Contracts';
function ContractGeneration() {
 const [contract1, setContract1] = useState<any>('');
 const [contract2, setContract2] = useState<any>('');
 const { web3 } = useMoralis();

 const submit = async () => {
  if (contract1 && contract2) {
   const contract = new (web3 as any).eth.Contract(abiCreate, contract1);
   console.log(contract);
   const receipt = await contract.methods
    .setMarketplace(contract2)
    .send({ from: window.ethereum.selectedAddress });
   console.log(receipt);
   alert('success');
  } else {
   alert('Please fill all fields');
  }
 };
 return (
  <div className="App darker">
   <div className="breadcumb-area clearfix auto-init contactBreadcumb">
    <div className="breadcumb-content"></div>
   </div>
   <section className="section-padding-100 contact_us_area" id="contact">
    <div className="container">
     <div className="row">
      <div className="col-12">
       <div className="section-heading text-center">
        <h2 className="wow fadeInUp d-blue" data-wow-delay="0.3s">
         Set Market Place
        </h2>
        <p className="wow fadeInUp" data-wow-delay="0.4s"></p>
       </div>
      </div>
     </div>
     <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
       <div className="contact_form">
        <form action="#" method="post" id="main_contact_form">
         <div className="row">
          <div className="col-12">
           <div id="success_fail_info"></div>
          </div>

          <div className="col-12 col-md-6">
           <label>Contract Address 1</label>
           <input
            type="text"
            name="name"
            id="name"
            required
            onChange={(evt: any) => {
             setContract1(evt.target.value);
            }}
           />
          </div>
          <div className="col-12 col-md-6">
           <label>Contract Address 2</label>
           <input
            type="text"
            name="email"
            id="email"
            required
            onChange={(evt: any) => {
             setContract2(evt.target.value);
            }}
           />
          </div>
          <input type="button" value="Confirm" onClick={() => submit()} />
         </div>
        </form>
       </div>
      </div>
     </div>
    </div>
   </section>
  </div>
 );
}

export default ContractGeneration;
