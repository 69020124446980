import { useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import { useMoralis } from 'react-moralis';
import { loginUser, registerUser } from '../service/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import UserData from '../service/UserData';
import { PROFILE_PAGE } from '../libs/Constants';
import Footer from '../components/Footer';
import Strings from '../libs/Strings';
declare global {
 interface Window {
  ethereum?: any;
 }
}

function WalletConnect() {
 let ether = window.ethereum;
 const navigate = useNavigate();
 const { isAuthenticated, user, logout, authenticate } = useMoralis();

 const naviagteToProfile = (userId: any, userToken: any) => {
  navigate(PROFILE_PAGE, {
   state: [
    {
     id: userId,
     type: Strings.MyNFT,
     token: userToken,
    },
   ],
  });
 };

 const loginuser = async (publicAddress: any, id: any) => {
  const loginResponse = await loginUser(publicAddress, id);
  if (loginResponse[0].msg) {
   const registerResponse = await registerUser(publicAddress, id);
   localStorage.setItem('userId', registerResponse[0].id);
   localStorage.setItem('userToken', registerResponse[0].token);
   let userLoginInfo = {
    userId: registerResponse[0].id,
    userToken: registerResponse[0].token,
   };
   UserData.updateUserLoginInfo(userLoginInfo);
   localStorage.setItem('UserloginDetail', JSON.stringify(userLoginInfo));
   UserData.setUserId(registerResponse[0].id);
   UserData.setUserToken(registerResponse[0].token);
   naviagteToProfile(registerResponse[0].id, registerResponse[0].token);
  } else {
   localStorage.setItem('userId', loginResponse[0].id);
   localStorage.setItem('userToken', loginResponse[0].token);
   let userLoginInfo = {
    userId: loginResponse[0].id,
    userToken: loginResponse[0].token,
   };
   localStorage.setItem('UserloginDetail', JSON.stringify(userLoginInfo));
   UserData.updateUserLoginInfo(userLoginInfo);
   UserData.setUserId(loginResponse[0].id);
   UserData.setUserToken(loginResponse[0].token);
   naviagteToProfile(loginResponse[0].id, loginResponse[0].token);
  }
 };
 const userDetails: any = {
  firstname: null,
  imageurl: null,
 };

 useEffect(() => {
  if (isAuthenticated === true) {
   loginuser((user as any).get('ethAddress'), (user as any).get('username'));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isAuthenticated]);

 const connectWalletHandler = async () => {
  if (isAuthenticated) {
   await logout();
   localStorage.clear();
  } else {
   await authenticate();
  }
 };

 return (
  <div>
   <div className="App darker">
    <Header userInfo={userDetails} />
    <div className="breadcumb-area clearfix contactBreadcumb">
     <div className="breadcumb-content">
      <div className="container h-100">
       <div className="row h-100 align-items-center">
        <div className="col-12">
         <nav aria-label="breadcrumb" className="breadcumb--con text-center">
          <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
           {Strings.ConnectWallet}
          </h2>
          <ol
           className="breadcrumb justify-content-center wow fadeInUp"
           data-wow-delay="0.4s"
          >
           <li className="breadcrumb-item">
            <Link to="/">{Strings.Home}</Link>
           </li>
           <li className="breadcrumb-item active" aria-current="page">
            {Strings.ConnectWallet}
           </li>
          </ol>
         </nav>
        </div>
       </div>
      </div>
     </div>
    </div>

    <section className="features section-padding-100 ">
     <div className="container">
      <div className="section-heading text-center">
       <div
        className="dream-dots justify-content-center fadeInUp"
        data-wow-delay="0.2s"
       >
        <span>{Strings.ConnectYourWallet}</span>
       </div>
       <h2 className="fadeInUp" data-wow-delay="0.3s">
        {Strings.ConnectWallet}
       </h2>
      </div>

      <div className="row align-items-center">
       <div className="service-img-wrapper col-lg-5 col-md-12 col-sm-12 no-padding-right">
        <div className="features-list">
         <div className="who-we-contant text-center">
          <img src="img/icons/wallet.png" className="mb-15" width="90" alt="" />
          <h4 className="w-text mb-30" data-wow-delay="0.3s">
           {Strings.ConnectYourWalletToStart}
          </h4>

          {ether &&
          (ether.overrideIsMetaMask ||
           ether.isCoinbaseWallet ||
           ether.isMetaMask) ? (
           <div className="pricing-item v2">
            <a onClick={connectWalletHandler} target="_blank">
             <div className="walletBtn">
              <img
               src="img/icons/wallet.png"
               width="30"
               className="wal-icon"
               alt=""
              />
              {isAuthenticated ? Strings.LogOut : Strings.ConnectWallet}
             </div>
            </a>
           </div>
          ) : (
           <div>
            <div className="pricing-item v2">
             <a href="https://metamask.io/download.html">
              <img
               src="img/icons/w1.png"
               width="30"
               className="wal-icon"
               alt=""
              />
              {Strings.ConnectwithMetaMask}
             </a>
            </div>
            <div className="pricing-item v2">
             <a href="https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad">
              <img
               src="img/icons/w2.png"
               width="30"
               className="wal-icon"
               alt=""
              />
              {Strings.ConnectwithCoinbase}
             </a>
            </div>
           </div>
          )}
         </div>
        </div>
       </div>

       <div className="service-img-wrapper col-lg-7 col-md-12 col-sm-12 mt-s">
        <div className="image-box">
         <img
          src="img/core-img/macBook.png"
          className="center-block img-responsive phone-img"
          alt=""
         />
         <img
          src="img/core-img/rings.png"
          className="center-block img-responsive rings "
          alt=""
         />
        </div>
       </div>
      </div>
     </div>
    </section>
    <Footer />
   </div>
  </div>
 );
}

export default WalletConnect;
