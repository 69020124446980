import Strings from '../libs/Strings';
import '../App.css';

function ErrorPage(props: any) {
 return (
  <div id="notfound">
   <div className="notfound">
    <div className="notfound-404">
     <h1>{Strings.ErrorCode}</h1>
    </div>
    <h2>{Strings.NothingWasFound}</h2>
    <p>
     {Strings.ThePageYouAreLooking}
     <a href="#" onClick={props.onReload}>
      {Strings.Reload}
     </a>
    </p>
   </div>
  </div>
 );
}

export default ErrorPage;
