import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useMoralis } from 'react-moralis';
import {
 createNFTByToken,
 getUserCollectionNameByToken,
} from '../service/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import {
 PROFILE_PAGE,
 DESCRIPTION_MAX_SIZE,
 LONG_DESCRIPTION_ERROR_MSG,
 ITEM_NAME_MAX_SIZE,
 CREATE_ABI,
 EMPTY_FIELDS_ERROR_MSG,
 LARGE_ITEM_NAME,
 VALID_AMOUNT_ERR_MSG,
 LARGE_ITEM_IMAGE_ERROR_MSG,
 IMAGE_EXTENSION_ERROR_MSG,
 VALID_MUSIC_FILE_ERROR_MSG,
 VALID_VIDEO_FILE_ERROR_MSG,
 MORALIS_ERROR_CODE,
 USER_DENIED_TRANSACTION,
 BLOCKS_ERROR,
 UNSUCCESSFUL_TRANSACTION,
 FILENAME_CONTAINS_INVALID_CHARACTERS,
 LATIN_RANGE_ERROR_MSG,
 CREATE_ITEM,
} from '../libs/Constants';
import UserData from '../service/UserData';
import Footer from '../components/Footer';
import { abiCreate } from '../libs/Contracts';
import PopUp from '../components/PopUp';
import '../App.css';
import UserProfile from '../components/UserProfile';
import Strings from '../libs/Strings';
import ItemPreviewModal from '../components/ItemPreviewModal';

function CreateItemScreen() {
 const navigate = useNavigate();
 const [category, setCategory] = useState('');
 const [imageName, setImageName] = useState('');
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [name, setName] = useState('');
 const [description, setDescription] = useState('');
 const [price, setPrice] = useState(0);
 const [nftDetail, setNftDetail] = useState({});
 const [loader, setLoader] = useState(false);
 const [error, setError] = useState(false);
 const [isCopy, setIsCopy] = useState(false);
 const [isNavigateProfile, setIsNavigateProfile] = useState(false);
 const [imageUri, setImageUri] = useState('');
 const [imageSize, setImageSize] = useState(0);
 const [UserDetails, setUserDetails] = useState<any>({});
 const [collectionId, setCollectionId] = useState();
 const [userNFT, setUserNFT] = useState<any>({});
 const [showCreateItemModal, setShowCreateItemModal] = useState(false);
 const [userCollectionName, setUserCollectionName] = useState<any>([]);
 const { account, Moralis, web3 } = useMoralis();
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const useNftDetail: any = localStorage.getItem('userNFts');

 const onDropDownClick = async (id: any, token: any) => {
  const response = await getUserCollectionNameByToken(id, token);
  if (response) {
   setUserCollectionName(response);
   setCollectionId(response[0].collection_id);
  } else {
   setError(true);
  }
  return null;
 };

 const setUploadImage = () => {
  const fileInput = document.getElementById('file');
  const datafile = (fileInput as any).files[0];
  var imageUrl = URL.createObjectURL(datafile);
  const img = new Image();
  img.src = imageUrl;
  setImageName(datafile.name);
  setImageSize(datafile.size);
  setImageUri(imageUrl);
 };
 const showValidateModal = () => {
  if (
   category === '' ||
   name === '' ||
   description === '' ||
   price === 0 ||
   imageName === ''
  ) {
   setValidationMsg(EMPTY_FIELDS_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (name.length > ITEM_NAME_MAX_SIZE) {
   setValidationMsg(LARGE_ITEM_NAME);
   setCreateValidItemModal(true);
  } else if (price <= 0) {
   setValidationMsg(VALID_AMOUNT_ERR_MSG);
   setCreateValidItemModal(true);
  } else if (imageSize >= 104857600) {
   setValidationMsg(LARGE_ITEM_IMAGE_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (description.length > DESCRIPTION_MAX_SIZE) {
   setValidationMsg(LONG_DESCRIPTION_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (
   category.toUpperCase() === Strings.IMAGE ||
   category.toUpperCase() === Strings.TOURS
  ) {
   let ext = imageName.split('.')[1].toUpperCase();
   if (
    ext === 'JPG' ||
    ext === 'PNG' ||
    ext === 'JPEG' ||
    ext === 'GIF' ||
    ext === 'SVG'
   ) {
    setShowCreateItemModal(true);
   } else {
    setValidationMsg(IMAGE_EXTENSION_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else if (category.toUpperCase() === Strings.MUSIC) {
   console.log('Category', category);
   let ext = imageName.split('.')[1].toUpperCase();
   if (ext === 'MP3' || ext === 'WAV') {
    setShowCreateItemModal(true);
   } else {
    setValidationMsg(VALID_MUSIC_FILE_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else if (category.toUpperCase() === Strings.VIDEO) {
   let ext = imageName.split('.')[1].toUpperCase();
   if (ext === 'WEBM' || ext === 'MP4') {
    setShowCreateItemModal(true);
   } else {
    setValidationMsg(VALID_VIDEO_FILE_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else {
   setShowCreateItemModal(true);
  }
 };

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0 &&
   UserData.getuserDetail() != null
  ) {
   setUserDetails(UserData.getuserDetail());
   setUserNFT(UserData.getuserNFt());
  } else if (userProfileDetails !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
   setUserNFT(JSON.parse(useNftDetail));
  } else {
   navigate('/');
  }
 };

 useEffect(() => {
  getUserprofile();
  onDropDownClick(userLoginDetail.userId, userLoginDetail.userToken);
 }, []);

 async function mintToken(_uri: any) {
  const contract = new (web3 as any).eth.Contract(abiCreate, CREATE_ABI);
  console.log('receipt is', contract);
  const receipt1 = await contract.methods
   .mint(_uri)
   .send({ from: window.ethereum.selectedAddress });
  console.log('receipt is', receipt1);
  const gasFee = (
   (receipt1.effectiveGasPrice * receipt1.gasUsed) /
   1000000000000000000
  ).toFixed(6);
  const tokenId = receipt1.events.Transfer.returnValues.tokenId;
  let tokenObject = {
   totalGasFee: gasFee,
   token_Id: tokenId,
  };
  console.log('tokenId', tokenId);
  return tokenObject;
 }

 const createNFT = async (nftData: any, isNavigate: any) => {
  try {
   const response = await createNFTByToken(nftData, userLoginDetail.userToken);
   setNftDetail(nftData);
   setLoader(false);
   if (isNavigate) {
    navigate(PROFILE_PAGE);
   }
  } catch (error) {
   setLoader(false);
   console.log('error in API hit');
  }
 };
 const getTokenIdByMint = async (metadataURI: any, imageURI: any) => {
  try {
   const tokenId = await mintToken(metadataURI);
   console.log('getTokenIdByMint', tokenId);
   let nftData: any = {
    Id: userLoginDetail.userId,
    Category: category,
    Name: name,
    Description: description,
    ExternalLink: metadataURI,
    Amount: price,
    TokenPath: imageURI,
    ContractAddress: CREATE_ABI,
    TokenId: tokenId.token_Id,
    CreatedGasfee: tokenId.totalGasFee,
    CollectionId: collectionId ? collectionId : null,
    Status: Strings.nftCreated,
   };
   createNFT(nftData, true);
  } catch (error: unknown) {
   console.log('Errror', error);
   setLoader(false);
   if (JSON.stringify((error as any).code) == MORALIS_ERROR_CODE) {
    setValidationMsg(USER_DENIED_TRANSACTION);
    setCreateValidItemModal(true);
   } else if (error == BLOCKS_ERROR) {
    let nftData: any = {
     Id: userLoginDetail.userId,
     Category: category,
     Name: name,
     Description: description,
     ExternalLink: metadataURI,
     Amount: price,
     TokenPath: imageURI,
     ContractAddress: CREATE_ABI,
     CreatedGasfee: '0',
     TokenId: '0',
     CollectionId: collectionId ? collectionId : null,
     Status: Strings.Processing,
    };
    setIsNavigateProfile(true);
    createNFT(nftData, false);
    setValidationMsg(UNSUCCESSFUL_TRANSACTION);
    setCreateValidItemModal(true);
   } else {
    alert(error);
   }
  }
 };
 const minting = async () => {
  setLoader(true);
  try {
   const fileInput = document.getElementById('file');
   const datafile = (fileInput as any).files[0];
   const fileM = new Moralis.File(datafile.name, datafile);
   await fileM.saveIPFS();
   const imageURI = (fileM as any).ipfs();
   const metadata = {
    name: name,
    description: description,
    image: imageURI,
   };
   const metadataFile = new Moralis.File('metadata.json', {
    base64: btoa(JSON.stringify(metadata)),
   });
   await metadataFile.saveIPFS();
   const metadataURI = (metadataFile as any).ipfs();
   getTokenIdByMint(metadataURI, imageURI);
  } catch (error: any) {
   setLoader(false);
   console.log('error is', error);
   if (
    JSON.stringify((error as any).message) ==
    '"Filename contains invalid characters."'
   ) {
    setValidationMsg(FILENAME_CONTAINS_INVALID_CHARACTERS);
    setCreateValidItemModal(true);
   } else if (JSON.stringify((error as any).stack)) {
    setValidationMsg(LATIN_RANGE_ERROR_MSG);
    setCreateValidItemModal(true);
   } else {
    alert(error);
   }
  }
 };

 const checkNavigate = () => {
  if (isNavigateProfile) {
   navigate(PROFILE_PAGE);
  }
 };

 return (
  <div className="App darker">
   {loader ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}

   <Header userInfo={UserDetails} />
   <div className="breadcumb-area clearfix auto-init collectionBreadcumb">
    <div className="breadcumb-content">
     <div className="container h-100">
      <div className="row h-100 align-items-center">
       <div className="col-12">
        <nav aria-label="breadcrumb" className="breadcumb--con text-center">
         <h2 className="w-text title">{Strings.UploadNFT}</h2>
         <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item">
           <Link to="/">{Strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
           {Strings.UploadNFT}
          </li>
         </ol>
        </nav>
       </div>
      </div>
     </div>
    </div>
   </div>
   <section className="blog-area section-padding-100">
    <div className="container">
     <div className="row">
      <UserProfile
       UserDetails={UserDetails}
       userNFTdetail={userNFT}
       Account={account}
       onCopy={() => setIsCopy(true)}
      />
      <div className="col-12 col-lg-8">
       <div className="creator-sec dd-bg">
        <div className="who-we-contant">
         <div className="dream-dots text-left" data-wow-delay="0.2s">
          <span className="gradient-text">{Strings.Upload}</span>
         </div>
         <h4 className="uploadText">{Strings.Upload}</h4>
         <p className="requireFields">
          <span className="star">*</span>
          {Strings.Requiredfields}
         </p>
         <p className="w-text uploadItem">{Strings.UploadItemFile}</p>
        </div>
        <div className="contact_form">
         <div className="row">
          <div className="col-12">
           <div id="success_fail_info"></div>
          </div>

          <div className="col-12 col-md-12">
           <div className="group-file">
            <p className="g-text">{Strings.supportedFile}</p>
            <span className="file-wrapper new_Btn more-btn">
             <input
              type="file"
              name="upload"
              id="file"
              onChange={(e) => setUploadImage()}
             />
             <span className="button1">
              {' '}
              {imageName === '' ? Strings.Uploadfile : imageName}
             </span>
            </span>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <input
             type="text"
             name="name"
             id="name"
             onChange={(evt: any) => {
              setName(evt.target.value);
             }}
             required
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.Itemname}
             <span className="star">*</span>
            </label>
           </div>
          </div>

          <div className="col-12 col-md-12">
           <div className="mb-15 mb-15-2">
            <p>{Strings.ChooseItemCategory}</p>
            <div className="filers-list">
             {CREATE_ITEM.map((item: any, index: any) => {
              return (
               <div
                className={
                 category === item.type
                  ? 'filter-item filter filterItem fillerItem4'
                  : 'filter-item filterItem fillerItem4'
                }
                onClick={() => setCategory(item.type)}
               >
                {item.type}
               </div>
              );
             })}
            </div>
           </div>
          </div>
          <div className="col-12">
           <div className="group">
            <textarea
             name="Description"
             id="Description"
             onChange={(evt: any) => {
              setDescription(evt.target.value);
             }}
             required
            ></textarea>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.ItemDescription}
             <span className="star">*</span>
            </label>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <input
             type="number"
             name="Price"
             id="Price"
             step="any"
             onChange={(evt: any) => {
              setPrice(evt.target.value);
             }}
             required
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.ItemPriceInETH}
             <span className="star">*</span>
            </label>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group collection-section1">
            <div className="collection__sec1">
             <p>
              {Strings.Collection}{' '}
              <span className="appearText">
               {Strings.Youritemwillappearhere}
              </span>
             </p>
            </div>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group collection-section">
            <div className="collection__sec">
             <select
              className="inputClass seletct_sec"
              onChange={(evt: any) => setCollectionId(evt.target.value)}
             >
              {userCollectionName.map((x: any, y: any) => (
               <option key={x.collection_id} value={x.collection_id}>
                {x.collection_name}
               </option>
              ))}
             </select>
            </div>
           </div>
          </div>
          <div className="col-12 text-center">
           <button
            className="more-btn mb-15"
            onClick={() => showValidateModal()}
           >
            {Strings.CreateNFT}
           </button>
          </div>

          {showCreateItemModal ? (
           <ItemPreviewModal
            onCrossButtonClick={() => setShowCreateItemModal(false)}
            Category={category}
            source={imageUri}
            ItemName={name}
            ItemDescription={description}
            ItemPrice={price}
            onConfirm={() => minting()}
           />
          ) : null}
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    {createValidItemModal ? (
     <PopUp
      message={validationMsg}
      navigate={() => {
       setShowCreateItemModal(false);
       setCreateValidItemModal(false);
       checkNavigate();
      }}
     />
    ) : null}
    {isCopy ? (
     <PopUp
      message={Strings.Copied}
      navigate={() => {
       setIsCopy(false);
      }}
     />
    ) : null}
   </section>
   <Footer />
  </div>
 );
}

export default CreateItemScreen;
