import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
 START_INDEX,
 LAST_INDEX,
 DEFAULT_INDEX,
 COLLECTION_ARRAY,
 OUR_COLLECTION_ARRAY,
} from '../libs/Constants';
import { useNavigate } from 'react-router-dom';
import ListItem from '../components/ListItem';
import {
 PROFILE_PAGE,
 WALLET_CONNECT,
 ITEM_DETAIL_PAGE,
 NOT_LOGGEDIN_MSG,
 NFTBYNAME_LASTINDEX,
 NFTBYNAME_STARTINDEX,
} from '../libs/Constants';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import PopUp from '../components/PopUp';
import {
 getItemsByType,
 getFavouritesByToken,
 addToFavouritesByToken,
 getNFTByPrice,
 getNFTByName,
} from '../service/ApiService';
import { useMoralis } from 'react-moralis';
import Footer from '../components/Footer';
import ErrorPage from '../components/Error';
import UserData from '../service/UserData';
import Strings from '../libs/Strings';

function OurCollectionScreen() {
 let params: any = useLocation();
 const navigate = useNavigate();
 const [itemsData, setItemsData] = React.useState<Array<object>>([]);
 const [loading, setLoading] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const [error, setError] = useState(false);
 const [startIndex, setStartIndex] = useState(START_INDEX);
 const [lastIndex, setLastIndex] = useState(LAST_INDEX);
 const { isAuthenticated } = useMoralis();
 const [collectionType, setCollectionType] = useState(params.state.Type);
 const [sortBy, setSortBy] = useState('');
 const [sortByType, setSortByType] = useState('');
 const [showNotLogged, setShowNotLogged] = useState(false);
 const [isSearchByNameCalled, setIsSearchByNameCalled] = useState(false);
 const [lastIndexOfSearchByNmaeNFT, setLastIndexOfSearchByNmaeNFT] =
  useState(NFTBYNAME_LASTINDEX);
 const [hideSearchNameButton, setHideSearchNameButton] = useState(false);
 const [showErrorInput, setShowErrorInput] = useState(false);
 const [showSortByPriceButton, setShowSortByPriceButton] = useState(false);
 const [hideSortByPriceButton, setHideSortByPriceButton] = useState(false);
 const [isApplyFilterDisable, setIsApplyFilterDisable] = useState(true);
 const [isSearchByNmaeInputIsEmpty, setIsSearchByNmaeInputIsEmpty] =
  useState(false);
 const [isFilterButtonEnable, setIsFilterButtonEnable] = useState(false);
 const [completeCollectionArray, setCompleteCollectionArray] = useState<any>(
  []
 );
 const [loadMoreData, setLoadMoreData] = useState(false);
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const [UserDetails, setUserDetails] = useState<any>({});
 const userId = UserData.getUserId()
  ? UserData.getUserId()
  : localStorage.getItem('userId');
 const userToken = UserData.getUserToken()
  ? UserData.getUserToken()
  : localStorage.getItem('userToken');

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserDetails(UserData.getuserDetail());
  } else if (JSON.parse(userProfileDetails) !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
  }
 };

 useEffect(() => {
  getUserprofile();
 }, []);

 const getItems = async (CollectionType: any) => {
  setLoading(true);
  setHideLoadMore(false);
  setStartIndex(START_INDEX);
  setLastIndex(LAST_INDEX);
  setCollectionType(CollectionType);
  setSortBy('');
  setSortByType('');
  setIsSearchByNameCalled(false);
  setShowSortByPriceButton(false);
  const inputElement = document.getElementById('searchBar') as HTMLInputElement;
  inputElement.value = '';
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
  }
  if (UserData.getUserId()) {
   const res = await getFavouritesByToken(userId, userToken);
   favao = res;
  } else if (localStorage.getItem('userId')) {
   const res = await getFavouritesByToken(userId, userToken);
   favao = res;
  }
  const response = await getItemsByType(
   CollectionType,
   START_INDEX,
   LAST_INDEX
  );
  if (response && response != null) {
   let arr = response[DEFAULT_INDEX];

   if (arr.length == 0) {
    setHideLoadMore(true);
   }
   arr.map((item: any) => {
    item.favouate = false;
    return item;
   });
   if (favao.length === 0) {
    let obj: any = {
     type: CollectionType,
     data: arr,
    };
    let data: any = [...completeCollectionArray];
    data.push(obj);
    setCompleteCollectionArray(data);
    setItemsData(arr);
    setLoading(false);
   }
   favao.map((item: any, index: any) => {
    var inde = arr.findIndex((i: any) => i.id === item.itemid);
    if (inde >= 0) {
     arr[inde].favouate = true;
    }
    if (index === favao.length - 1) {
     let obj: any = {
      type: CollectionType,
      data: arr,
     };
     let data: any = [...completeCollectionArray];
     data.push(obj);
     setCompleteCollectionArray(data);
     setItemsData(arr);
     setLoading(false);
    }
   });
  } else {
   setError(true);
  }
 };

 const onLikeButtonPress = async (index: any, item: any) => {
  if (isAuthenticated) {
   const res = await addToFavouritesByToken(item.id, userToken);
   let arr: any = [...itemsData];
   arr[index].favouate = !item.favouate;
   setItemsData(arr);
   setCompleteCollectionArray([]);
  } else {
   setShowNotLogged(true);
  }
 };
 useEffect(() => {
  getItems(collectionType);
 }, []);

 const getItemByName = async (startIndex: any, lastIndex: any) => {
  setShowSortByPriceButton(false);
  setIsSearchByNmaeInputIsEmpty(false);
  const inputElement = document.getElementById('searchBar') as HTMLInputElement;
  if (inputElement.value === '') {
  } else {
   setLoading(true);
   setIsSearchByNameCalled(true);
   var favao: any = [];
   if (isAuthenticated === false) {
    favao = [];
   }
   if (UserData.getUserId()) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   } else if (localStorage.getItem('userId')) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   }
   const response = await getNFTByName(
    inputElement.value,
    startIndex,
    lastIndex
   );
   if (response[0].length > 0) {
    let arr = response[0];
    setCollectionType('All');
    setHideSearchNameButton(false);

    arr.map((item: any) => {
     item.favouate = false;
     return item;
    });
    if (favao.length === 0) {
     setItemsData(arr);
     setLoading(false);
    }
    favao.map((item: any, index: any) => {
     var inde = arr.findIndex((i: any) => i.id === item.itemid);
     if (inde >= 0) {
      arr[inde].favouate = true;
     }
     if (index === favao.length - 1) {
      setItemsData(arr);
      setLoading(false);
     }
    });
    setLoading(false);
   } else {
    setCollectionType('All');
    setItemsData([]);
    setLoading(false);
    setHideSearchNameButton(true);
   }
  }
  return null;
 };
 const getMoreItemsByName = async (startIndex: any, lastIndex: any) => {
  const inputElement = document.getElementById('searchBar') as HTMLInputElement;
  setLoadMoreData(true);
  setLastIndexOfSearchByNmaeNFT(lastIndexOfSearchByNmaeNFT + 20);
  setIsSearchByNameCalled(true);
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
  }
  if (UserData.getUserId()) {
   const res = await getFavouritesByToken(userId, userToken);
   favao = res;
  } else if (localStorage.getItem('userId')) {
   const res = await getFavouritesByToken(userId, userToken);
   favao = res;
  }
  const response = await getNFTByName(
   inputElement.value,
   startIndex,
   lastIndex
  );
  if (response[0].length > 0) {
   let arr = response[0];
   setCollectionType('All');
   setHideSearchNameButton(false);

   arr.map((item: any) => {
    item.favouate = false;
    return item;
   });
   if (favao.length === 0) {
    setItemsData([...itemsData, ...response[0]]);
    setLoadMoreData(false);
   }
   favao.map((item: any, index: any) => {
    var inde = arr.findIndex((i: any) => i.id === item.itemid);
    if (inde >= 0) {
     arr[inde].favouate = true;
    }
    if (index === favao.length - 1) {
     setItemsData([...itemsData, ...response[0]]);
     setLoadMoreData(false);
    }
   });
   setLoadMoreData(false);
  } else {
   setCollectionType('All');
   setLoadMoreData(false);
   setHideSearchNameButton(true);
  }
  return null;
 };

 const getMoreItems = async () => {
  setLoadMoreData(true);
  var favao = [];
  if (localStorage.getItem('userId')) {
   const res = await getFavouritesByToken(userId, userToken);
   favao = res;
  }
  setStartIndex(lastIndex + START_INDEX);
  setLastIndex(lastIndex + LAST_INDEX);
  const response = await getItemsByType(
   collectionType,
   lastIndex + START_INDEX,
   lastIndex + LAST_INDEX
  );
  if (response && response != null) {
   let arr = response[DEFAULT_INDEX];

   if (arr.length == 0) {
    setHideLoadMore(true);
   }
   arr.map((item: any) => {
    item.favouate = false;
    return item;
   });
   if (favao.length == 0) {
    setItemsData([...itemsData, ...arr]);
    setLoadMoreData(false);
   }
   favao.forEach((item: any, index: any) => {
    var inde = arr.findIndex((i: any) => i.id == item.itemid);

    if (inde >= 0) {
     arr[inde].favouate = true;
    }
    if (index == favao.length - 1) {
     setItemsData([...itemsData, ...arr]);
     setLoadMoreData(false);
    }
   });
  } else {
   setError(true);
   setLoadMoreData(false);
  }
 };

 const getNFTBySortType = async (sortType: any) => {
  setLoading(true);
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
   const response = await getNFTByPrice(
    NFTBYNAME_STARTINDEX,
    NFTBYNAME_LASTINDEX,
    sortByType,
    sortType
   );
   setItemsData(response[0]);
   if (response[0].length > 0) {
    setHideSortByPriceButton(false);
   } else {
    setHideSortByPriceButton(true);
   }
   setLoading(false);
  } else {
   if (UserData.getUserId()) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   } else if (localStorage.getItem('userId')) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   }
   const response = await getNFTByPrice(
    NFTBYNAME_STARTINDEX,
    NFTBYNAME_LASTINDEX,
    sortByType,
    sortType
   );

   if (response[0].length > 0) {
    let arr = response[0];
    setHideSortByPriceButton(false);
    arr.map((item: any) => {
     item.favouate = false;
     return item;
    });
    if (favao.length === 0) {
     setItemsData(response[0]);
     setLoading(false);
    } else {
     favao.map((item: any, index: any) => {
      var inde = arr.findIndex((i: any) => i.id === item.itemid);
      if (inde >= 0) {
       arr[inde].favouate = true;
      }
      if (index === favao.length - 1) {
       setItemsData(response[0]);
       setLoading(false);
      }
     });
    }
    setLoading(false);
   } else {
    setItemsData(response[0]);
    setLoading(false);
    setHideSortByPriceButton(true);
   }
   setLoading(false);
  }
 };
 const getNFTOfAllType = async (sortType: any) => {
  setLoading(true);
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
   const response = await getNFTByPrice(
    NFTBYNAME_STARTINDEX,
    NFTBYNAME_LASTINDEX,
    'all',
    sortType
   );
   setItemsData(response[0]);
   if (response[0].length > 0) {
    setHideSortByPriceButton(false);
   } else {
    setHideSortByPriceButton(true);
   }
   setLoading(false);
  } else {
   if (UserData.getUserId()) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   } else if (localStorage.getItem('userId')) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   }
   const response = await getNFTByPrice(
    NFTBYNAME_STARTINDEX,
    NFTBYNAME_LASTINDEX,
    'all',
    sortType
   );
   if (response[0].length > 0) {
    let arr = response[0];
    setHideSortByPriceButton(false);
    arr.map((item: any) => {
     item.favouate = false;
     return item;
    });
    if (favao.length === 0) {
     setItemsData(response[0]);
     setLoading(false);
    } else {
     favao.map((item: any, index: any) => {
      var inde = arr.findIndex((i: any) => i.id === item.itemid);
      if (inde >= 0) {
       arr[inde].favouate = true;
      }
      if (index === favao.length - 1) {
       setItemsData(response[0]);
       setLoading(false);
      }
     });
    }
    setLoading(false);
   } else {
    setItemsData([]);
    setHideSortByPriceButton(true);
   }
   setLoading(false);
  }
 };

 const applyFilters = () => {
  setShowSortByPriceButton(true);
  setHideLoadMore(false);
  setShowErrorInput(false);
  setIsSearchByNameCalled(false);
  const inputElement = document.getElementById('searchBar') as HTMLInputElement;
  inputElement.value = '';
  if (sortBy == '') {
  } else {
   if (sortBy == 'High to Low') {
    if (sortByType == '') {
     setCollectionType('All');
     getNFTOfAllType('HighToLow');
    } else {
     setCollectionType(sortByType);
     getNFTBySortType('HighToLow');
    }
   } else {
    if (sortByType == '') {
     setCollectionType('All');
     getNFTOfAllType('LowToHigh');
    } else {
     setCollectionType(sortByType);
     getNFTBySortType('LowToHigh');
    }
   }
  }
 };

 const getMoreNFTOfAllType = async (
  sortBy: any,
  startIndex: any,
  lastIndex: any
 ) => {
  setLoadMoreData(true);
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
   const response = await getNFTByPrice(startIndex, lastIndex, 'all', sortBy);
   setItemsData([...itemsData, ...response[0]]);
   if (response[0].length > 0) {
    setHideSortByPriceButton(false);
   } else {
    setHideSortByPriceButton(true);
   }
   setLoadMoreData(false);
  } else {
   if (UserData.getUserId()) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   } else if (localStorage.getItem('userId')) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   }
   const response = await getNFTByPrice(startIndex, lastIndex, 'all', sortBy);
   if (response[0].length > 0) {
    let arr = response[0];
    setHideSortByPriceButton(false);
    arr.map((item: any) => {
     item.favouate = false;
     return item;
    });
    if (favao.length === 0) {
     setItemsData([...itemsData, ...response[0]]);
     setLoadMoreData(false);
    } else {
     favao.map((item: any, index: any) => {
      var inde = arr.findIndex((i: any) => i.id === item.itemid);
      if (inde >= 0) {
       arr[inde].favouate = true;
      }
      if (index === favao.length - 1) {
       setItemsData([...itemsData, ...response[0]]);
       setLoadMoreData(false);
      }
     });
    }
    setLoadMoreData(false);
   } else {
    setHideSortByPriceButton(true);
   }
   setLoadMoreData(false);
  }
 };
 const getMoreNFTBySortType = async (
  sortBy: any,
  startIndex: any,
  lastIndex: any
 ) => {
  setLoadMoreData(true);
  var favao: any = [];
  if (isAuthenticated === false) {
   favao = [];
   const response = await getNFTByPrice(
    startIndex,
    lastIndex,
    sortByType,
    sortBy
   );
   setItemsData([...itemsData, ...response[0]]);
   if (response[0].length > 0) {
    setHideSortByPriceButton(false);
   } else {
    setHideSortByPriceButton(true);
   }
   setLoadMoreData(false);
  } else {
   if (UserData.getUserId()) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   } else if (localStorage.getItem('userId')) {
    const res = await getFavouritesByToken(userId, userToken);
    favao = res;
   }
   const response = await getNFTByPrice(
    startIndex,
    lastIndex,
    sortByType,
    sortBy
   );
   if (response[0].length > 0) {
    let arr = response[0];
    setHideSortByPriceButton(false);
    arr.map((item: any) => {
     item.favouate = false;
     return item;
    });
    if (favao.length === 0) {
     setItemsData([...itemsData, ...response[0]]);
     setLoadMoreData(false);
    } else {
     favao.map((item: any, index: any) => {
      var inde = arr.findIndex((i: any) => i.id === item.itemid);
      if (inde >= 0) {
       arr[inde].favouate = true;
      }
      if (index === favao.length - 1) {
       setItemsData([...itemsData, ...response[0]]);
       setLoadMoreData(false);
      }
     });
    }
    setLoadMoreData(false);
   } else {
    setHideSortByPriceButton(true);
    setLoadMoreData(false);
   }
   setLoadMoreData(false);
  }
 };
 const onAnyKeyPress = (e: any) => {
  if (e.key === 'Enter') {
   getItemByName(NFTBYNAME_STARTINDEX, NFTBYNAME_LASTINDEX);
  }
 };
 const getDataByCollectionType = async (CollectionType: any) => {
  setLoading(true);
  setHideLoadMore(false);
  setStartIndex(START_INDEX);
  setLastIndex(LAST_INDEX);
  setCollectionType(CollectionType);
  setSortBy('');
  setSortByType('');
  setIsSearchByNameCalled(false);
  setShowSortByPriceButton(false);
  const inputElement = document.getElementById('searchBar') as HTMLInputElement;
  inputElement.value = '';
  let isExists: boolean = false;
  let itemIndex: any = null;
  completeCollectionArray.forEach(async (item: any, i: any) => {
   if (item.type === CollectionType) {
    isExists = true;
    itemIndex = i;
   }
  });
  if (isExists) {
   if (completeCollectionArray[itemIndex].data.length == 0) {
    setHideLoadMore(true);
   }
   setCollectionType(CollectionType);
   setItemsData(completeCollectionArray[itemIndex].data);
   setLoading(false);
  } else {
   setCollectionType(CollectionType);
   getItems(CollectionType);
  }
 };

 const getMoreItemsByPrice = (startIndex: any, lastIndex: any) => {
  setLastIndexOfSearchByNmaeNFT(lastIndexOfSearchByNmaeNFT + 20);
  if (sortBy == 'High to Low') {
   if (sortByType == '') {
    getMoreNFTOfAllType('HighToLow', startIndex, lastIndex);
   } else {
    setCollectionType(sortByType);
    getMoreNFTBySortType('HighToLow', startIndex, lastIndex);
   }
  } else {
   if (sortByType == '') {
    getMoreNFTOfAllType('LowToHigh', startIndex, lastIndex);
   } else {
    setCollectionType(sortByType);
    getMoreNFTBySortType('LowToHigh', startIndex, lastIndex);
   }
  }
 };

 const onCollectionTypeSelect = (type: any) => {
  if (type === 'All NFTs') {
   setSortByType('All');
  } else {
   setSortByType(type);
  }
 };

 const checkTextInputLength = (text: any) => {
  if (text.length > 0) setIsFilterButtonEnable(true);
  else setIsFilterButtonEnable(false);
 };
 return (
  <div className="App darker">
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}

   <div className="App darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="App darker">
      <Header
       all={(a: any) => {
        getItems(a);
       }}
       tour={(a: any) => getItems(a)}
       music={(a: any) => getItems(a)}
       video={(a: any) => getItems(a)}
       image={(a: any) => getItems(a)}
       userInfo={UserDetails}
      />

      <section className="features section-padding-0-100 discoverOuter">
       <div className="container">
        <div className="sectlion-heading">
         <div className="dream-dots fadeInUp fadeUp" data-wow-delay="0.2s">
          <span>{Strings.WelcometoTheWorld}</span>
         </div>
         <div className="row rowContent content__Row2">
          <div className=" col-lg-6 col-md-6 col-sm-12 rows_col">
           <h2 className="fadeInUp fadeIn" data-wow-delay="0.3s">
            {Strings.ExploreAllNFTs}
           </h2>
          </div>
          <div className="colContent">
           <div className="col-lg-6 col-md-4 col-sm-6 searchOuter">
            <div className="form">
             <input
              id="searchBar"
              placeholder="Search"
              className="search"
              onKeyPress={(e) => onAnyKeyPress(e)}
              onChange={(evt) => checkTextInputLength(evt.target.value)}
             />
            </div>
           </div>
           <div className="col-lg-6 col-md-2 col-sm-6 filterBtnOuter">
            <div
             onClick={() => {
              getItemByName(NFTBYNAME_STARTINDEX, NFTBYNAME_LASTINDEX);
             }}
             className={
              isFilterButtonEnable
               ? 'btn more-btn filterBtn'
               : 'btn more-btn filterBtn disabled'
             }
            >
             {Strings.Filter}
             <img
              src="img/icons/filterIcon.png"
              alt=""
              className="filter__Icon"
             />
            </div>
           </div>
          </div>
         </div>
         <div className="row align-items-center">
          <div className="col-md-12 dropdownOuter">
           <div className="col-md-4 col-sm-12 sortByOuter sort__Outer">
            <p className="filterText">{Strings.SortBy}</p>
            <ul>
             <li className="nav-item dropdown dropdownLink">
              <div
               className="nav-link dropdown-toggle dropdownToggle fillerItem4"
               data-toggle="dropdown"
              >
               {sortBy ? sortBy : Strings.Select}
              </div>
              <div className="dropdown-menu">
               <div
                onClick={() => {
                 setSortBy('High to Low');
                 setIsApplyFilterDisable(false);
                }}
                className="dropdown-item"
               >
                {Strings.HighToLow}
               </div>
               <div
                onClick={() => {
                 setSortBy('Low to High');
                 setIsApplyFilterDisable(false);
                }}
                className="dropdown-item"
               >
                {Strings.LowToHigh}
               </div>
              </div>
             </li>
            </ul>
           </div>
           <div className="col-md-4 col-sm-12 sortByOuter">
            <p className="filterText">Collections</p>
            <ul>
             <li className="nav-item dropdown dropdownLink">
              <div
               className="nav-link dropdown-toggle dropdownToggle fillerItem4"
               data-toggle="dropdown"
              >
               {sortByType ? sortByType : Strings.SelectAnyCollection}
              </div>
              <div className="dropdown-menu dropdownMenu">
               {OUR_COLLECTION_ARRAY.map((item: any, index: any) => {
                return (
                 <div
                  key={item.key}
                  className="dropdown-item"
                  onClick={() => onCollectionTypeSelect(item.type)}
                 >
                  {item.type}
                 </div>
                );
               })}
              </div>
             </li>
            </ul>
           </div>
           <div className="col-md-2 forPaddingLeft">
            <div
             onClick={() => applyFilters()}
             className={
              isApplyFilterDisable
               ? 'btn more-btn filterBtn filterButton disabled'
               : 'btn more-btn filterBtn filterButton'
             }
            >
             {Strings.APPLYFILTER}
            </div>
           </div>
          </div>
         </div>
        </div>
        <div className="row">
         <div className="col-12 col-md-12">
          <div className="dream-projects-menu mb-50">
           <div className="text portfolio-menu txtT2">
            {COLLECTION_ARRAY.map((item: any, index: any) => {
             return (
              <div
               key={item.key}
               className={
                collectionType === item.type
                 ? 'collectionTypeButton'
                 : 'collectionTypeButtonDisable'
               }
               onClick={() => getDataByCollectionType(item.type)}
              >
               <p className="collectionTitle">{item.type}</p>
              </div>
             );
            })}
           </div>
          </div>
          <div className="row align-items-center">
           <div className="listedItems2">
            {itemsData.length >= 1 ? (
             <>
              {itemsData.map((item: any, index: any) => {
               return (
                <>
                 <ListItem
                  key={item.id}
                  category={item.category}
                  ownerName={
                   item.ownername === null
                    ? Strings.MintishUser
                    : item.ownername
                  }
                  ownerImage={
                   item.ownerimage ? item.ownerimage : 'img/authors/round2.jpg'
                  }
                  itemName={item.itemname}
                  itemPrice={item.itemamount}
                  itemCreatedTime={moment
                   .utc(item.createdat)
                   .local()
                   .startOf('seconds')
                   .fromNow()}
                  itemLike={0}
                  itemBlockChain={item.blockchain}
                  itemImage={item.tokenpath}
                  onIconPress={() => onLikeButtonPress(index, item)}
                  navigateToProfile={PROFILE_PAGE}
                  navigateToDetailPage={ITEM_DETAIL_PAGE}
                  routesIs={{ Id: item.id }}
                  showLike={item.favouate}
                 />
                </>
               );
              })}
              ;
             </>
            ) : loading ? null : (
             <div className="noData">{Strings.NODATAFOUND}</div>
            )}
           </div>

           <div className="ShowMoreButtonDiv">
            {loadMoreData ? (
             <div id="SpecificAreaLoaderOurCollection">
              <div>
               <img
                className="animation-loader2"
                src="../img/icons/animationloader.gif"
                alt=""
               />
              </div>
             </div>
            ) : null}
            {showSortByPriceButton ? (
             hideSortByPriceButton || loading ? null : (
              <div className="col-12 col-lg-12 text-center">
               <button
                onClick={() =>
                 getMoreItemsByPrice(
                  lastIndexOfSearchByNmaeNFT + 1,
                  lastIndexOfSearchByNmaeNFT + 20
                 )
                }
                className="btn more-btn fadeInUp"
                data-wow-delay="0.6s"
               >
                {Strings.LoadMore}
               </button>
              </div>
             )
            ) : isSearchByNameCalled ? (
             hideSearchNameButton || loading ? null : (
              <div className="col-12 col-lg-12 text-center">
               <button
                onClick={() =>
                 getMoreItemsByName(
                  lastIndexOfSearchByNmaeNFT + 1,
                  lastIndexOfSearchByNmaeNFT + 20
                 )
                }
                className="btn more-btn fadeInUp"
                data-wow-delay="0.6s"
               >
                {Strings.LoadMore}
               </button>
              </div>
             )
            ) : hideLoadMore || loading ? null : (
             <div className="col-12 col-lg-12 text-center">
              <button
               onClick={() => getMoreItems()}
               className="btn more-btn fadeInUp"
               data-wow-delay="0.6s"
              >
               {Strings.LoadMore}
              </button>
             </div>
            )}
           </div>
          </div>
         </div>
        </div>
       </div>
       {showNotLogged ? (
        <PopUp
         message={NOT_LOGGEDIN_MSG}
         navigate={() => {
          setShowNotLogged(false);
          navigate(WALLET_CONNECT);
         }}
        />
       ) : null}
      </section>
      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default OurCollectionScreen;
