import React from 'react';

export default function Modal(props: any) {
 return (
  <div className="modalOverlay">
   <div className="termsAndConditionModal">
    <div className="termsConditions_heading">
     <h5>TERMS & CONDITIONS</h5>
     <p>(hereinafter, the “Agreement”)</p>
    </div>
    <div className="col-md-12 borderBottom"></div>
    <div className="content termsConditionModal">
     <p>
      Last Updated: August 7th, 2021. The Terms & Conditions shall be amendable
      from time to time subject to the sole discretion of Mintish LLC and their
      agents. Terms may be prospectively updated as our Service evolves. In the
      event of substantive changes to the terms of this Agreement, you will be
      notified by email, nonetheless, posting of new Terms on the Service is
      notice to you thereof. Your continued use of the Services following
      posting of a change notice or new agreement on the Site or notice to you
      via e-mail, will constitute your binding acceptance of the changes.
     </p>
     <h5>
      PLEASE READ THIS TERMS OF SERVICE CAREFULLY. IT SETS FORTH THE LEGALLY
      BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE SERVICE, INCLUDING MUSIC
      CONTENT AND OTHER SERVICES WE MAKE AVAILABLE THROUGH THE SERVICE.
     </h5>
     <h5>INTRODUCTION</h5>
     <p>
      Welcome! The Terms of Service (“Terms”) governs the website{' '}
      <a href="https://musicafresh-nft.web.app/">
       https://musicafresh-nft.web.app/{' '}
      </a>
      (including both mobile and online versions) (the “Site”), including your
      use of interactive features, applications (“Apps”), content and/or other
      online services that we own and control and that post a link to these
      Terms (collectively with the Site, the “Service”), which are made
      available by Mintish LLC. (“Mintish”, “we”, “our” or “us”). By using the
      Service, you acknowledge and accept the Service’s Privacy Policy and
      consent to the collection and use of your data in accordance with our
      Privacy Policy. Mintish may, from time to time, provide you with
      information relating to customers that purchase Your Content, subject to
      our privacy policy and the preferences of our customers. You may only use,
      and disclose this information to a third party, for your internal record
      keeping purposes. You may not disclose any of this information to a third
      party or use it for any other purpose. In all events, you will comply with
      the terms of Mintish’ss privacy policy in connection with the customer
      data provided under this Agreement.
     </p>
     <h5>
      THIS AGREEMENT, WHEN ACCEPTED BY YOU AFTER CLICK “I AGREE”, WILL CREATE A
      BINDING AND LEGALLY ENFORCEABLE CONTRACT BETWEEN YOU AND US, WHETHER YOU
      ARE ACTING IN YOUR INDIVIDUAL CAPACITY OR AS THE AUTHORIZED REPRESENTATIVE
      FOR AN ARTIST, BAND, GROUP OR CORPORATION, IN WHICH CASE “YOU” SHALL REFER
      TO THE ARTIST, BAND, GROUP OR CORPORATION ON WHOSE BEHALF YOU ARE ACTING
      AND AUTHORIZED TO ACT. THEREFORE, PLEASE READ THIS AGREEMENT CAREFULLY AND
      CONSULT WITH YOUR OWN BUSINESS AND LEGAL ADVISORS BEFORE CLICKING “I
      AGREE.” THE “EFFECTIVE DATE” OF THIS AGREEMENT IS THE DATE ON WHICH YOU
      CLICK THE “I AGREE” BUTTON BELOW.
     </h5>
     <p>
      We reserve the right to add, delete and/or modify any of the terms and
      conditions contained in this Agreement. Notwithstanding the preceding
      sentence, no modifications to this Agreement will apply to any dispute
      between you and Mintish that arose prior to the date of such modification.
      In the event of substantive changes to the terms of this Agreement, you
      will be notified by email. If any modification is unacceptable to you,
      your only recourse is to discontinue use of the Services. Your continued
      use of the Services following posting of a change notice or new agreement
      on the Site or notice to you via e-mail, will constitute your binding
      acceptance of the changes.
     </p>
     <h5>
      YOU ARE RESPONSIBLE FOR MAINTAINING A VALID E-MAIL ADDRESS ON FILE WITH
      Mintish FOR SO LONG AS YOU AVAIL YOURSELF OF ANY SERVICES.
     </h5>
     <p>If You Want to Use the Service,</p>
     <p>
      Then carefully read these entire Terms (including all links to details),
      as they constitute a written agreement between you and us and they affect
      your legal rights and obligations and you agree to them all collectively
      when you agree to this Agreement. Each time you access and/or use the
      Service (other than to simply read these Terms), you agree to be bound by
      and comply with these Terms and any Additional Terms (defined below) then
      posted. <h5>THEREFORE, DO NOT USE THE SERVICE IF YOU DO NOT AGREE.</h5>
      The business realities associated with operating the Service are such
      that, without the limitations that are set forth in these Terms, such as
      your grants and waivers of rights, the limitations on our liability, your
      indemnity of us, and the forum selection – we would not make the Service
      available to you. By accessing and/or using the Service, you agree to be
      bound by these Terms. In some instances, both these Terms and separate
      guidelines, rules, or terms of use or sale setting forth additional or
      different terms and/or conditions will apply to your use of the Service or
      to a service or product offered via the Service (in each such instance,
      and collectively “Additional Terms”). To the extent there is a conflict
      between these Terms and any Additional Terms, the Additional Terms will
      control unless the Additional Terms expressly state otherwise.
      Additionally, certain features and services made available through the
      Service from time to time may be governed by different terms of use.
     </p>
     <h5>TABLE OF CONTENTS</h5>
     <p>
      It is important that you read and understand these entire Terms before
      using the Service. To ease review, each section below includes a brief
      introductory summary. Please note that the complete provisions, and not
      the headings or summaries shall govern. Any capitalized terms have the
      meanings given to them where defined in the Terms.
     </p>
     <h5> Content, Ownership, Limited License and Rights of Others</h5>
     <p>
      We only grant you a limited revocable license to use the Service for your
      own non-commercial use subject to rules and limitations. Music Content
      (defined below) is subject to different terms as noted in this Section.
     </p>
     <h5>B. Service and Content Use Restrictions</h5>
     <p>
      Your use of our Service is subject to various restrictions designed to
      protect the Service and our users. We may change or discontinue our
      Service in whole or in part.
     </p>
     <h5>C. Terms Applicable to Purchases and/or Subscriptions</h5>
     <p>
      If you make a purchase through the Service (including subscriptions to the
      Service), these terms apply, collectively.
     </p>
     <h5>D. Accounts</h5>
     <p>
      You may have the opportunity to open, revise and close your accounts,
      subject to certain rules. We may offer you the ability to make choices
      regarding how and to whom some aspects of your account are used and seen,
      but these may not be completely effective.
     </p>
     <h5>E. Content You Submit and Community Usage Rules</h5>
     <p>
      You grant us a broad license, which we may sublicense, to the content you
      submit which you represent. You have the right to allow us to use. You,
      however, retain ownership of and responsibility for, your content. Use of
      our Service is subject to community usage rules and we have the right to
      manage our Service to keep its content appropriate.
     </p>
     <h5>F. Procedure For Alleging Copyright Infringement</h5>
     <p>
      Users may not post content they do not own or control, and may be
      suspended or terminated if they do so. Copyright owners may give us notice
      of infringement by following specific instructions specifically addressed
      in this Section.
     </p>
     <h5>
      G. Procedure For Alleging Infringement of Other Intellectual Property
     </h5>
     <p>
      You can also give notice of trademark and other infringements that you
      think occur on the Service.
     </p>
     <h5>H. Notices and Questions</h5>
     <p>
      You agree we may provide you notices, including of new terms and
      conditions, by posting notice on the home page of the Service, changing
      the date at the beginning of these Terms or by other reasonable means that
      we may elect, such as to the email address you provided.
     </p>
     <h5>I. Links by You to the Service</h5>
     <p>You may link to our Service, subject to some basic rules.</p>
     <h5>J. Linked-To Websites; Advertisements; Dealings with Third Parties</h5>
     <p>
      We are not responsible for third parties or their content,
      advertisement(s), apps, sites, products and/or services. We may make
      advertisements and third-party content or services available to you on or
      via our Service, which we do not control. Use caution when dealing with
      third parties.
     </p>
     <h5>K. Wireless Features</h5>
     <p>
      Wireless carrier charges may apply to use of the Service via wireless
      networks or Devices.
     </p>
     <h5>L. Dispute Resolution</h5>
     <p>
      You agree to waive jury trial and class actions and to our selection of
      forum.
     </p>
     <h5>M. Disclaimer of Representations and Warranties</h5>
     <p>
      We disclaim warranties to the extent permitted by applicable law, and
      provide the Service “As Is”.
     </p>
     <h5>N. Limitations of our Liability</h5>
     <p>Our liability is greatly limited.</p>
     <h5>O. Updates to Terms</h5>
     <p>
      These Terms and Additional Terms posted on the Service at each time of use
      apply to that use, and the Terms may be prospectively updated as our
      Service evolves. Posting of new Terms on the Service is notice to you
      thereof.
     </p>
     <h5>P. General Provisions</h5>
     <p>
      You agree to various other terms and conditions, which you should read
      here, regarding: (1) Mintish’ss Consent or Approval;(2)Relationship of the
      Parties; (3)Entire Agreement; (4)Binding Effect ; (5)Rights Cumulative;
      (6)No Third Party Beneficiaries; (7)Headings; (8)Assignment ; (9)Indemnity
      ; (10)Operation of Service; Availability of Products and Services;
      International Issues ; (11)Severability; Interpretation; (12)
      Communications; (13) Investigations; Cooperation with Law Enforcement;
      Termination; Survival; (14) Assignment; (15) No Waiver; and (16)
      Connectivity. More
     </p>
     <h5>Terms Applicable For Apple Device Users</h5>
     <p>
      There are some other things you should know if you are accessing or using
      our App through an Apple Device.
     </p>
     <h5>FULL DETAILS OF THE TERMS OF SERVICE</h5>
     <h5>1. Content, Ownership, Limited License and Rights of Others</h5>
     <h5>1. Content</h5>
     <p>
      The Service contains a variety of: (i) materials and other items relating
      to Mintish, and its products and services, and similar items from our
      licensors and other third parties, including all layout, information,
      articles, posts, text, data, files, images, scripts, designs, graphics,
      button icons, instructions, illustrations, photographs, audio clips,
      music, sounds, pictures, videos, advertising copy, URLs, technology,
      software, interactive features, the “look and feel” of the Service, and
      the compilation, assembly, and arrangement of the materials of the Service
      and any and all copyrightable material (including source and object code);
      (ii) trademarks, trade dress, logos, trade names, service marks, and/or
      trade identities of various parties, including those of Mintish
      (collectively, “Trademarks”); and (iii) other forms of intellectual
      property (all of the foregoing but excluding Music Content (defined
      below), collectively, “Content”).
     </p>
     <h5>2. Ownership</h5>
     <p>
      The Service (including past, present, and future versions) and the Content
      are owned or controlled by Mintish, our licensors and/or certain other
      third parties. All right, title, and interest in and to the Content
      available via the Service is the property of Mintish or our licensors or
      certain other third parties, and is protected by U.S. and international
      copyright, trademark, trade dress, patent, and/or other intellectual
      property and unfair competition rights and laws to the fullest extent
      possible. Mintish owns the copyright in the selection, compilation,
      assembly, arrangement, and enhancement of the Content on the Service.
     </p>
     <h5>3. Limited License to Content</h5>
     <p>
      Subject to your strict compliance with these Terms (including payment of a
      subscription fee) (defined below) and any Additional Terms, excluding
      Music Content (defined below), Mintish grants you a limited,
      non-exclusive, revocable, non-assignable, personal, and nontransferable
      license to: (i) download (temporary storage only), display, view, use,
      play, and/or print one copy of the Content (excluding source and object
      code in raw form or otherwise, other than as made available to access and
      use to enable display and functionality) on a personal computer, mobile
      phone or other wireless device, or other Internet enabled device (each, a
      “Device”) for your personal, non-commercial use only. The foregoing
      limited license: (i) does not give you any ownership of, or any other
      intellectual property interest in, any Content, and (ii) may be
      immediately suspended or terminated for any reason, in Mintish’s sole
      discretion, and without advance notice or liability. In some instances, we
      may permit you to have greater access to and use of Content, subject to
      certain Additional Terms.
     </p>{' '}
     <h5>4. Rights to Music Content</h5>
     <p>
      The Service contains a cloud-based technology platform (the “Platform”)
      that enables users (“Licensors”) of the Service to license to other users
      of the Service (“Licensees”), for agreed-upon compensation, certain sound
      recordings (each individually, a “Master” and collectively, the “Masters”)
      and underlying musical compositions embodied therein (each individually, a
      “Composition” and collectively, the “Compositions”) for the purpose of
      possible embodiment (“Sample”, “Sampling”) in other derivative master
      recordings (each, a “Derivative Master”) and underlying derivative musical
      compositions (each, a “Derivative Composition”). Masters, Compositions,
      Derivative Masters, and Derivative Compositions shall be collectively
      referred to hereunder as “Music Content”. Notwithstanding anything to the
      contrary contained herein, where applicable, one (1) or more separate
      agreement(s) between Licensors, on the one hand, and Licensees, on the
      other hand (each, an “Outside Agreement”), shall control the licensing of
      any Music Content licensed by Licensors to Licensees on the Service. For
      the avoidance of doubt, Mintish does not grant any licenses or other
      rights to any users of the Service with respect to Music Content other
      than as specifically set forth hereunder, and any and all further rights
      shall be governed by the terms of Outside Agreement(s), if any.{' '}
     </p>
     <h5>5. Rights Granted to Mintish by Licensors.</h5>
     <p>
      1. For good and valuable consideration, Licensors grant to Mintish a
      license, for so long as Licensors choose to host the applicable Music
      Content (subject to the terms of any Outside Agreement[s]), to:
     </p>
     <p>
      a. Host, reproduce, distribute, make available for download and/or perform
      (publicly or otherwise) all Masters and Compositions on the Platform,
      including full-length versions of sound recordings (“Clips”) that can be
      used for promotional purposes as authorized herein and, if necessary,
      reproducing Your Content in new Physical Product;
     </p>
     <p>
      b. Publicly perform, publicly display, communicate to the public, and
      otherwise make available Your Content, and Clips, by means of digital
      audio transmissions (on an interactive or non interactive basis) through
      the Website, a Licensee website, or via a Mintish Widget you or any person
      authorized by you may place on any website, to identify the availability
      of Your Content for license, sale or distribution and to promote Your
      Content, on a through-to- the-listener basis, without the payment of any
      fees or royalties to (i) the songwriters, composers, or music publishers
      owning any rights in and to Your Content; (ii) any performing artist(s)
      (including non- featured vocalists and musicians) on Your Content; (iii)
      any other person involved in the creation of or owning any portion of Your
      Content, including, but not limited to a record label, and (iv) any agents
      for any of the foregoing, including, without limitation, performing rights
      organizations (“PROs”) and unions or guilds, whether U.S.- based (such as
      ASCAP, BMI, SESAC, SoundExchange, AFTRA and AFM) or foreign (e.g., PRS for
      Music, PPL, CMRRA, CSI, GEMA, etc.);
     </p>
     <p>
      c. Distribute Your Content in accordance with any applicable Addendum;
     </p>
     <p>
      d. Place or embed Your Content in magazines, web sites, Mintish’s
      advertisements, and any and all other media, whether now known or
      hereafter developed, but specifically excluding television and movies, to
      promote the Mintish’s Service;
     </p>
     <p>
      e. Use and distribute Copyright Management Information as embodied in a
      Digital Master of Your Content;
     </p>
     <p>
      f. Use Your Content and metadata as may be reasonably necessary or
      desirable for us to exercise our rights under this Agreement;
     </p>
     <p>
      g. Reproduce, distribute, and publicly perform and communicate to the
      public Your Content (including Clips) as part of a downloaded program that
      may include multiple sound recordings and other content, commonly known as
      a “podcast;” and
     </p>
     <p>
      h. Authorize our Licensees to perform any one or more of the activities
      specified above or in an applicable Addendum;
     </p>
     <p>
      i. Grant to Licensees, subject to the terms of any Outside Agreement, the
      exclusive or non-exclusive right, privilege and license to download, copy,
      reproduce, Sample and otherwise edit, alter, couple, synchronize and/or
      otherwise embody the Masters (together with the applicable underlying
      including the Composition(s) embodied therein) into Derivative Masters and
      Derivative Compositions, subject to and in accordance with these Terms;
     </p>
     <p>
      j. Broadcast, stream and/or perform (publicly or otherwise) the Masters
      and/or Compositions in any and all media in connection with the
      advertisement and promotion of the Masters, Compositions and/or the
      Platform;
     </p>
     <p>
      k. Use, publish and display Licensor’s name, approved image, approved
      likeness and approved biographical materials (provided that any materials
      or information provided by Licensors are deemed approved) in any and all
      media in connection with the advertisement and promotion of the Masters,
      Compositions and/or the Platform; and
     </p>
     <p>
      l. License any of the rights granted to Mintish hereunder to third parties
      as reasonably necessary to perform its obligations hereunder or to
      otherwise effectuate the purpose of these Terms.
     </p>
     <p>
      2. The rights granted pursuant to this Section 1 with respect to each
      Master (and the applicable corresponding underlying Composition) shall be
      non- exclusive to Mintish, subject to the terms of any Outside
      Agreement(s). For the avoidance of doubt, it is hereby acknowledged and
      agreed that the terms of any Outside Agreement(s) may continue for a
      duration longer than the rights granted to Mintish hereunder.
     </p>
     <p>
      3. Licensor hereby represents and warrants: (1) there are and there shall
      be no liens, encumbrances or other charges against the Master(s) and/or
      the Composition(s), including, without limitation, any Samples
      incorporated therein by Licensor and/or any third party engaged by
      Licensor; (2) no selections, materials, ideas, or other properties
      furnished by Licensor and embodied or contained in the Master(s) or the
      Composition(s), nor the exercise by Mintish of any of its rights
      hereunder, will violate or infringe upon any law or statutory right of any
      third party; (3) Licensor has the full right, power and authority to agree
      to these Terms, grant the rights conveyed to Mintish hereunder, and
      perform Licensor’s material terms and obligations hereunder; and (4)
      Licensor has not entered into and shall not enter into to any agreement
      with any third-party record company,distributor, music publisher, or any
      other person or entity that would conflict, inhibit, restrict or impair
      the rights granted to Mintish hereunder or the performance of Licensor’s
      obligations under these Terms.
     </p>
     <p>
      4. Licensee hereby represents and warrants that the use or other
      exploitation of Your Content, including, but not limited to, any musical
      works embodied in your sound recordings, by us and our Licensees as
      contemplated by this Agreement will not infringe or violate the rights of
      any third party, including, without limitation, any privacy rights,
      publicity rights, copyrights, contract rights, or any other intellectual
      property or proprietary rights.
     </p>
     <p>
      5. Licensee hereby represents and warrants that no selections, materials,
      ideas, or other properties furnished by Licensee and embodied or contained
      in any Derivative Master(s) or Derivative Composition(s), nor the exercise
      by Mintish of any of its rights hereunder, will violate or infringe upon
      any law or statutory right of any third party, including without
      limitation copyright, trademark right or right of publicity or privacy.
     </p>
     <p>
      6. Licensee hereby represents and warrants to the extent you are the
      songwriter of any or all of the musical works embodied in Your Content,
      whether in whole or in part (e.g., as a co-writer), you have the full
      right, power, and authority to grant the rights set forth in this
      Agreement notwithstanding the provisions of any agreement you may have
      entered into with any PRO, whether based in the United States or
      elsewhere, or any music publisher, and that you are solely responsible for
      taking all steps necessary to inform such PRO or music publisher of your
      grant of a royalty free license to us and our Licensees for the public
      performance and communication to the public of Your Content, including as
      Clips, and that no fees or payments of any kind whatsoever shall be due to
      any PRO or music publisher for the use of the musical works in Your
      Content when publicly performed, communicated or otherwise transmitted by
      Mintish or its Licensees.
     </p>
     <p>
      7. Licensee hereby represents and warrants you have not assigned any of
      the rights in and to the sound recordings embodied in Your Content to any
      third party (e.g., a record label) that obtained exclusive rights in and
      to such sound recordings.
     </p>
     <p>
      8. Licensee hereby represents and warrants that if it is ever determined
      that you have acted contrary to any other agreements you have in place,
      you shall cause any and all of your respective PRO (Performing Rights
      Organizations), CMOs (Collective Management Organization), publishing
      administrators, agents, Entities, their parent company and subsidiaries,
      or their respective employees, officers, directors, members, managers,
      shareholders, agents, vendors, licensors, licensees, contractors,
      customers,successors, and assigns, as applicable, to license to Mintish
      and/or Mintish’s publishing administrators, agents,Mintish’s parent
      company and subsidiaries, agents, vendors, licensors, licensees,
      contractors, customers, successors, and assigns the rights in any of the
      Composition pursuant to the terms and conditions of this agreement.
      Licensee shall issue or shall cause the necessary above parties in this
      paragraph) to issue a non- exclusive, worldwide, and perpetual
      royalty-free licenses for use of the Composition in connection with any
      Master(s), Composition(s), Derivative Master(s) and/or Derivative
      Composition(s) hereunder, including, without limitation, any unauthorized
      commercial exploitation thereof.
     </p>
     <h5>
      9. LICENSOR AND LICENSEE HEREBY FOREVER RELEASE AND COVENANT NOT TO SUE
      Mintish AND/OR ANY OF THE Mintish PARTIES FROM ANY AND ALL CLAIMS ARISING
      OUT OF OR IN CONNECTION WITH ANY ACT OR OMISSION OF ANY THIRD PARTY IN
      CONNECTION WITH ANY MASTER(S), COMPOSITION(S), DERIVATIVE MASTER(S) AND/OR
      DERIVATIVE COMPOSITION(S) HEREUNDER, INCLUDING, WITHOUT LIMITATION, ANY
      UNAUTHORIZED COMMERCIAL EXPLOITATION THEREOF.
     </h5>
     <h5>
      10. YOU UNDERSTAND AND AGREE THAT Mintish IS NOT A PARTY TO ANY AGREEMENTS
      ENTERED INTO BETWEEN LICENSORS AND LICENSEE. YOU ACKNOWLEDGE THAT UNLESS
      EXPRESSLY SET FORTH ON THE SERVICE AS PART OF A SUBSCRIPTION OFFERING,
      Mintish DOES NOT LICENSE MUSIC CONTENT DIRECTLY OR FUNCTION AS MUSIC
      LICENSING COMPANY. THE SERVICE MAY BE USED BY LICENSORS AND LICENSEES TO
      LICENSE OR OBTAIN A LICENSE TO MUSIC CONTENT FOR DEVELOPMENT OF DERIVATIVE
      COMPOSITIONS, BUT YOU AGREE THAT Mintish HAS NO RESPONSIBILITY OR
      LIABILITY TO YOU RELATED TO ANY MUSIC CONTENT LICENSED THROUGH THE USE OF
      THE SERVICE, OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS. Mintish HAS
      NO CONTROL OVER THE CONDUCT OF LICENSORS, LICENSEES, AND OTHER USERS OF
      THE SITE AND SERVICE OR ANY MUSIC CONTENT, AND DISCLAIMS ALL LIABILITY IN
      THIS REGARD TO THE MAXIMUM EXTENT PERMITTED BY LAW. Mintish DOES NOT
      GUARANTEE THE MUSIC CONTENT MADE AVAILABLE ON THE SERVICE BY LICENSORS IS
      CLEAR FROM A RIGHTS USE PERSPECTIVE AND SUCH MUSIC CONTENT MAY INFRINGE
      THIRD PARTY RIGHTS. IT IS SOLELY A LICENSEE’S RESPONSIBILITY TO DETERMINE
      IF ANY MUSIC CONTENT WILL MEET THE LICENSEE’S NEEDS AND EXPECTATIONS.
      Mintish WILL NOT PARTICIPATE IN DISPUTES BETWEEN LICENSORS AND LICENSEES.
      Mintish SHALL NOT HAVE ANY LIABILITY ARISING FROM OR IN ANY WAY RELATED TO
      ANY TRANSACTIONS OR RELATIONSHIP BETWEEN LICENSORS AND LICENSEES.
     </h5>
     <h5>6. Term</h5>
     <p>
      1. The term of this Agreement will commence on the Effective Date and will
      continue, unless and until terminated by either you or us, upon five (5)
      work days of a written notice (the “Term”), which notice, if sent by (a)
      Mintish, may be sent to you at the last e-mail address you provided to
      Mintish and (b) you to Mintish, must be sent only to the following e-mail
      address: contact@musicafreshllc.com with the following information:
      (a)your username; (b) the e-mail address associated with your Account; (c)
      all album titles for which you are requesting termination; and (d) which
      Services you are terminating (e.g., Marketplace Services, Digital
      Distribution, Publishing Administration and/or Sync Distribution). Any
      termination notice provided by you pursuant to this section shall be
      permanent and irreversible. Notwithstanding, anything to the contrary
      herein, Mintish, may at any time in its sole discretion, with or without
      notice to you: (i) suspend or limit your access to or your use of the
      Services and/or (ii) suspend or limit your access to your Account.
     </p>
     <h6>
      2. Mintish is not responsible and has no liability for any delays of our
      Licensees in removing Your Content from any websites or services owned or
      operated by such Licensees. You shall remain solely responsible for
      enforcing the removal of Your Content from our Licensees’ websites and
      services in the event such Licensees fail to remove Your Content following
      receipt of a Takedown Notice or following the termination of any rights
      granted to such Licensees by Mintish; provided, however, that Mintish may,
      in its sole and absolute discretion, continue to assist you to effectuate
      the removal of Your Content from Licensees’ websites and services. Mintish
      may, but need not, provide you with notice in the event Mintish terminates
      or allows to expire any authorizations previously granted to a Licensee
      for the distribution of Your Content. Nothing in this Agreement shall
      limit any remedies you may have at law or in equity against any Licensee
      that is using Your Content in violation of the terms of any license
      granted to such Licensee by you or Mintish.
     </h6>
     <p>
      3. The expiration or termination of the Agreement will not relieve either
      you or us from our respective obligations incurred prior to the effective
      date of your termination of the Agreement. In addition, provisions of this
      Agreement intended to survive the termination of this Agreement shall
      survive termination, including, but not limited to, the Indemnification,
      Disclaimers, Limitation of Liability; Basis of the Bargain, and General
      Provisions.
     </p>
     <h5>7. Payments to You and Thirty Percent (30%) Fee on Your Content.</h5>
     <p>
      <span className="span_text">1. Pricing the Sale of Your Content.</span>{' '}
      <span>
       Pricing the Sale of Your Content. Except as otherwise set forth in an
       Addendum, you will have the discretion to set the pricing for the sale of
       Your Content on the Website. Notwithstanding the foregoing, Mintish and
       its distributors and partners may set pricing differently for third-party
       websites than for the Website, including, by way of example and not
       limitation, to cover additional costs or to provide discounts for
       promotions.Mintish reserves the sole and exclusive right to set the
       pricing for any digital audio transmissions of Your Content, whether on
       an interactive or non-interactive basis, provided that Your Content will
       be priced the same as all other content on the Service licensed by
       Mintish for interactive or non- interactive digital audio transmissions.
      </span>
     </p>
     <h5>
      2. THIRTY PERCENT (30%) FEE ON PRETAX (IF ANY) PRICE OF YOUR CONTENT.
      Mintish WILL CHARGE LICENSORS A THIRTY (30%) COMMISSION ON THE PRETAX (IF
      ANY SALE IS SUBJECT TO ANY KIND OF TAX, STATE AND/OR FEDERAL TAX OR ANY
      OTHER SIMILAR GOVERNMENTAL CHARGES) PRICES OF YOUR CONTENT PAYABLE TO
      Mintish. THE THIRTY (30%) COMMISSION SHALL CONSTITUTE SET UP FEES AND
      CHARGES AS MORE FULLY EXPLAINED ON THE WEBSITE, INCLUDING, BUT NOT LIMITED
      TO, FEES FOR Mintish ADMINISTERING MECHANICAL ROYALTIES FOR THE
      REPRODUCTION AND DISTRIBUTION OF MUSICAL WORKS (AS APPLICABLE), AS SUCH
      FEES AND CHARGES MAY BE UPDATED BY Mintish FROM TIME TO TIME, AND YOU ARE
      RESPONSIBLE FOR REVIEWING THOSE FEES AND CHARGES. YOUR ACCEPTANCE OF THIS
      AGREEMENT IS AN ACCEPTANCE OF THEMintish’S FEES AND CHARGES. THE THIRTY
      PERCENT (30%) FEE DOES NOT CONSTITUTE AN ACCEPTANCE OF LIABILITY OF ANY
      KIND AND/OR APPROVAL OF CONDUCT THAT DOES NOT COMPLY WITH OUR COMMUNITY
      USAGE RULES, THIS TERMS, FEDERAL, STATE OR INTERNATIONAL COPYRIGHT AND
      INTELLECTUAL PROPERTY LAWS.
     </h5>
     <p>
      3. <span className="span_text">Licensee Records.</span>
      <span>
       Mintish, may, but need not, audit the books and records of Licensees and
       may accept any representations made in a Licensee accounting statement
       delivered to Mintish as true and complete. Mintish shall have no
       liability to you for failure to audit or investigate any accountings
       rendered to it by any Licensees. You hereby authorize Mintish to offset
       against any amounts owed to you pursuant to this Agreement any amounts
       that you may owe to Mintish, whether under an indemnification provision
       or for costs, expenses, and deductions authorized in this Agreement.
      </span>
     </p>
     <p>
      4. <span className="span_text">Recordkeeping;</span>
      <span>
       Audits. You may, but not more than once a year, at your own expense,
       engage a Certified Public Accountant (“CPA”) to examine those books and
       records directly related to the sale or other licensed uses of Your
       Content, as provided in this Section 7(c), only. You may have your CPA
       make those examinations only for the purpose of verifying the accuracy of
       the statements sent to you. All such examinations will be in accordance
       with generally accepted accounting principles (“GAAP”) procedures and
       regulations. Your CPA may make such an examination for a particular
       statement only once, and only within one (1) year after the date we send
       you that statement. Your CPA may make such an examination only during our
       usual business hours, and only at the place where such books and records
       are maintained in the ordinary course of business. You must provide us
       with thirty (30)-days written notice prior to commencing an audit and
       must identify the name, address, telephone number, and email address of
       the CPA conducting the audit on your behalf. You may not engage the CPA
       on a contingent fee basis (i.e., your CPA must be paid on a flat fee or
       time- based basis). We may postpone the commencement of your CPA’s
       examination by notice given to you not later than five (5) days before
       the commencement date specified in your notice. In the event of any
       postponement initiated by us, the running of the time within which the
       examination may be made will be suspended during the postponement. If
       your CPA’s examination has not been completed within three (3) months
       from the time commenced, then we may require you to terminate the audit
       upon seven (7) days’ notice, which notice may be given at any time. We
       will not be required to permit the CPA to continue any examination after
       the end of that seven (7)-day period. Your CPA will not be entitled to
       examine any other records that do not specifically report sales or other
       licensed uses of Your Content for which Mintish has actually received
       payment. Your CPA may act only under an acceptable confidentiality
       agreement, which provides that any information derived from such audit or
       examination on your behalf will not be knowingly released, divulged,
       published or shared with any other person, firm or corporation, other
       than to you or to a judicial or administrative body in connection with
       any proceeding relating to this Agreement. Your CPA may not share the
       results of the examination conducted on your behalf with any third party
       without our express written permission.
      </span>
     </p>
     <p>
      5.<span className="span_text">Objection to Accountings.</span>
      <span>
       {' '}
       If you have any objections to a Mintish accounting statement made
       available to you, you agree that you shall give us specific notice of
       that objection, including a copy of your CPA’s analysis of the accounting
       statement, and your reasons for it within eighteen (18) months after the
       date we send or make that statement available to you. Each statement
       shall become conclusively binding on you at the end of that eighteen
       (18)-month period, and you will no longer have any right to make any
       other objections to it notwithstanding any audit rights you may otherwise
       have under any applicable law or regulation. Any payments determined to
       be owed you following an audit shall be paid within forty- five (45) days
       of the delivery of your CPA’s audit report, unless objected to in writing
       by Mintish, in which case any payments due shall be postponed pending the
       resolution of the audit dispute. Unless otherwise prohibited by law, you
       will not have the right to sue us in connection with any statement, or to
       sue us for unpaid royalties for the period a statement covers, unless you
       commence the suit within that eighteen (18)-month period. If you commence
       suit on any controversy or claim concerning statements rendered to you
       under this Agreement in a court of competent jurisdiction, the scope of
       the proceeding will be limited to a determination of the amount of
       royalties due for the accounting periods concerned, and the court shall
       have no authority to consider any other issues or award any relief except
       recovery of any royalties found owing. Your recovery of any such
       royalties plus interest shall be the sole remedy available to you by
       reason of any claim related to our statements.
      </span>
     </p>
     <p>
      6.<span className="span_text">Tax information.</span>
      <span>
       {' '}
       Notwithstanding the above, in all events, you acknowledge and agree that
       you are ultimately responsible for the payment of any Sales Tax, Income
       Tax and any other kind of tax, state, federal or any other similar
       governmental charge owed in connection with the sale or distribution of
       Your Content pursuant to this Agreement, and you hereby indemnify Mintish
       for any Sales Tax that may be owed in addition to those amounts collected
       and remitted on your behalf by Mintish.
      </span>
     </p>
     <h5>8. Your Obligations</h5>
     <p>
      1. You, or a licensee acting on your behalf, will be responsible for
      obtaining and paying for any and all clearances or licenses required in
      the Authorized Territory (or any portion thereof) for the use of any
      musical works embodied in Your Content. Without limiting the generality of
      the foregoing, you (either directly or through a third party acting on
      your behalf) shall be responsible for and shall pay (i) any royalties and
      other sums due to artists (featured and non-featured), authors,
      co-authors, copyright owners and co-owners, producers, engineers, and any
      other record royalty participants from sales or other uses of Your
      Content, (ii) all mechanical royalties or other sums payable to music
      publishers and/or authors or co-authors of musical compositions embodied
      in Your Content from sales or other uses of Your Content, (iii) all
      payments that may be required under any collective bargaining agreements
      applicable to you or any third party (e.g., to unions or guilds such as
      AFM or AFTRA), and (iv) any other royalties, fees and/or sums payable with
      respect to Your Content or other materials provided by you to us. You
      agree that the amount payable to you is inclusive of any so-called “artist
      royalties” that might otherwise be required to be paid for sales or
      exploitations pursuant to the applicable laws of any jurisdiction and for
      any public performances, public displays or communications to the public
      of the sound recordings and musical works constituting Your Content.
     </p>
     <p>
      2. Parental Advisory Labeling. You will be responsible for complying with
      the Recording Industry Association of America’s (“RIAA”) Parental Advisory
      Logo (“PAL”) Standards, as applicable, for so long as you use the
      Services.
     </p>
     <h5>9. Rights of Others</h5>
     <p>
      When using the Service, you must respect the intellectual property and
      other rights of Mintish and others.
      <h5>
       {' '}
       YOUR UNAUTHORIZED USE OF CONTENT MAY VIOLATE COPYRIGHT, TRADEMARK,
       PRIVACY, PUBLICITY, COMMUNICATIONS, AND OTHER LAWS, AND ANY SUCH USE MAY
       RESULT IN YOUR PERSONAL LIABILITY, INCLUDING POTENTIAL CRIMINAL
       LIABILITY.
      </h5>
     </p>
     <h5>2. Service and Content Use Restrictions</h5>
     <h6>1. Monitoring of Your Content; Removal of Content from Website</h6>
     <p>
      1. Mintish does not control Your Content and does not have any obligation
      to monitor Your Content for any purpose. Mintish may choose, in its sole
      discretion, to monitor, review or otherwise access some or all of Your
      Content, but by doing so Mintish assumes no responsibility for Your
      Content, no obligation to modify or remove any inappropriate elements of
      Your Content, or to monitor, review or otherwise access any other artist’s
      content or artwork.
     </p>
     <p>
      2. Mintish reserves the right, in its sole and absolute discretion, to
      remove any of Your Content from the Website if such content: (i) is
      patently offensive, pornographic or defamatory; (ii)is the subject of a
      dispute between you or us and a third party; (iii) is content to which you
      cannot document your rights therein upon Mintish’s request; (iv) violates
      the intellectual property rights or other protected interests of a third
      party; (v)is the subject of a takedown notice by a party claiming to own
      the rights therein, or (vi) is the subject of any fraudulent activity, or
      for any other reason in Mintish’s sole and absolute judgment is necessary
      to protect the business interests of Mintish and any of its business
      partners or Licensees. Mintish may also remove Your Content from the
      Website if you are abusive or rude or provide false or intentionally
      misleading information to any Mintish employees or agents. Mintish shall
      have no liability to you for the removal of any of Your Content from the
      Website or any Licensee website or service other than to provide you a
      credit (but not a refund) for any fees previously paid by you for making
      Your Content available via the Website or through Licensees. The removal
      of any of Your Content shall not relieve Mintish of the obligation to pay
      you any royalties that may have accrued prior to the removal of Your
      Content.
     </p>
     <h6>
      3. This Agreement shall not be terminated automatically by Mintish removal
      of Your Content from the Website or Licensee’s websites or services. In
      order for you to terminate this Agreement following the removal of any of
      Your Content, you must send Mintish a Termination Notice.
     </h6>
     <h6>2. Account Information; Disclosures</h6>
     <p>
      1. In order to access some features of the Website, including your account
      information and periodic statements, you will have to create an online
      account (“Account”). You hereby represent and warrant that the information
      you provide to Mintish upon registration will be true, accurate, current,
      and complete. You also hereby represent and warrant that you will ensure
      that your Account information, including your e-mail address, is kept
      accurate and up-to-date at all times during the Term of this Agreement.
     </p>
     <p>
      2. b. As a registered user of the Services you will have login
      information, including a username and password. Your Account is personal
      to you, and you may not share your Account information with, or allow
      access to your Account by, any third party, other than an agent authorized
      to act on your behalf. As you will be responsible for all activity that
      occurs under your Account, you should take care to preserve the
      confidentiality of your username and password, and any device that you use
      to access the Website. You agree to notify us immediately of any breach in
      secrecy of your login information. If you have any reason to believe that
      your Account information has been compromised or that your Account has
      been accessed by a third party not authorized by you, then you agree to
      immediately notify Mintish by e-mail to contact@musicafreshllc.com. You
      will be solely responsible for the losses incurred by Mintish and others
      (including other users) due to any unauthorized use of your Account that
      takes place prior to notifying Mintish that your Account has been
      compromised.
     </p>
     <p>
      3. You acknowledge, consent, and agree that Mintish may access, preserve,
      and disclose your Account information and Your Content if required to do
      so by law or in a good faith belief that such access, preservation or
      disclosure is reasonably necessary to (i) comply with legal process; (ii)
      enforce this Agreement; (iii)respond to a claim that any of Your Content
      violates the rights of third parties; (iv) to respond to your requests for
      customer service; or (v) to protect the rights, business interests,
      property or personal safety of Mintish and its employees and users, and
      the public.
     </p>
     <h6>
      3. No Representations and Warranties With Respect to Sales and
      Distributions
     </h6>
     <p>
      1. Mintish makes no guarantees regarding the minimum number of unit sales
      or uses of Your Content. In addition, we cannot guarantee that Licensees
      will perform under any agreement they enter into withMintish for the sale,
      distribution or licensed use of Your Content, including by paying the
      royalties they owe us for the distribution of Your Content. If a Licensee
      refuses to pay us for the use of Your Content, you agree that you will
      assume responsibility for collecting any payments that may be due from
      such non-compliant Licensees for any sale, distribution or licensed use of
      Your Content if such third party fails or refuses to pay such amounts to
      Mintish upon Mintish’s request.
     </p>
     <h6>
      4. Prohibited Use of the Website and Licensee Websites and Services{' '}
     </h6>
     <p>
      1. You agree not to use the Website, the Services, and any services
      provided by Licensees, for any unlawful purpose or in any way that might
      harm, damage, or disparage Mintish, its Licensees or any other party.
      Without limiting the preceding sentence and by way of example and not
      limitation, you agree that you will not, whether through the Website, our
      Licensees or Your Content, do or attempt any of the following:
     </p>
     <p>
      2. Reproduce, duplicate, copy, sell, trade, resell, distribute or exploit,
      any portion of the Website, use of the Website, access to the Website or
      content obtained through the Website, as a result of your being granted
      permission to upload Your Content to the Website;
     </p>
     <p>
      3. Remove, circumvent, disable, damage or otherwise interfere with any
      security-related features of the Website, features that prevent or
      restrict the use or copying of any part of the Website or features that
      enforce limitations on the use of the Website;
     </p>
     <h6>
      4. Threaten, harass, abuse, slander, defame or otherwise violate the legal
      rights (including, without limitation, rights of privacy and publicity) of
      Mintish staff, employees, or affiliates.
     </h6>
     <p>
      5. Publish, distribute or disseminate any inappropriate, profane, vulgar,
      defamatory, infringing, obscene, tortious, indecent, unlawful, offensive,
      immoral or otherwise objectionable material or information;
     </p>
     <h6>
      6. Publish, distribute or disseminate any inappropriate, profane, vulgar,
      defamatory, infringing, obscene, tortious, indecent, unlawful, offensive,
      immoral or otherwise objectionable material or information towards Mintish
      staff, employees, or affiliates.
     </h6>
     <h5>
      7. CREATE A FALSE IDENTITY OR IMPERSONATE ANOTHER FOR THE PURPOSE OF
      MISLEADING OTHERS AS TO YOUR IDENTITY, INCLUDING, BUT NOT LIMITED TO,
      PROVIDING MISLEADING INFORMATION TO ANY FEEDBACK SYSTEM EMPLOYED BY
      Mintish AND ANY OF IT’S USERS. ANY LICENSOR, LICENSEE, THEIR AGENTS OR ANY
      OTHER USER OF ANY KIND THAT ENGAGES IN THIS CONDUCT OR ANY OTHER SIMILAR
      CONDUCT RELATED TO THIS MATTER SHALL BE COMPLETELY, SOLELY, AND PERSONALLY
      LIABLE TO ANY LEGAL (CIVIL OR CRIMINAL) CONSEQUENCE.Mintish SHALL NOT HAVE
      ANY LIABILITY ARISING FROM OR IN ANY WAY RELATED TO THIS CONDUCT AND MAY,
      BUT IS NOT OBLIGATED TO, AT IT’S OWN DISCRETION, TRY TO FIND LICENSORS AND
      LICENSEES THAT DO ENGAGE IN THIS CONDUCT AND Mintish SHALL TERMINATE THEIR
      AGREEMENT, ACCOUNT AND MAY BANNED THE PERMANENTLY FROM OUR PLATFORM AND
      SERVICES. ENGAGING IN THIS PARTICULAR CONDUCT CONSTITUTES JUST CAUSE TO
      TERMINATE THIS AGREEMENT WITHOUT ADVANCE NOTICE OR LIABILITY.
     </h5>
     <p>
      8. Transmit or upload any material that contains viruses, Trojan horses,
      worms, time bombs, cancelbots, or any other harmful, damaging or
      deleterious software programs;
     </p>
     <p>
      9. Interfere with or disrupt the Website, networks or servers connected to
      the Website or violate the regulations, policies or procedures of such
      networks or servers;
     </p>
     <p>
      9. Interfere with or disrupt the Website, networks or servers connected to
      the Website or violate the regulations, policies or procedures of such
      networks or servers;
     </p>
     <p>
      10. Upload or otherwise transmit any information or content that infringes
      any patent, trademark, trade secret, copyright or other proprietary rights
      of any party, including by incorporating any such material in Your
      Content; or
     </p>
     <p>
      11. Use the Website in any manner whatsoever that could lead to a
      violation of any federal, state or local laws, rules or regulations.
     </p>
     <p>
      12. Use the Service for any political or commercial purpose (including,
      without limitation, for purposes of advertising, soliciting funds,
      collecting product prices, and selling products); (ii) use any meta tags
      or any other "hidden text" utilizing any Trademarks; (iii) engage in any
      activities through or in connection with the Service that seek to attempt
      to or do harm any individuals or entities or are unlawful, offensive,
      obscene, lewd, lascivious, filthy, violent, threatening, bullying,
      harassing, or abusive, or that violate any right of any third party, or
      are otherwise objectionable to Mintish; (iv) reverse engineer, decompile,
      disassemble, reverse assemble, or modify any Service source or object code
      or any software or other products, services, or processes accessible
      through any portion of the Service; (v) engage in any activity that
      interferes with a user's access to the Service or the proper operation of
      the Service, or otherwise causes harm to the Service,Mintish, or other
      users of the Service; (vi) interfere with or circumvent any security
      feature of the Service or any feature that restricts or enforces
      limitations on use of or access to the Service, the Content, or the User-
      Generated Content (defined below); (vii) harvest or otherwise collect or
      store any information (including personally identifiable information about
      other users of the Service, including email addresses, without the express
      consent of such users); (viii) attempt to gain unauthorized access to the
      Service, other computer systems or networks connected to the Service,
      through password mining or any other means; (ix): (a) use false or
      deceptive identities, names or accounts; (b) deploy or otherwise use bots,
      malware, viruses or scrapers; (c) make it appear as though any aspect of
      your account, User-Generated Content, or Music Content, including, without
      limitation, your plays, likes, messages, reposts, etc., are more
      successful than they actually were through unauthentic interactions by you
      or third parties you engage, manage or transact with (e.g., click-fraud,
      manipulating social media followings, etc.); (d) engage in any activities
      that are designed to defraud or game Mintish or third parties; or (e)
      deploy, or permit any third party to deploy, any technology on, or in
      connection with, Mintish or Mintish branded sites, systems or services
      that can enable the tracking of site or service activities or users,
      except with Mintish prior express written approval; or (x) otherwise
      violate these Terms or any Additional Terms.
     </p>
     <p>
      13. You also agree that, in using the Service: (i) you will not monitor,
      gather, copy, or distribute the Content (except as may be a result of
      standard search engine activity or use of a standard browser) on the
      Service by using any robot, rover, "bot", spider, scraper, crawler,
      spyware, engine, device, software, extraction tool, or any other automatic
      device, utility, or manual process of any kind; (ii) you will not frame or
      utilize framing techniques to enclose any such Content (including any
      images, text, or page layout); (iii) you will keep intact all Trademark,
      copyright, and other intellectual property notices contained in such
      Content; (iv) you will not use such Content in a manner that suggests an
      unauthorized association with any of our or our licensors' products,
      services, or brands; (v) you will not make any modifications to such
      Content; (vi) you will not copy, modify, reproduce, archive, sell, lease,
      rent, exchange, create derivative works from, publish by hard copy or
      electronic means, publicly perform, display, disseminate, distribute,
      broadcast, retransmit, circulate or transfer to any third party or on any
      third-party application or website, or otherwise use or exploit such
      Content in any way for any purpose except as specifically permitted by
      these Terms or any Additional Terms or with the prior written consent of
      an officer of Mintish or, in the case of Content from a licensor, the
      owner of the Content; and (vii) you will not insert any code or product to
      manipulate such Content in any way that adversely affects any user
      experience.
     </p>
     <h6>5. Availability of Service and Content</h6>
     <p>
      1. Mintish may make changes to or discontinue any aspects of the Services
      and any of the features, media, content, products, software or services
      available via the Website, at any time and without notice and without
      liability to you. The features, media, content, products, software or
      services available on and through the Website may be out of date, and
      Mintish makes no commitment to update any aspect of the Website. Mintish
      makes no representations and warranties with respect to availability of
      the Website and may discontinue the Service at any time with or without
      notice. You are solely responsible for maintaining back-up copies of any
      elements of Your Content uploaded to the Website or otherwise delivered to
      Mintish as Physical Product.
     </p>
     <p>
      2. Mintish may immediately suspend or terminate the availability of the
      Service and Content (and any elements and features of them), in whole or
      in part, for any reason, in Mintish’s sole discretion, and without advance
      notice or liability.
     </p>
     <h6>6. Reservation of All Rights Not Granted as to Content and Service</h6>
     <p>
      1. These Terms and any Additional Terms include only narrow, limited
      grants of rights to Content and to use and access the Service. No right or
      license may be construed, under any legal theory, by implication,
      estoppel, industry custom, or otherwise. All rights not expressly granted
      to you are reserved by Mintish and its licensors and other third parties.
      Any unauthorized use of any Content or the Service for any purpose is
      prohibited
     </p>
     <h5>3. Terms Applicable to Purchases and/or Subscriptions</h5>
     <h5>1. Generally</h5>
     <p>
      <span>
       1. To purchase access and use of subscriptions we make available for sale
       on the Service or other products and services,{' '}
       <span className="spantext1">
        you must be at least eighteen (18) years of age or the applicable age of
        majority in your jurisdiction of residence.
       </span>{' '}
       Prior to the purchase of any products or services, you must provide us
       with a valid credit card number and associated payment information
       including all of the following: (i) your name as it appears on the card,
       (ii) your credit card number, (iii) the credit card type, (iv) the date
       of expiration, and (v) any activation numbers or codes needed to charge
       your card or otherwise use a valid gift card. By submitting that
       information to us or our third party credit card processor, you agree
       that you authorize us and/or our processor to charge your card at our
       convenience but within thirty (30) days of credit card authorization. For
       any product or service that you order on the Service, you agree to pay
       the price applicable (including any sales taxes and surcharges) as of the
       time you submit the order. Mintish will automatically bill your credit
       card or other form of payment submitted as part of the order process for
       such price.
      </span>
     </p>
     <h5>2. Subscription Term & Termination</h5>
     <p>
      1. Except in the event of a free trial offer, your subscription will
      commence as of the date your payment for a subscription is received by
      Mintish. Your subscription will continue in full force for the length of
      the term you specifically purchased or on a month-to-month term until such
      time as you cancel the subscription as further explained below (the
      "Subscription Term"). In the event that you cancel a subscription in the
      middle of your Subscription Term, you will not be entitled to receive a
      refund for the unused portion of the remainder of that Subscription Term.
     </p>
     <p>
      2. Mintish will have the right, upon written notice to you, to terminate
      these Terms, and suspend your access to your subscription, if: (a) you
      fail to pay Mintish any amount due to Mintish under these Terms; and/or
      (b) you materially breach any term or condition of these Terms. Mintish
      shall have the right to terminate these Terms and suspend your access to
      your subscription with or without cause, upon thirty (30) days written
      notice to you in which case you will no longer be charged for access to
      the subscription. Upon the expiration or termination of these Terms for
      any reason, your access to, and your use of, your subscription will
      terminate.
     </p>
     <h5>3. Free Trials/Promotional Offerings</h5>
     <p>
      1. We may offer promotional trial subscriptions to access the Service for
      free for a limited time or at special discounted prices. If you sign up
      for a trial use, your rights to use the Service are limited by the terms
      of such trial and will terminate or renew on the terms of your trial
      arrangement and/or any applicable Additional Terms. Please be aware that
      when you sign up for a free trial, you will be required to provide your
      credit card number and Mintish will confirm your credit card is valid.
      When we process your credit card, some credit card companies may place a
      temporary hold on your account for your first payment. Please contact your
      credit card company if you have questions. Please note that we do not
      provide price protection or refunds in the event of a price drop or
      promotional offering.
     </p>
     <h5>4. Auto-Renewal of Membership</h5>
     <p>
      1. Your subscription to the applicable subscription on the Service will
      automatically renew at the end of your Subscription Term continuously and
      indefinitely without action by the member, and the membership fee is
      charged to the member at the time of renewal. An enrolee whose membership
      fee has been paid is entitled to all privileges included in the membership
      until the membership is cancelled by the enrolled member as set forth in
      the paragraph below. By providing your payment method information for your
      subscription, you are agreeing to pay a subscription fee, that will
      automatically renew, at the then current rate, unless you cancel prior to
      the expiration of the current Subscription Term, and any applicable taxes
      and service fees (collectively, "Fees").
     </p>
     <p>
      2. The Fees will be charged to your original payment method automatically
      at the beginning of your Subscription Term, and at the beginning of each
      renewal Subscription Term thereafter on the calendar day corresponding to
      the commencement of your current Subscription Term, unless you cancel your
      subscription or your account is suspended or terminated pursuant to these
      Terms. The renewal Subscription Term will be the same length as your
      initial Subscription Term unless otherwise disclosed to you at the time of
      sale. The rate for the renewal Subscription Term will be the then current
      subscription-rate.
     </p>
     <p>
      3. The Fees charged to your payment method may vary from Subscription Term
      to Subscription Term due to changes in your subscription plan or
      applicable taxes, and you authorize Mintish to charge your payment method
      for these amounts. Mintish reserves the right to change the pricing of
      subscriptions at any time. In the event of a price change, Mintish will
      post the new pricing on the Service and attempt to notify you in advance
      by sending an email to the address you have registered for your account.
      You agree that we may change the pricing we charge you for your
      subscription and any products/services offered in your subscription
      package by providing you with notice through an electronic communication
      from us and you agree that all agreements, notices, disclosures, and other
      communications that we provide to you electronically satisfy any legal
      requirement that such communications be in writing. You consent to our
      ability to change our pricing and the details of our subscription packages
      through an electronic communication to you. If you do not wish to accept a
      price or subscription package change made by us, you may cancel your
      subscription as described below, otherwise you will be deemed to have
      consented to the price/subscription package change and authorize Mintish
      to charge the new Fees to your payment method. If there are any
      discrepancies in billing, you hereby waive your right to dispute such
      discrepancies if you do not notify Mintish within sixty (60) days after
      they first appear on an account statement.
     </p>
     <h5>5. Cancellation of Membership</h5>
     <p>
      1. A member will have the right to cancel your subscription at any time
      upon notice to Mintish by email at contact@musicafreshllc.com.
      Cancellation of initial membership any time after purchase will result in
      forfeiture of the membership fee. To avoid a late cancellation fee or
      forfeiture of the membership renewal fee, membership should be cancelled
      prior to the end of the then current Subscription Term. Upon cancellation,
      the member loses access to the areas of the Service designated for members
      only. This could include any credit and other data and analyses that have
      been displayed during your membership. To the extent that you continued to
      get charged after cancellation of your membership due to Mintish error or
      otherwise, you agree that your sole remedy will be to receive a refund
      from Mintish for the overcharged amounts.
     </p>
     <h5>6. Methods of Payment, Credit Card Terms and Taxes</h5>
     <p>
      1. All payments must be made via Visa, Mastercard, American Express, JCB,
      Discover and Paypal. We currently do not accept cash, personal or business
      checks or any other payment form, although in the future we may change
      this policy. Your card issuer agreement governs your use of your
      designated card, and you must refer to that agreement and not these Terms
      to determine your rights and liabilities as a cardholder. You represent
      and warrant that you will not use any credit card or other form of payment
      unless you have all necessary legal authorization to do so.
     </p>
     <p>
      <h5>
       2. YOU, AND NOT Mintish, ARE RESPONSIBLE FOR PAYING ANY UNAUTHORIZED
       AMOUNTS BILLED TO YOUR CREDIT CARD BY A THIRD PARTY.{' '}
      </h5>{' '}
      You agree to pay all fees and charges incurred in connection with your
      purchases (including any applicable taxes) at the rates in effect when the
      charges were incurred. Unless you notify Mintish of any discrepancies
      within sixty (60) days after they first appear on your credit card
      statement, you agree that they will be deemed accepted by you for all
      purposes. If Mintish does not receive payment from your credit card issuer
      or its agent, you agree to pay all amounts due upon demand by Mintish or
      its agents. Sales taxes, or other taxes, customs, import/export charges,
      or similar governmental charges are not included in the price of the
      products. You are responsible for paying any such taxes or charges imposed
      on your purchases, including, but not limited to, sales, use or
      value-added taxes. Mintish shall automatically charge and withhold the
      applicable tax for orders to be delivered to addresses within and any
      states or localities that it deems is required in accordance with our
      order policy in effect at the time of purchase.
     </p>
     <h5>7. Refund Policy</h5>
     <p>
      1. All purchase transactions made through the Service are subject to
      Mintish’s return policy in effect at the time of purchase. Currently,
      Mintish’s refund policy is to not offer any refunds for any subscriptions
      or products purchased through the Service, except in our sole and absolute
      discretion.
     </p>
     <h5>8. Order Acceptance Policy</h5>
     <p>
      1. Your receipt of an electronic or other form of order confirmation does
      not signify our acceptance of your order, nor does it constitute
      confirmation of our offer to sell. Mintish reserves the right at any time
      after receipt of your order to accept or decline your order for any
      reason. Mintish further reserves the right any time after receipt of your
      order, without prior notice to you, to supply less than the quantity you
      ordered of any item. Your order will be deemed accepted byMintish upon our
      delivery of products or services that you have ordered.
     </p>
     <p>
      2. We may require additional verifications or information before accepting
      any order. Notwithstanding the foregoing, you agree that, if we cancel all
      or a portion of your order or if we provide you less than the quantity you
      ordered, your sole and exclusive remedy is either that: (a) we will issue
      a credit to your credit card account in the amount charged for the
      cancelled portion or the quantity not provided (if your credit card has
      already been charged for the order); or (b) we will not charge your credit
      card for the cancelled portion of the order or the quantity not provided.
     </p>
     <p>
      3. Do not assume that a cancellation or change of an order you have placed
      with Mintish has been effected until you receive a confirmation from
      Mintish via email or the Service. As stated above, you will be responsible
      for, and your credit card or third-party payment account may be charged
      for, the payment of all fees associated with orders already processed or
      shipped before your cancellation/change request or a request to terminate
      your account was received.
     </p>
     <h5>9. No Responsibility to Sell Mispriced Products or Services</h5>
     <p>
      1. We do our best to describe every item, product or service offered on
      this Service as accurately as possible. However, we are human, and
      therefore we do not warrant that specifications or pricing on the Service
      is complete, accurate, reliable, current, or error-free. In the event of
      any errors relating to the pricing or specifications of any item, product
      or service, Mintish shall have the right to refuse or cancel any orders in
      its sole discretion. If we charged your credit card or other account prior
      to cancellation, we will issue a credit to your account in the amount of
      the charge. Additional terms may apply. If a product you purchased from
      Mintish is not as described, your sole remedy is to return it in unused
      condition, complete and undamaged, in the original packaging.
     </p>
     <h5>10. Modifications to Prices or Billing Terms</h5>
     <h5>
      1. PRODUCTS AND SERVICES DISPLAYED ON THE SERVICE MAY NOT BE AVAILABLE AT
      ALL TIMES AND MAY BE SUBSTITUTED OR DISCONTINUED AT ANY TIME.Mintish
      RESERVES THE RIGHT, AT ANY TIME, TO CHANGE ITS PRICES AND BILLING METHODS
      FOR SERVICES SOLD, EFFECTIVE IMMEDIATELY UPON POSTING ON THE SERVICE OR BY
      E-MAIL DELIVERY TO YOU.
     </h5>
     <p>
      2. The features, media, content, products, software or services available
      on and through the Website may be out of date, and Mintish makes no
      commitment to update any aspect of the Website. Mintish makes no
      representations and warranties with respect to availability of the Website
      and may discontinue the Service at any time with or without notice. You
      are solely responsible for maintaining back-up copies of any elements of
      Your Content uploaded to the Website or otherwise delivered to Mintish as
      Physical Product.
     </p>
     <h5>4. Accounts</h5>
     <h5>1. General</h5>
     <p>
      1. In order to access or use some (or potentially all) of the features on
      the Service, you may be required to first register for a user account
      through our registration process that we make available through the
      Service. The Service's practices governing any resulting collection and
      use of your personal information are disclosed in our Privacy Policy.
     </p>
     <p>
      2. If you register for any feature that requires a password and/or
      username, then you will select your own password at the time of
      registration (or we may send you an e-mail notification with a randomly
      generated initial password) and you agree that: (i) You will not use a
      username (or e-mail address) that is already being used by someone else,
      may impersonate another person, belongs to another person, violates the
      intellectual property or other right of any person or entity, or is
      offensive. We may reject the use of any password, username, or e-mail
      address for any other reason in our sole discretion; (ii) You will provide
      true, accurate, current, and complete registration information about
      yourself in connection with the registration process and, as permitted, to
      maintain and update it continuously and promptly to keep it accurate,
      current, and complete; (iii) You are solely responsible for all activities
      that occur under your account, password, and username - whether or not you
      authorized the activity; (iv) You are solely responsible for maintaining
      the confidentiality of your password and for restricting access to your
      Device so that others may not access any password protected portion of the
      Service using your name, username, or password; (v) You will immediately
      notify us of any unauthorized use of your account, password, or username,
      or any other breach of security; and (vi) You will not sell, transfer, or
      assign your account or any account rights. We will not be liable for any
      loss or damage (of any kind and under any legal theory) to you or any
      third party arising from your inability or failure for any reason to
      comply with any of the foregoing obligations. If any information that you
      provide, or if we have reasonable grounds to suspect that any information
      that you provide, is false, inaccurate, outdated, incomplete, or violates
      these Terms, any Additional Terms, or any applicable law, then we may
      suspend or terminate your account. We also reserve the more general and
      broad right to terminate your account or suspend or otherwise deny you
      access to it or its benefits - all in our sole discretion, for any reason,
      and without advance notice or liability.
     </p>
     <h5>5. Content You Submit and Community Usage Rules</h5>
     <h5>1. User-Generated Content</h5>
     <p>
      1. Mintish may now or in the future offer users of the Service the
      opportunity to create, build, post, upload, display, publish, distribute,
      transmit, broadcast, or otherwise make available on or submit through the
      Service (collectively, "submit") messages, text, illustrations, files,
      images, graphics, photos, comments, feedback, surveys, responses, videos,
      information, content, data, questions, suggestions, personally
      identifiable information, or other information or materials and the ideas
      contained therein but expressly excluding Music Content (collectively,
      "User - Generated Content"). Mintish may allow you to do this through
      forums, email, and other communications functionality. Subject to the
      license you grant in these Terms, you retain whatever legally cognizable
      right, title, and interest that you have in your User-Generated Content
      and you remain ultimately responsible for it.
     </p>
     <h5>2. Non - Confidentiality of Your User - Generated Content</h5>
     <p>
      1. Except as otherwise described in the Service's posted Privacy Policy or
      any Additional Terms, you agree that: (a) your User-Generated Content will
      be treated as non-confidential - regardless of whether you mark them
      "confidential," "proprietary," or the like - and will not be returned; and
      (b) Mintish does not assume any obligation of any kind to you or any third
      party with respect to your User-Generated Content. UponMintish’s request,
      you will furnish us with any documentation necessary to substantiate the
      rights to such content and to verify your compliance with these Terms or
      any Additional Terms.
     </p>
     <p>
      2. You acknowledge that the Internet and mobile communications may be
      subject to breaches of security and that you are aware that submissions of
      User-Generated Content may not be secure, and you will consider this
      before submitting any User-Generated Content and do so at your own risk.
      In your communications with Mintish, please keep in mind that we do not
      seek any unsolicited ideas or materials for products or services, or even
      suggested improvements to products or services, including, without
      limitation, ideas, concepts, inventions, or designs for music, websites,
      apps, books, scripts, screenplays, motion pictures, television shows,
      theatrical productions, software or otherwise (collectively, "Unsolicited
      Ideas and Materials").
     </p>
     <p>
      3. Any Unsolicited Ideas and Materials you post on or send to us via the
      Service are deemed User-Generated Content and licensed to us as set forth
      below. In addition, Mintish retains all of the rights held by members of
      the general public with regard to your Unsolicited Ideas and Materials.
      Mintish’s receipt of your Unsolicited Ideas and Materials is not an
      admission by Mintish of their novelty, priority, or originality, and it
      does not impair Mintish’s right to contest existing or future intellectual
      property rights relating to your Unsolicited Ideas and Materials.
     </p>
     <h5>3. License to Mintish of Your User - Generated Content</h5>
     <p>
      1. Except as otherwise described in any applicable Additional Terms, you
      grant to Mintish the non-exclusive, unrestricted, unconditional,
      unlimited, worldwide, irrevocable, perpetual, and cost-free right and
      license to use, copy, record, distribute, reproduce, disclose, sell,
      re-sell, sublicense (through multiple levels), display, publicly perform,
      transmit, publish, broadcast, translate, make derivative works of, and
      otherwise use and exploit in any manner whatsoever, all or any portion of
      your User- Generated Content (and derivative works thereof), for any
      purpose whatsoever in all formats, on or through any means or medium now
      known or hereafter developed, and with any technology or devices now known
      or hereafter developed, and to advertise, market, and promote the same.
      Without limitation, the granted rights include the right to: (a)
      configure, host, index, cache, archive, store, digitize, compress,
      optimize, modify, reformat, edit, adapt, publish in searchable format, and
      remove such User- Generated Content and combine same with other materials,
      and (b) use any ideas, concepts, know-how, or techniques contained in any
      User-Generated Content for any purposes whatsoever, including developing,
      producing, and marketing products and/or services.
     </p>
     <p>
      2. You understand that in exercising such rights metadata, notices and
      content may be removed or altered, including copyright management
      information, and you consent thereto and represent and warrant you have
      all necessary authority to do so. In order to further effect the rights
      and license that you grant to Mintish to your User-Generated Content, you
      also hereby grant to Mintish, and agree to grant to Mintish, the
      unconditional, perpetual, irrevocable right to use and exploit your name,
      persona, and likeness in connection with any User-Generated Content,
      without any obligation or remuneration to you.
     </p>
     <p>
      3. Except as prohibited by law, you hereby waive, and you agree to waive,
      any moral rights (including attribution and integrity) that you may have
      in any User-Generated Content, even if it is altered or changed in a
      manner not agreeable to you. To the extent not waivable, you irrevocably
      agree not to exercise such rights (if any) in a manner that interferes
      with any exercise of the granted rights. You understand that you will not
      receive any fees, sums, consideration, or remuneration for any of the
      rights granted in this Section.
     </p>
     <h5>4. Exclusive Right to Manage Our Service</h5>
     <p>
      1. Mintish may, but will not have any obligation to, review, monitor,
      display, post, store, maintain, accept, or otherwise make use of, any of
      your User-Generated Content, and Mintish may, in its sole discretion,
      reject, delete, move, re-format, remove or refuse to post or otherwise
      make use of User-Generated Content without notice or any liability to you
      or any third party in connection with our operation of User-Generated
      Content venues in an appropriate manner. Without limitation, we may do so
      to address content that comes to our attention that we believe is
      offensive, obscene, lewd, lascivious, filthy, violent, harassing,
      threatening, abusive, illegal or otherwise objectionable or inappropriate,
      or to enforce the rights of third parties or these Terms or any applicable
      Additional Terms,including, without limitation, the content restrictions
      set forth below in the Rules.
     </p>
     <p>
      2. Such User-Generated Content submitted by you or others need not be
      maintained on the Service by us for any period of time and you will not
      have the right, once submitted, to access, archive, maintain, or otherwise
      use such User-Generated Content on the Service or elsewhere.
     </p>
     <h5>
      5. Representations and Warranties Related to Your User - Generated Content
     </h5>
     <p>
      1. Each time you submit any User-Generated Content, you represent and
      warrant that you are at least the age of majority in the jurisdiction in
      which you reside or are the parent or legal guardian, or have all proper
      consents from the parent or legal guardian, of any minor who is depicted
      in or contributed to any User-Generated Content you submit, and that, as
      to that User-Generated Content: (a) you are the sole author and owner of
      the intellectual property and other rights to the User-Generated Content,
      or you have a lawful right to submit the User-Generated Content and grant
      Mintish the rights to it that you are granting by these Terms and any
      Additional Terms, all without any Mintish obligation to obtain consent of
      any third party and without creating any obligation or liability of
      Mintish; (b) the User-Generated Content is accurate; (c) the User-
      Generated Content does not and, as to Mintish’s permitted uses and
      exploitation set forth in these Terms, will not infringe any intellectual
      property or other right of any third party; and (d) the User-Generated
      Content will not violate these Terms (including the Rules) or any
      Additional Terms, or cause injury or harm to any person.
     </p>
     <h5>6. Enforcement</h5>
     <p>
      1. Mintish has no obligation to monitor or enforce your intellectual
      property rights to your User-Generated Content, but you grant us the right
      to protect and enforce our rights to your User-Generated Content,
      including by bringing and controlling actions in your name and on your
      behalf (at Mintish’s cost and expense, to which you hereby consent and
      irrevocably appoint Mintish as your attorney-in-fact, with the power of
      substitution and delegation, which appointment is coupled with an
      interest).
     </p>
     <h5>7. Community Usage Rules</h5>
     <p>
      1. As a user of the Service, these Community Usage Rules ("Rules") are
      here to help you understand the conduct that is expected of visitors of
      the Service's online forums and work spaces ("Communities").
     </p>
     <p>
      {' '}
      2. Nature of Rules. Your participation in the Communities is subject to
      all of the Terms, including these Rules:
     </p>
     <p>
      <h5>a. Your User - Generated Content.</h5> All of your User-Generated
      Content either must be original with you or you must have all necessary
      rights in it from third parties in order to permit you to comply with
      these Terms and any Additional Terms. Your User- Generated Content should
      not contain any visible logos, phrases, or trademarks that belong to third
      parties. Do not use any User- Generated Content that belongs to other
      people and pass it off as your own; this includes any content that you
      might have found elsewhere on the Internet. If anyone contributes to your
      User- Generated Content or has any rights to your User-Generated Content,
      or if anyone appears or is referred to in the User-Generated Content, then
      you must also have their permission to submit such User-Generated Content
      to Mintish (for example, if someone has taken a picture of you and your
      friend, and you submit that photo toMintish as your User-Generated
      Content, then you must obtain your friend's and the photographer's
      permission to do so.).
     </p>
     <p>
      <h5>b. Speaking of Photos.</h5> No Pictures, Videos, or Images of Anyone
      Other Than You and Your Friends and Family. If you choose to submit photos
      to the Service, link to embedded videos, or include other images of real
      people, then make sure they are of you or of you and someone you know -
      and only if you have their express permission to submit it. You may choose
      to submit royalty free pictures, videos or images or content that you own
      and it is you the sole person liable of the usage of that content.
     </p>
     <p>
      <h5>c. Act Appropriately.</h5> All of your Service activities must be
      venue appropriate, as determined by us. Be respectful of others' opinions
      and comments so we can continue to build Communities for everyone to
      enjoy. If you think your User-Generated Content might offend someone or be
      embarrassing to someone, then chances are it probably will and it doesn't
      belong on the Service. Cursing, harassing, bullying, stalking, insulting
      comments, personal attacks, gossip, and similar actions are prohibited.
      Your User-Generated Content must not threaten, abuse, or harm others, and
      it must not include any negative comments that are connected to race,
      national origin, gender, sexual orientation, or physical handicap. Your
      User- Generated Content must not be defamatory, slanderous, indecent,
      obscene, pornographic, or sexually explicit.
     </p>
     <p>
      <h5> Do Not Use for Commercial or Political Purposes.</h5>
      Your User- Generated Content must not advertise or promote a product or
      service or other commercial activity, or a politician, public servant, or
      law.
     </p>
     <p>
      <h5> e. Do Not Use for Inappropriate Purposes.</h5>
      Your User-Generated Content must not promote any infringing, illegal, or
      other similarly inappropriate activity.
     </p>
     <h5>
      f. Be Honest and Do Not Misrepresent Yourself or Your User - Generated
      Content.{' '}
      <p>
       Do not impersonate any other person, user, or company, and do not submit
       User-Generated Content that you believe may be false, fraudulent,
       deceptive, inaccurate, or misleading, or that misrepresents your identity
       or affiliation with a person or company.
      </p>{' '}
      ANY LICENSOR, LICENSEE, THEIR AGENTS OR ANY OTHER USER OF ANY KIND THAT
      ENGAGES IN THIS CONDUCT OR ANY OTHER SIMILAR CONDUCT RELATED TO THIS
      MATTER SHALL BE COMPLETELY, SOLELY, AND PERSONALLY LIABLE TO ANY LEGAL
      (CIVIL OR CRIMINAL) CONSEQUENCE. Mintish SHALL NOT HAVE ANY LIABILITY
      ARISING FROM OR IN ANY WAY RELATED TO THIS CONDUCT AND MAY, BUT IS NOT
      OBLIGATED TO, AT IT’S OWN DISCRETION, TRY TO FIND LICENSORS AND LICENSEES
      THAT DO ENGAGE IN THIS CONDUCT AND Mintish SHALL TERMINATE THEIR
      AGREEMENT, ACCOUNT AND MAY BANNED THE PERMANENTLY FROM OUR PLATFORM AND
      SERVICES. ENGAGING IN THIS PARTICULAR CONDUCT CONSTITUTES JUST CAUSE TO
      TERMINATE THIS AGREEMENT WITHOUT ADVANCE NOTICE OR LIABILITY.
     </h5>
     <p>
      <h5>g. Others Can See.</h5>
      We hope that you will use the Communities to exchange information and
      content and have venue appropriate discussions about job search-related
      issues with other members. However, please remember that the Communities
      are public or semi-public and User-Generated Content that you submit on
      the Service within a Community may be accessible and viewable by other
      users. Do not submit personally identifying information (e.g., first and
      last name together, password, phone number, address, credit card number,
      medical information, email address, or other personally identifiable
      information or contact information) on Community spaces and take care when
      disclosing this type of information to others.
     </p>
     <p>
      h. Don't Share Other Peoples' Personal Information. Your User- Generated
      Content should not reveal another person's address, phone number, e-mail
      address, social security number, credit card number, medical information,
      financial information, or any other information that may be used to track,
      contact, or impersonate that individual, unless, and in the form and by
      the method, specifically requested by Mintish.
     </p>
     <p>
      <h5>
       i. Don't Damage the Service or Anyone's Computers or Other Devices.
      </h5>
      Your User-Generated Content must not submit viruses, Trojan horses,
      spyware, or any other technologies or malicious code that could impact the
      operation of the Service or any computer or other Device.
     </p>
     <p>
      <h5>j. Your Interactions With Other Users; Disputes. </h5>
      You are solely responsible for your interaction with other users of the
      Service, whether online or offline. We are not responsible or liable for
      the conduct or content of any user. We reserve the right, but have no
      obligation, to monitor or become involved in disputes between you and
      other users. Exercise common sense and your best judgment in your
      interactions with others (e.g., when you submit any personal or other
      information) and in all of your other online activities.
     </p>
     <p>
      <h5> Alerting Us of Violations</h5>
      If you discover any content that violates these Terms, then you may report
      it to us here. For alleged infringements of intellectual property rights.
     </p>
     <h5>6. Procedure For Alleging Copyright Infringement</h5>
     <p>
      <h6>1. DMCA Notice </h6>Mintish will respond appropriately to notices of
      alleged copyright infringement that comply with the U.S. Digital
      Millennium Copyright Act ("DMCA"), as set forth below. If you own a
      copyright in a work (or represent such a copyright owner) and believe that
      your (or such owner's) copyright in that work has been infringed by an
      improper posting or distribution of it via the Service, then you may send
      us a written notice, with the following information: (i) a description of
      the copyrighted work that you claim has been infringed or, if multiple
      copyrighted works are covered by a single notification, a representative
      list of such works; (ii) a description of where the material that you
      claim is infringing or is the subject of infringing activity is located
      that is reasonably sufficient to permit us to locate the material (please
      include the URL or page of the Service on which the material appears);
      (iii) your full name, address, telephone number and email address; (iv) a
      statement by you that you have a good faith belief that use of the
      material in the manner complained of is not authorized by the copyright
      owner, its agent, or the law; (v) a statement by you, made under penalty
      of perjury, that all the information in your notice is accurate, and that
      you are the copyright owner (or, if you are not the copyright owner, then
      your statement must indicate that you are authorized to act on the behalf
      of the owner of an exclusive right that is allegedly infringed); and (vi)
      your electronic or physical signature.
     </p>
     <p>
      2. Mintish will only respond to DMCA Notices that submit the complete
      information. It is often difficult to determine if your copyright has been
      infringed. Mintish may elect to not respond to DMCA Notices that do not
      substantially comply with all of the foregoing requirements, and Mintish
      may elect to remove allegedly infringing material that comes to its
      attention via notices that do not substantially comply with the DMCA.
      Please note that the DMCA provides that any person who knowingly
      materially misrepresents that material or activity is infringing may be
      subject to liability. We may send the information that you provide in your
      notice to the person who provided the allegedly infringing work. That
      person may elect to send us a DMCA Counter-Notification. Without limiting
      Mintish’s other rights, Mintish may, in appropriate circumstances,
      terminate a repeat infringer's access to the Service and any other website
      owned or operated by Mintish.
     </p>
     <p>
      3. Counter - Notification. If access on the Service to a work that you
      submitted to Mintish is disabled or the work is removed as a result of a
      DMCA Notice, and if you believe that the disabled access or removal is the
      result of mistake or misidentification, then you may send us a DMCA
      Counter-Notification to the following address:{' '}
      <span className="span_text">
       {' '}
       By Mail: Mintish LLC, CLM BlDG 100 CARR 165, Torre 1 Suite 708, Guaynabo,
       Puerto Rico, 00968. By email: contact@musicafreshllc.com
      </span>
     </p>
     <p>
      Your DMCA Counter-Notification should contain the following information:
      (i) a legend or subject line that says: "DMCA Counter-Notification"; (ii)
      a description of the material that has been removed or to which access has
      been disabled and the location at which the material appeared before it
      was removed or access to it was disabled (please include the URL or page
      of the Service from which the material was removed or access to it
      disabled); (iii) a statement under penalty of perjury that you have a good
      faith belief that the material was removed or disabled as a result of
      mistake or misidentification of the material to be removed or disabled;
      (iv) your full name, address, telephone number, e-mail address, and the
      username of your account; (v) a statement that you consent to the
      jurisdiction of the Federal District Court for the judicial district in
      which your address is located (or, if the address is located outside the
      U.S.A., to the jurisdiction of the United States District Court of San
      Juan, Puerto Rico), and that you will accept service of process from the
      person who provided DMCA notification to us or an agent of such person;
      and your electronic or physical signature.Please note that the DMCA
      provides that any person who knowingly materially misrepresents that
      material or activity was removed or disabled by mistake or
      misidentification may be subject to liability. If we receive a DMCA
      Counter-Notification, then we may replace the material that we removed (or
      stop disabling access to it) in not less than ten (10) and not more than
      fourteen (14) business days following receipt of the DMCA Counter-
      Notification. However, we will not do this if we first receive notice at
      the addresses above that the party who sent us the DMCA Copyright
      Infringement Notice has filed a lawsuit asking a court for an order
      restraining the person who provided the material from engaging in
      infringing activity relating to the material on the Service. You should
      also be aware that we may forward the Counter- Notification to the party
      who sent us the DMCA Copyright Infringement Notice.
     </p>
     <h5>
      7. Procedure For Alleging Infringement of Other Intellectual Property.
     </h5>
     <h5>1. General</h5>
     <p>
      If you own intellectual property other than copyrights and believe that
      your intellectual property has been infringed by an improper posting or
      distribution of it via the Service, then you may send us an email to
      contact@musicafreshllc.com that includes all of the following: (i) a
      legend or subject line that says: "Intellectual Property Infringement
      Notice"; (ii) a description of the intellectual property that you claim
      has been infringed; (iii) a description of where the material that you
      claim is infringing or is the subject of infringing activity is located
      that is reasonably sufficient to permit us to locate the material (please
      include the URL or page of the Service on which the material appears);
      (iv) your full name, address, telephone number and email address; (v) a
      statement by you that you have a good faith belief that use of the
      material in the manner complained of is not authorized by the owner of the
      intellectual property, its agent, or the law; (vi) a statement by you,
      made under penalty of perjury, that all the information in your notice is
      accurate, and that you are the owner of the intellectual property at issue
      (or, if you are not the owner, then your statement must indicate that you
      are authorized to act on the behalf of the owner of the intellectual
      property that is allegedly infringed); and your electronic or physical
      signature. We will act on such notices in our sole discretion. Any user of
      the Service that fails to respond satisfactorily to Mintish with regard to
      any such notice is subject to suspension or termination. We may send the
      information that you provide in your notice to the person who provided the
      allegedly infringing material.
     </p>
     <h5>8. Notices and Questions</h5>
     <p>
      You agree that: (i) we may give you notices of new, revised or changed
      terms and other important matters by prominently posting notice on the
      homepage of the Service, or in another reasonable manner that we may
      elect; and (ii) we may contact you by mail or email sent to the address
      provided by you. You agree to promptly notify us if you change your email
      or mailing address by updating your account information. If you have a
      question regarding using the Service, you may contact us at
      contact@musicafreshllc.com. You acknowledge that the provision of customer
      support is at Mintish’s sole discretion and that we have no obligation to
      provide you with customer support of any kind.
     </p>
     <h5>9. Links by You to the Service</h5>
     <p>
      We grant you a limited, non-exclusive, revocable, non-assignable,
      personal, and non-transferable license to create hyperlinks to the
      Service, so long as: (a) the links only incorporate text, and do not use
      any Trademarks, (b) the links and the content on your website do not
      suggest any affiliation with Mintish or cause any other confusion, and (c)
      the links and the content on your website do not portrayMintish or its
      products or services in a false, misleading, derogatory, or otherwise
      offensive matter, and do not contain content that is unlawful, offensive,
      obscene, lewd, lascivious, filthy, violent, threatening, harassing, or
      abusive, or that violate any right of any third party or are otherwise
      objectionable to Mintish. Mintish reserves the right to suspend or
      prohibit linking to the Service for any reason, in its sole discretion,
      without advance notice or any liability of any kind to you or any third
      party.
     </p>
     <h5>
      10. Linked-To Websites; Advertisements; Dealings with Third Parties
     </h5>
     <h5>1. General</h5>
     <p>
      1. The Service may contain links, as part of third-party ads on the
      Service or otherwise, to or from third-party websites ("Linked Services"),
      including websites operated by advertisers, licensors, licensees,
      recruitment services and certain other third parties who may have business
      relationships with Mintish. Mintish may have no control over the content,
      operations, policies, terms, or other elements of Linked Services, and
      Mintish does not assume any obligation to review any Linked Services.
      Mintish does not endorse, approve, or sponsor any Linked Services, or any
      third-party content, advertising, information, materials, products,
      services, or other items. Furthermore, Mintish is not responsible for the
      quality or delivery of the products or services offered, accessed,
      obtained by or advertised at such Services. Finally, Mintish will under no
      circumstances be liable for any direct, indirect, incidental or special
      loss or other damage, whether arising from negligence, breach of contract,
      defamation, infringement of copyright or other intellectual property
      rights, caused by the exhibition, distribution or exploitation of any
      information or content contained within these third-party Linked Services.
      Any activities you engage in connection with any of the same are subject
      to the privacy and other policies, terms and conditions of use and/or
      sale, and rules issued by the operator of the Linked Services. Mintish
      disclaims all liability in connection therewith.
     </p>
     <h5>2. Dealings with Third Parties</h5>
     <p>
      1. Any interactions, correspondence, transactions, and other dealings that
      you have with any third parties found on or through the Service (including
      on or via Linked Services or advertisements) are solely between you and
      the third party (including issues related to the content of third-party
      advertisements, payments, delivery of goods, warranties (including product
      warranties), privacy and data security, and the like). Mintish disclaims
      all liability in connection therewith.
     </p>
     <h5>11. Wireless Features</h5>
     <h5>1. Wireless Features</h5>
     <p>
      1. The Service may offer certain features and services that are available
      to you via your wireless Device. These features and services may include
      the ability to access the Service's features and upload content to the
      Service, receive messages from the Service, and download applications to
      your wireless Device (collectively, "Wireless Features"). Standard
      messaging, data, and other fees may be charged by your carrier to
      participate in Wireless Features. Fees and charges may appear on your
      wireless bill or be deducted from your pre-paid balance. Your carrier may
      prohibit or restrict certain Wireless Features and certain Wireless
      Features may be incompatible with your carrier or wireless Device. You
      should check with your carrier to find out what plans are available and
      how much they cost. Contact your carrier with questions regarding these
      issues.
     </p>
     <h5>2. Terms of Wireless Features</h5>
     <p>
      1. You agree that as to the Wireless Features for which you are
      registered, we may send communications to your wireless Device regarding
      us or other parties. Further, we may collect information related to your
      use of the Wireless Features. If you have registered via the Service for
      Wireless Features, then you agree to notify us of any changes to your
      wireless contact information (including phone number) and update your
      accounts on the Service to reflect the changes.
     </p>
     <h5>Providing Telephone Numbers and Other Contact Information.</h5>
     <p>
      1. You verify that any contact information provided to Mintish, including,
      but not limited to, your name, mailing address, email address, your
      residential or business telephone number, and/or your mobile telephone
      number, is true and accurate. You verify that you are the current
      subscriber or owner of any telephone number that you provide. You are
      strictly prohibited from providing a phone number that is not your own. If
      we discover that any information provided in connection with your account
      is false or inaccurate, we may suspend or terminate your account at any
      time. Should any of your contact information change, including ownership
      of your telephone numbers, you agree to immediately notify us before the
      change goes into effect by contacting us at contact@musicafreshllc.com.
     </p>
     <h5>4. Unauthorized Use of Your Telephone Device.</h5>
     <p>
      1. You must notify Mintish immediately of any breach of security or
      unauthorized use of your telephone device. Although Mintish, our agents,
      and affiliates will not be liable for losses caused by any unauthorized
      use of your telephone device, you may be liable for our losses due to such
      unauthorized use.
     </p>
     <h5>12. Dispute Resolution</h5>
     <h5>1. General</h5>
     <h5>
      FIRST - TRY TO RESOLVE DISPUTES AND EXCLUDED DISPUTES.IF ANY CONTROVERSY,
      ALLEGATION, OR CLAIM ARISES OUT OF OR RELATES TO THE SERVICE, THE CONTENT,
      YOUR USER-GENERATED CONTENT, THESE TERMS, OR ANY ADDITIONAL TERMS, WHETHER
      HERETOFORE OR HEREAFTER ARISING (COLLECTIVELY, "DISPUTE"), OR TO ANY OF
      Mintish’S ACTUAL OR ALLEGED INTELLECTUAL PROPERTY RIGHTS (AN "EXCLUDED
      DISPUTE", THEN YOU AND WE AGREE TO SEND A WRITTEN NOTICE TO THE OTHER
      PROVIDING A REASONABLE DESCRIPTION OF THE DISPUTE OR EXCLUDED DISPUTE,
      ALONG WITH A PROPOSED RESOLUTION OF IT. OUR NOTICE TO YOU WILL BE SENT TO
      YOU BASED ON THE MOST RECENT CONTACT INFORMATION THAT YOU PROVIDE US. BUT
      IF NO SUCH INFORMATION EXISTS OR IF SUCH INFORMATION IS NOT CURRENT, THEN
      WE HAVE NO OBLIGATION UNDER THIS PARAGRAPH. YOUR NOTICE TO US MUST BE SENT
      VIA EMAIL TO CONTACT@MUSICAFRESHLLC.COM. FOR A PERIOD OF SIXTY (60) DAYS
      FROM THE DATE OF RECEIPT OF NOTICE FROM THE OTHER PARTY, Mintish AND YOU
      WILL ENGAGE IN A DIALOGUE IN ORDER TO ATTEMPT TO RESOLVE THE DISPUTE OR
      EXCLUDED DISPUTE, THOUGH NOTHING WILL REQUIRE EITHER YOU OR Mintish TO
      RESOLVE THE DISPUTE OR EXCLUDED DISPUTE ON TERMS WITH RESPECT TO WHICH YOU
      AND Mintish, IN EACH OF OUR SOLE DISCRETION, ARE NOT COMFORTABLE.
      APPLICABLE LAW/JURISDICTION: THIS AGREEMENT SHALL BE CONSTRUED IN
      ACCORDANCE WITH THE LAWS OF THE COMMONWEALTH OF PUERTO RICO. IN CASE OF
      DISPUTE ABOUT IT, THE PARTIES SUBMIT TO THE JURISDICTION OF THE COURT OF
      FIRST INSTANCE OF PUERTO RICO, SAN JUAN SECTION. NO CLASS ACTION MATTERS.
      YOU AND Mintish AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
      YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
      ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING OR AS AN ASSOCIATION.
      GOVERNING LAW.THESE TERMS AND ANY ADDITIONAL TERMS WILL BE GOVERNED BY AND
      CONSTRUED IN ACCORDANCE WITH, AND ANY DISPUTE AND EXCLUDED DISPUTE WILL BE
      RESOLVED IN ACCORDANCE WITH THE LAWS OF PUERTO RICO, WITHOUT REGARD TO ITS
      CONFLICTS OF LAW PROVISIONS.
     </h5>
     <h5>13. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES</h5>
     <h5>1. General</h5>
     <h5>
      YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
      PROVIDED ON AN "AS IS", "AS AVAILABLE", AND "WITH ALL FAULTS"
     </h5>
     <p>
      Therefore, to the fullest extent permissible by law, Mintish, its parent
      company and subsidiaries, and each of their respective employees,
      officers, directors, members, managers, shareholders, agents, vendors,
      licensors, licensees, contractors, customers, successors, and assigns
      (collectively, the "Mintish Parties"), hereby disclaim and make no
      representations, warranties, endorsements, or promises, express or
      implied, as to:
     </p>
     <p>
      1. the Service (including the Content, Music Content and the
      User-Generated Content);
     </p>
     <p>
      2. the functions, features, or any other elements on, or made accessible
      through, the Service;{' '}
     </p>
     <p>
      3. any products, services, developer code or instructions offered or
      referenced at or linked through the Service;
     </p>
     <p>
      {' '}
      4. security associated with the transmission of your User-Generated
      Content transmitted to Mintish via the Service;
     </p>{' '}
     <p>
      {' '}
      5. whether the Service or the servers that make the Service available are
      free from any harmful components (including viruses, Trojan horses, and
      other technologies that could adversely impact your Device);
     </p>{' '}
     <p>
      {' '}
      6. whether the information (including any instructions) on the Service is
      accurate, complete, correct, adequate, useful, timely, or reliable;
     </p>{' '}
     <p>
      7. whether any defects to, or errors on, the Service will be repaired or
      corrected;
     </p>{' '}
     <p> 8. whether your access to the Service will be uninterrupted;</p>
     <p>
      9. whether the Service will be available at any particular time or
      location; and
     </p>{' '}
     <p>
      {' '}
      10. whether your use of the Service is lawful in any particular
      jurisdiction.
     </p>
     <h5>
      EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN OR IN ADDITIONAL TERMS
      PROVIDED BY A Mintish PARTY, IF ANY, TO THE EXTENT PERMITTED BY APPLICABLE
      LAW, THE Mintish PARTIES HEREBY FURTHER DISCLAIM ALL WARRANTIES, EXPRESS
      OR IMPLIED, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, NON-INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL
      PROPERTY RIGHTS OF THIRD PARTIES, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT,
      SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS.
     </h5>
     <p>
      Some jurisdictions limit or do not allow the disclaimer of implied or
      other warranties so the above disclaimers may not apply to the extent such
      jurisdictions' laws are applicable.
     </p>
     <h5>14. LIMITATIONS OF OUR LIABILITY</h5>
     <h5>1. General</h5>
     <p>
      TO THE EXTENT PERMITTED UNDER ANY APPLICABLE LAW, UNDER NO CIRCUMSTANCES
      WILL ANY Mintish PARTIES BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGES
      OF ANY KIND, including personal injury or death or for any direct,
      indirect, economic, exemplary, special, punitive, incidental, or
      consequential losses or damages that are directly or indirectly related
      to:
     </p>
     <p>
      1. the Service (including the Content, Music Content and the User-
      Generated Content;
     </p>
     <p>
      2. your use of or inability to use the Service, or the performance of the
      Service;
     </p>{' '}
     <p>
      3. any action taken in connection with an investigation by Mintish Parties
      or law enforcement authorities regarding your access to or use of the
      Service;
     </p>{' '}
     <p>
      4. any action taken in connection with copyright or other intellectual
      property owners or other rights owners;
     </p>{' '}
     <p>
      5. any injury or damages you sustain directly or indirectly as a result of
      your use of the Service or any Music Content;
     </p>{' '}
     <p>6. any errors or omissions in the Service's technical operation; or</p>{' '}
     <p>
      7. any damage to any user's computer, hardware, software, modem, or other
      equipment or technology, including damage from any security breach or from
      any virus, bugs, tampering, fraud, error, omission, interruption, defect,
      delay in operation or transmission, computer line, or network failure or
      any other technical or other malfunction, including losses or damages in
      the form of lost profits, loss of goodwill, loss of data, work stoppage,
      accuracy of results, or equipment failure or malfunction.
     </p>
     <p>
      The foregoing limitations of liability will apply even if any of the
      foregoing events or circumstances were foreseeable and even if Mintish
      Parties were advised of or should have known of the possibility of such
      losses or damages, regardless of whether you bring an action based in
      contract, negligence, strict liability, or tort (including whether caused,
      in whole or in part, by negligence, acts of god, telecommunications
      failure, or destruction of the Service).
     </p>
     <p>
      Some jurisdictions do not allow the exclusion or limitation of incidental
      or consequential damages of the sort that are described above, so the
      above limitation or exclusion may not apply to you.
     </p>
     <h5>
      EXCEPT AS MAY BE PROVIDED IN ANY ADDITIONAL TERMS, TO THE FULLEST EXTENT
      PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL Mintish PARTIES' TOTAL
      LIABILITY TO YOU, FOR ALL POSSIBLE DAMAGES, LOSSES, AND CAUSES OF ACTION
      IN CONNECTION WITH YOUR ACCESS TO AND USE OF THE SERVICE AND YOUR RIGHTS
      UNDER THESE TERMS, EXCEED AN AMOUNT EQUAL TO THE AMOUNT YOU HAVE PAID
      Mintish TO ACCESS THE SERVICE OR IN CONNECTION WITH THE TRANSACTION(S)
      THAT UNDERLIE THE CLAIM(S); PROVIDED, HOWEVER, THIS PROVISION WILL NOT
      APPLY IF A TRIBUNAL WITH APPLICABLE JURISDICTION FINDS SUCH TO BE
      UNCONSCIONABLE. FOR PURPOSES OF CLARITY, THE PRIOR SENTENCE DOES NOT
      EXPAND OR LIMIT ANY EXPRESS, WRITTEN PRODUCT WARRANTY THAT IS PROVIDED BY
      US.
     </h5>
     <h5>15. Updates to Terms</h5>
     <h6>1. General</h6>
     <h5>
      1. These Terms (or if applicable Additional Terms), in the form posted at
      the time of your use of the applicable services to which it applies, shall
      govern such use (including transactions entered during such use). AS OUR
      SERVICE EVOLVES, THE TERMS AND CONDITIONS UNDER WHICH WE OFFER THE SERVICE
      MAY PROSPECTIVELY BE MODIFIED AND WE MAY CEASE OFFERING THE SERVICE UNDER
      THE TERMS OR ADDITIONAL TERMS FOR WHICH THEY WERE PREVIOUSLY OFFERED.
      ACCORDINGLY, EACH TIME YOU SIGN IN TO OR OTHERWISE USE THE SERVICE YOU ARE
      ENTERING INTO A NEW AGREEMENT WITH US ON THE THEN APPLICABLE TERMS AND
      CONDITIONS AND YOU AGREE THAT WE MAY NOTIFY YOU OF OTHER TERMS BY POSTING
      THEM ON THE SERVICE (OR IN ANY OTHER REASONABLE MANNER OF NOTICE WHICH WE
      ELECT), AND THAT YOUR USE OF THE SERVICE AFTER SUCH NOTICE CONSTITUTES
      YOUR GOING FORWARD AGREEMENT TO THE OTHER TERMS FOR YOUR NEW USE AND
      TRANSACTIONS.
     </h5>
     <p>
      2. Therefore, you should review the posted terms of use and any applicable
      Additional Terms each time you use the Service (at least prior to each
      transaction or submission). The Additional Terms will be effective as to
      new use and transactions as of the time that we post them, or such later
      date as may be specified in them or in other notice to you. However, the
      Terms (and any applicable Additional Terms) that applied when you
      previously used the Service will continue to apply to such prior use
      (i.e., changes and additions are prospective only) unless mutually agreed.
      In the event any notice to you of new, revised or additional terms is
      determined by a tribunal to be insufficient, the prior agreement shall
      continue until sufficient notice to establish a new agreement occurs.
     </p>
     <p>
      3. You should frequently check the home page and the email you associated
      with your account for notices, and you agree that the means set forth in
      these Terms are all reasonable manners of providing you with notice. You
      can reject any new, revised or Additional Terms by discontinuing use of
      the Service and related services.
     </p>
     <h5>16. General Provisions</h5>
     <h5>1. Mintish’s Consent or Approval</h5>
     <p>
      1. As to any provision in these Terms or any Additional Terms that grant
      Mintish a right of consent or approval, or permits Mintish to exercise a
      right in its "sole discretion," Mintish may exercise that right in its
      sole and absolute discretion. No opt-in consent or approval may be deemed
      to have been granted by Mintish without being in writing and signed by an
      officer of Mintish.
     </p>
     <h5>2. Relationship of the Parties</h5>
     <p>
      1. The parties hereto agree and acknowledge that the relationship between
      them is that of independent contractors. This Agreement shall not be
      deemed to create an agency, partnership or joint venture between you and
      Mintish, and Mintish shall not have a fiduciary obligation to you as a
      result of your entering into this Agreement.
     </p>
     <h5>3. Entire Agreement</h5>
     <p>
      1. This Agreement together with the TOS contains the entire understanding
      of the parties relating to the subject matter hereof. This Agreement
      (including all Addenda) supersedes all previous agreements or arrangements
      between you and Mintish pertaining to the Services, provided that if you
      previously entered into a digital distribution or consignment agreement
      with us in the past, and elected any options specifying or limiting the
      scope of the distribution of Your Content, the limitations and
      authorizations with respect to the distribution of Your Content shall
      remain in place under this Agreement and the applicable Addendum. This
      Agreement cannot be changed or modified except as provided herein.
     </p>
     <h5>4. Binding Effect</h5>
     <p>
      1. This Agreement will be binding on the assigns, heirs, executors,
      affiliates, agents, personal representatives, administrators, and
      successors (whether through merger, operation of law, or otherwise) of
      each of the parties.
     </p>
     <h5>5. Rights Cumulative</h5>
     <p>
      1. To the extent permitted by applicable law, the rights and remedies of
      the parties provided under this Agreement are cumulative and are in
      addition to any other rights and remedies of the parties at law or equity.
     </p>
     <h5>6. No Third Party Beneficiaries</h5>
     <p>
      1. This Agreement is for the sole benefit of the parties hereto and their
      authorized successors and permitted assigns. Nothing herein, express or
      implied, is intended to or shall confer upon any person or entity, other
      than the parties hereto and their authorized successors and permitted
      assigns, any legal or equitable right, benefit or remedy of any nature
      whatsoever under or by reason of this Agreement.
     </p>
     <h5>7. Headings</h5>
     <p>
      1. The titles and headings used in this Agreement are for convenience only
      and are not to be considered in construing or interpreting this Agreement.
     </p>
     <h5>8. Assignment</h5>
     <p>
      1. Mintish may assign its rights and obligations under this Agreement at
      any time to any party. You may not assign your rights and/or obligations
      under this Agreement without obtaining Mintish’s prior written consent.
     </p>
     <h5>9. Indemnity</h5>
     <p>
      1. You agree to, and you hereby, defend, indemnify, and hold Mintish
      Parties harmless from and against any and all claims, damages, losses,
      costs, investigations, liabilities, judgments, fines, penalties,
      settlements, interest, and expenses (including attorneys' fees) that
      directly or indirectly arise from or are related to any claim, suit,
      action, demand, or proceeding made or brought against any Mintish Party,
      or on account of the investigation, defense, or settlement thereof,
      arising out of or in connection with, whether occurring heretofore or
      hereafter: (i) your User-Generated Content; (ii) your use of the Service
      and your activities in connection with the Service; (iii) your breach or
      alleged breach of these Terms or any Additional Terms; (iv) your violation
      or alleged violation of any laws, rules, regulations, codes, statutes,
      ordinances, or orders of any governmental or quasi-governmental
      authorities in connection with your use of the Service or your activities
      in connection with the Service; (v) information or material transmitted
      through your Device, even if not submitted by you, that infringes,
      violates, or misappropriates any copyright, trademark, trade secret, trade
      dress, patent, publicity, privacy, or other right of any person or entity;
      (vi) any misrepresentation made by you; and (vii) Mintish Parties' use of
      the information or content that you submit to us (including your
      User-Generated Content and Music Content) (all of the foregoing, "Claims
      and Losses").
     </p>
     <p>
      2. You will cooperate as fully required by Mintish Parties in the defense
      of any Claims and Losses. Notwithstanding the foregoing,Mintish Parties
      retain the exclusive right to settle, compromise, and pay any and all
      Claims and Losses. Mintish Parties reserve the right to assume the
      exclusive defense and control of any Claims and Losses. You will not
      settle any Claims and Losses without, in each instance, the prior written
      consent of an officer of a Mintish Party.
     </p>
     <p>
      3. Furthermore, you agree to, and you hereby, defend, indemnify, and hold
      Mintish Parties harmless from and against any and all, but not limited to,
      any Claims made by a PRO or music publisher with respect to any public
      performances or communications to the public of any musical works embodied
      in Your Content, any contributor to any sound recording included within
      Your Content, including claims from any unions, guilds, background
      musicians for vocalists, engineers, etc., or any other party for any use
      or misuse of any other forms of intellectual property or proprietary
      rights in Your Content, including, but not limited to, trademark rights
      and invasions of the right of privacy or publicity. You agree to reimburse
      us, on demand, for any payment made by us at any time with respect to any
      Claims to which the foregoing indemnity applies. Pending the resolution of
      any claim, demand, or action, we may, at our election, withhold payment of
      any monies otherwise payable to you hereunder in an amount which does not
      exceed your potential liability to us pursuant to this Section.
     </p>
     <p>
      4. If we make an indemnification request to you under this Section, we may
      permit you to control the defense, disposition or settlement of the matter
      at your own expenses, provided that you shall not, without our prior
      written consent, enter into any settlement or agree to any disposition
      that requires any admission of liability by Mintish or imposes any
      conditions or obligations on Mintish other than the payment of monies that
      are readily measurable for purposes of determining your monetary
      indemnification or reimbursement obligations to us. If we, in our
      reasonable and good faith judgment conclude that you are not capable of
      defending your or our interests against any Claims, then we shall have the
      option to control the defense in any matter or litigation through counsel
      of our own choosing to defend against any such Claim for which you owe
      Mintish an indemnification, and the costs of such counsel, as well as any
      court costs, shall be at your expense.
     </p>
     <h5>
      10. Operation of Service; Availability of Products and Services;
      International Issues
     </h5>
     <p>
      1. The Service is operated in the United States, and is primarily intended
      for users located in the U.S. Mintish makes no representation that the
      Service is appropriate or available for use beyond the U.S. If you use the
      Service from other locations, you are doing so on your own initiative and
      are responsible for compliance with applicable local laws regarding your
      online conduct and acceptable content, if and to the extent local laws
      apply.
     </p>
     <p>
      2. We reserve the right to limit the availability of the Service and/or
      the provision of any content, program, product, service, or other feature
      described or available on the Service to any person, entity, geographic
      area, or jurisdiction, at any time and in our sole discretion, and to
      limit the quantities of any content, program, product, service, or other
      feature that we provide. You and we disclaim any application to these
      Terms of the Convention on Contracts for the International Sale of Goods.
     </p>
     <h5>11. Severability; Interpretation</h5>
     <p>
      1. If any provision of these Terms, or any Additional Terms, is for any
      reason deemed invalid, unlawful, void, or unenforceable by a court of
      competent jurisdiction, then that provision will be deemed severable from
      these Terms or the Additional Terms, and the invalidity of the provision
      will not affect the validity or enforceability of the remainder of these
      Terms or the Additional Terms (which will remain in full force and
      effect).{' '}
     </p>
     <p>
      2. To the extent permitted by applicable law, you agree to waive, and you
      hereby waive, any applicable statutory and common law that may permit a
      contract to be construed against its drafter. Wherever the word
      "including" is used in these Terms or any Additional Terms, the word will
      be deemed to mean "including, without limitation,". The summaries of
      provisions and section headings are provided for convenience only and
      shall not limit the full Terms.
     </p>
     <h5>12. Communications</h5>
     <p>
      1. As permitted by applicable law, when you communicate with us
      electronically, such as via email and text message, you consent to receive
      communications from us electronically. Please note that we are not
      obligated to respond to inquiries that we receive. You agree that all
      agreements, notices, disclosures, and other communications that we provide
      to you electronically satisfy any legal requirement that such
      communications be in writing.
     </p>
     <h5>
      13. Investigations; Cooperation with Law Enforcement; Termination;
      Survival
     </h5>
     <p>
      1. Mintish reserves the right, without any limitation, to: (i) investigate
      any suspected breaches of its Service security or its information
      technology or other systems or networks, (ii) investigate any suspected
      breaches of these Terms and any Additional Terms, (iii) investigate any
      information obtained by Mintish in connection with reviewing law
      enforcement databases or complying with criminal laws, (iv) involve and
      cooperate with law enforcement authorities in investigating any of the
      foregoing matters, (v) prosecute violators of these Terms and any
      Additional Terms, and (vi) discontinue the Service, in whole or in part,
      or, except as may be expressly set forth in any Additional Terms, suspend
      or terminate your access to it, in whole or in part, including any user
      accounts or registrations, at any time, without notice, for any reason and
      without any obligation to you or any third party.
     </p>
     <p>
      2. Any suspension or termination will not affect your obligations to
      Mintish under these Terms or any Additional Terms. Upon suspension or
      termination of your access to the Service, or upon notice from Mintish,
      all rights granted to you under these Terms or any Additional Terms will
      cease immediately, and you agree that you will immediately discontinue use
      of the Service. The provisions of these Terms and any Additional Terms
      (including the terms applicable to User-Generated Content), which by their
      nature should survive your suspension or termination will survive,
      including the rights and licenses you grant to Mintish in these Terms, as
      well as the indemnities, releases, disclaimers, and limitations on
      liability and the provisions regarding jurisdiction, choice of law, and no
      class action.
     </p>
     <h5>14. Assignment</h5>
     <p>
      1. Mintish may assign its rights and obligations under these Terms and any
      Additional Terms, in whole or in part, to any party at any time without
      any notice. These Terms and any Additional Terms may not be assigned by
      you, and you may not delegate your duties under them, without the prior
      written consent of an officer of Mintish.
     </p>
     <h5>15. No Waiver</h5>
     <p>
      1. Except as expressly set forth in these Terms or any Additional Terms:
      (i) no failure or delay by you or Mintish in exercising any of rights,
      powers, or remedies hereunder will operate as a waiver of that or any
      other right, power, or remedy, and (ii) no waiver or modification of any
      term of these Terms or any Additional Terms will be effective unless in
      writing and signed by the party against whom the waiver or modification is
      sought to be enforced. For avoidance of doubt, nothing herein shall be
      construed to restrict Mintish’s right to amend these Terms or any
      Additional Terms as otherwise permitted in those agreements.
     </p>
     <p>
      1. You are responsible for obtaining and maintaining all Devices and other
      equipment and software, and all internet service provider, mobile service,
      and other services needed for your access to and use of the Service and
      you will be responsible for all charges related to them.
     </p>
     <h5>17. Terms Applicable For Apple Device Users</h5>
     <h5>1. General</h5>
     <h5>16. Connectivity</h5>
     <p>
      If you are accessing or using the Service through a Device manufactured
      and/or sold by Apple, Inc. ("Apple", with such a device herein referenced
      as an "Apple Device"):
     </p>
     <p>
      1. To the extent that you are accessing the Service through an Apple
      Device, you acknowledge that these Terms are entered into between you and
      Mintish and, that Apple is not a party to these Terms other than as
      third-party beneficiary as contemplated below.
     </p>
     <p>
      {' '}
      2. The license granted to you in Section 1 of these Terms is subject to
      the permitted Usage Rules set forth in the Apple App Store Terms and
      Conditions (see:{' '}
      <a href="http://www.apple.com/legal/itunes/us/terms.html">
       http://www.apple.com/legal/itunes/us/terms.html
      </a>
      ) and any third party terms of agreement applicable to the Service.
     </p>
     <p>
      3. You acknowledge that Mintish, and not Apple, is responsible for
      providing the Service and Content thereof.
     </p>
     <p>
      {' '}
      4. You acknowledge that Apple has no obligation whatsoever to furnish any
      maintenance or any support services to you with respect to the Service.
     </p>
     <p>
      5. To the maximum extent permitted by applicable law, Apple will have no
      other warranty obligation whatsoever with respect to the Service.
     </p>
     <p>
      {' '}
      6. Notwithstanding anything to the contrary herein, and subject to the
      terms in these Terms, you acknowledge that, solely as between Apple and
      Mintish, Mintish and not Apple is responsible for addressing any claims
      you may have relating to the Service, or your possession and/or use
      thereof, including, but not limited, to: (i) product liability claims,
      (ii) any claim that the Service fails to conform to any applicable legal
      or regulatory requirement; and (iii) claims arising under consumer
      protection or similar legislation.
     </p>
     <p>
      7. Further, you agree that if the Service, or your possession and use of
      the Service, infringes on a third party's intellectual property rights,
      you will not hold Apple responsible for the investigation, defense,
      settlement and discharge of any such intellectual property infringement
      claims.
     </p>
     <p>
      8. You acknowledge and agree that Apple, and Apple's subsidiaries, are
      third- party beneficiaries of these Terms, and that, upon your acceptance
      of the terms and conditions of these Terms, Apple will have the right (and
      will be deemed to have accepted the right) to enforce these Terms against
      you as a third-party beneficiary thereof.
     </p>
     <p>
      9. When using the Service, you agree to comply with any and all
      third-party terms that are applicable to any platform, website, technology
      or service that interacts with the Service.
     </p>
     <p>
      10. You represent and warrant that: (i) you are not located in a country
      that is subject to a U.S. Government embargo or that has been designated
      by the U.S. Government as a "terrorist supporting" country; and (ii) you
      are not listed on any U.S. Government list of prohibited or restricted
      parties.
     </p>
     <p>
      <a href="https://musicafresh-nft.web.app/">
       https://musicafresh-nft.web.app/
      </a>{' '}
      Privacy Policy
     </p>
     <p>
      <a href="https://musicafresh-nft.web.app/">
       https://musicafresh-nft.web.app/
      </a>
      (the "Site") is owned and operated by Mintish LLC. Mintish LLC is the data
      controller and can be contacted at:
     </p>
     <p>
      contact@musicafresh.com (787) 598-4934 CIM Bldg 100 Carr 165, Torre 1
      Suite 708, Guaynabo, Puerto Rico, 00968
     </p>
     <h5>Purpose</h5>
     <p>
      The purpose of this privacy policy (this "Privacy Policy") is to inform
      users of our Site of the following: 1. The personal data we will collect;
      2. Use of collected data; 3. Who has access to the data collected; and 4.
      The rights of Site users.
      <p>
       This Privacy Policy applies in addition to the terms and conditions of
       our Site.
      </p>
     </p>
     <h5>GDPR</h5>
     <p>
      For users in the European Union, we adhere to the Regulation (EU) 2016/679
      of the European Parliament and of the Council of 27 April 2016, known as
      the General Data Protection Regulation (the "GDPR"). For users in the
      United Kingdom, we adhere to the GDPR as enshrined in the Data Protection
      Act 2018.
     </p>
     <h5>Consent</h5>
     <p>
      By using our Site users agree that they consent to: 1. The conditions set
      out in this Privacy Policy.
     </p>
     <h5>Legal Basis for Processing</h5>
     <p>
      We collect and process personal data about users in the EU only when we
      have a legal basis for doing so under Article 6 of the GDPR.
     </p>
     <p>
      We rely on the following legal bases to collect and process the personal
      data of users in the EU:
     </p>
     <p>
      {' '}
      1. Processing of user personal data is necessary for us to take, at the
      request of a user, steps before entering a contract or for the performance
      of a contract to which a user is a party. If a user does not provide the
      the personal data necessary to perform a contract the consequences are as
      follows: Authentication process for selling products will not be met and
      cannot get paid for product "beats" provided.; and
     </p>
     <p>
      2. Processing of user personal data is necessary to a task carried out in
      the public interest or in the exercise of our official authority.
     </p>
     <h5 className="text_h5">Personal Data We Collect</h5>
     <p>
      We only collect data that helps us achieve the purpose set out in this
      Privacy Policy. We will not collect any additional data beyond the data
      listed below without notifying you first. Data Collected Automatically
      When you visit and use our Site, we may automatically collect and store
      the following information: 1. Location; 2. Clicked links; and 3. Content
      viewed.
     </p>
     <p>
      Data Collected in a Non-Automatic Way We may also collect the following
      data when you perform certain functions on our Site: 1. First and last
      name; 2. Age; 3. Date of birth; 4. Email address; 5. Phone number; 6.
      Address; and 7. Auto fill data. This data may be collected using the
      following methods: 1. Creating Account.
     </p>
     <h5 className="text_h5">How We Use Personal Data</h5>
     <p>
      Data collected on our Site will only be used for the purposes specified in
      this Privacy Policy or indicated on the relevant pages of our Site. We
      will not use your data beyond what we disclose in this Privacy Policy. The
      data we collect automatically is used for the following purposes: 1.
      Statistic; 2. Future development; and 3. User ease. The data we collect
      when the user performs certain functions may be used for the following
      purposes: 1. Communication.
     </p>
     <h5 className="text_h5">Who We Share Personal Data With</h5>
     <p>
      Employees We may disclose user data to any member of our organization who
      reasonably needs access to user data to achieve the purposes set out in
      this Privacy Policy. Other Disclosures We will not sell or share your data
      with other third parties, except in the following cases: 1. If the law
      requires it; 2. If it is required for any legal proceeding; 3. To prove or
      protect our legal rights; and 4. To buyers or potential buyers of this
      company in the event that we seek to sell the company. If you follow
      hyperlinks from our Site to another Site, please note that we are not
      responsible for and have no control over their privacy policies and
      practices.
     </p>
     <h5 className="text_h5">How Long We Store Personal Data</h5>
     <p>
      User data will be stored until the purpose the data was collected for has
      been achieved. You will be notified if your data is kept for longer than
      this period.
     </p>
     <h5 className="text_h5">How We Protect Your Personal Data</h5>
     <p>
      In order to protect your security, we use the strongest available browser
      encryption and store all of our data on servers in secure facilities. All
      data is only accessible to our employees. Our employees are bound by
      strict confidentiality agreements and a breach of this agreement would
      result in the employee's termination. While we take all reasonable
      precautions to ensure that user data is secure and that users are
      protected, there always remains the risk of harm. The Internet as a whole
      can be insecure at times and therefore we are unable to guarantee the
      security of user data beyond what is reasonably practical.
     </p>
     <h5 className="text_h5">Your Rights as a User</h5>
     <p>
      Under the GDPR, you have the following rights: 1. Right to be informed; 2.
      Right of access; 3. Right to rectification; 4. Right to erasure; 5. Right
      to restrict processing; 6. Right to data portability; and 7. Right to
      object.
     </p>
     <h5 className="text_h5">Children</h5>
     <p>
      We do not knowingly collect or use personal data from children under 16
      years of age. If we learn that we have collected personal data from a
      child under 16 years of age, the personal data will be deleted as soon as
      possible. If a child under 16 years of age has provided us with personal
      data their parent or guardian may contact our data protection officer.
     </p>
     <h5 className="text_h5">
      How to Access, Modify, Delete, or Challenge the Data Collected
     </h5>
     <p>
      If you would like to know if we have collected your personal data, how we
      have used your personal data, if we have disclosed your personal data and
      to who we disclosed your personal data, if you would like your data to be
      deleted or modified in any way, or if you would like to exercise any of
      your other rights under the GDPR, please contact our data protection
      officer here: Fernando Llavona fernando@musicafresh.com (787) 598-4934 CIM
      Bldg 100 Carr 165, Torre 1 Suite 708 Guaynabo, Puerto Rico
     </p>
     <h5 className="text_h5">Do Not Track Notice</h5>
     <p>
      Do Not Track ("DNT") is a privacy preference that you can set in certain
      web browsers. We do not track the users of our Site over time and across
      third party websites and therefore do not respond to browser-initiated DNT
      signals.
     </p>
     <h5 className="text_h5">Additional Clauses</h5>
     <p>
      Content The Service contains a variety of: (i) materials and other items
      relating to Mintish LLC, and its products and services, and similar items
      from our licensors and other third parties, including all layout,
      information, articles, posts, text, data, files, images, scripts, designs,
      graphics, button icons, instructions, illustrations, photographs, audio
      clips, music, sounds, pictures, videos, advertising copy, URLs,
      technology, software, interactive features, the “look and feel” of the
      Service, and the compilation, assembly, and arrangement of the materials
      of the Service and any and all copyrightable material (including source
      and object code); (ii) trademarks, trade dress, logos, trade names,
      service marks, and/or trade identities of various parties, including those
      of Mintish LLC (collectively, “Trademarks”); and (iii) other forms of
      intellectual property (all of the foregoing but excluding Music Content
      (defined below), collectively, “Content”).
     </p>
     <p>
      Ownership The Service (including past, present, and future versions) and
      the Content are owned or controlled by Mintish LLC, our licensors and/or
      certain other third parties. All right, title, and interest in and to the
      Content available via the Service is the property of Mintish LLC or our
      licensors or certain other third parties, and is protected by U.S. and
      international copyright, trademark, trade dress, patent, and/or other
      intellectual property and unfair competition rights and laws to the
      fullest extent possible. Mintish LLC owns the copyright in the selection,
      compilation, assembly, arrangement, and enhancement of the Content on the
      Service.
     </p>
     <p>
      Ownership The Service (including past, present, and future versions) and
      the Content are owned or controlled by Mintish LLC, our licensors and/or
      certain other third parties. All right, title, and interest in and to the
      Content available via the Service is the property of Mintish LLC or our
      licensors or certain other third parties, and is protected by U.S. and
      international copyright, trademark, trade dress, patent, and/or other
      intellectual property and unfair competition rights and laws to the
      fullest extent possible. Mintish LLC owns the copyright in the selection,
      compilation, assembly, arrangement, and enhancement of the Content on the
      Service.
     </p>
     <p>
      Limited License to Content Subject to your strict compliance with these
      Terms (including payment of a subscription fee) (defined below) and any
      Additional Terms, excluding Music Content (defined below), Mintish LLC
      grants you a limited, non-exclusive, revocable, non-assignable, personal,
      and non- transferable license to: (i) download (temporary storage only),
      display, view, use, play, and/or print one copy of the Content (excluding
      source and object code in raw form or otherwise, other than as made
      available to access and use to enable display and functionality) on a
      personal computer, mobile phone or other wireless device, or other
      Internet enabled device (each, a “Device”) for your personal,
      non-commercial use only. The foregoing limited license: (i) does not give
      you any ownership of, or any other intellectual property interest in, any
      Content, and (ii) may be immediately suspended or terminated for any
      reason, in Mintish LLC’ sole discretion, and without advance notice or
      liability. In some instances, we may permit you to have greater access to
      and use of Content, subject to certain Additional Terms.
     </p>
     <p>
      Rights to Music Content The Service contains a cloud-based technology
      platform (the “Platform”) that enables users (“Licensors”) of the Service
      to license to other users of the Service (“Licensees”), for agreed- upon
      compensation, certain sound recordings (each individually, a “Master” and
      collectively, the “Masters”) and underlying musical compositions embodied
      therein (each individually, a “Composition” and collectively, the
      “Compositions”) for the purpose of possible embodiment (“Sample”,
      “Sampling”) in other derivative master recordings (each, a “Derivative
      Master”) and underlying derivative musical compositions (each, a
      “Derivative Composition”). Masters, Compositions, Derivative Masters, and
      Derivative Compositions shall be collectively referred to hereunder as
      “Music Content”. Notwithstanding anything to the contrary contained
      herein, where applicable, one (1) or more separate agreement(s) between
      Licensors, on the one hand, and Licensees, on the other hand (each, an
      “Outside Agreement”), shall control the licensing of any Music Content
      licensed by Licensors to Licensees on the Service. For the avoidance of
      doubt, Mintish LLC does not grant any licenses or other rights to any
      users of the Service with respect to Music Content other than as
      specifically set forth hereunder, and any and all further rights shall be
      governed by the terms of Outside Agreement(s), if any.
     </p>
     <p>
      Rights Granted to Mintish LLC by Licensors. For good and valuable
      consideration, Licensors grant to Mintish LLC a license, for so long as
      Licensors choose to host the applicable Music Content (subject to the
      terms of any Outside Agreement[s]), to: Host, reproduce, distribute, make
      available for download and/or perform (publicly or otherwise) all Masters
      and Compositions on the Platform, including full-length versions of sound
      recordings (“Clips”) that can be used for promotional purposes as
      authorized herein and, if necessary, reproducing Your Content in new
      Physical Product; Publicly perform, publicly display, communicate to the
      public, and otherwise make available Your Content, and Clips, by means of
      digital audio transmissions (on an interactive or non interactive basis)
      through the Website, a Licensee website, or via a Mintish LLC Widget you
      or any person authorized by you may place on any website, to identify the
      availability of Your Content for license, sale or distribution and to
      promote Your Content, on a through-to-the- listener basis, without the
      payment of any fees or royalties to (i) the songwriters, composers, or
      music publishers owning any rights in and to Your Content; (ii) any
      performing artist(s) (including non-featured vocalists and musicians) on
      Your Content; (iii) any other person involved in the creation of or owning
      any portion of Your Content, including, but not limited to a record label,
      and (iv) any agents for any of the foregoing, including, without
      limitation, performing rights organizations (“PROs”) and unions or guilds,
      whether U.S.- based (such as ASCAP, BMI, SESAC, SoundExchange, AFTRA and
      AFM) or foreign (e.g., PRS for Music, PPL, CMRRA, CSI, GEMA, etc.);
      Distribute Your Content in accordance with any applicable Addendum; Place
      or embed Your Content in magazines, web sites,Mintish LLC’ advertisements,
      and any and all other media, whether now known or hereafter developed, but
      specifically excluding television and movies, to promote the Mintish LLC’
      Service; Use and distribute Copyright Management Information as embodied
      in a Digital Master of Your Content; Use Your Content and metadata as may
      be reasonably necessary or desirable for us to exercise our rights under
      this Agreement;
     </p>
     <p>
      Reproduce, distribute, and publicly perform and communicate to the public
      Your Content (including Clips) as part of a downloaded program that may
      include multiple sound recordings and other content, commonly known as a
      “podcast;” and Authorize our Licensees to perform any one or more of the
      activities specified above or in an applicable Addendum;
     </p>
     <p>
      Grant to Licensees, subject to the terms of any Outside Agreement, the
      exclusive or non- exclusive right, privilege and license to download,
      copy, reproduce, Sample and otherwise edit, alter, couple, synchronize
      and/or otherwise embody the Masters (together with the applicable
      underlying including the Composition(s) embodied therein) into Derivative
      Masters and Derivative Compositions, subject to and in accordance with
      these Terms; Broadcast, stream and/or perform (publicly or otherwise) the
      Masters and/or Compositions in any and all media in connection with the
      advertisement and promotion of the Masters, Compositions and/or the
      Platform;
     </p>
     <p>
      Use, publish and display Licensor’s name, approved image, approved
      likeness and approved biographical materials (provided that any materials
      or information provided by Licensors are deemed approved) in any and all
      media in connection with the advertisement and promotion of the Masters,
      Compositions and/or the Platform; and
     </p>
     <p>
      License any of the rights granted to Mintish LLC hereunder to third
      parties as reasonably necessary to perform its obligations hereunder or to
      otherwise effectuate the purpose of these Terms.
     </p>
     <p>
      The rights granted pursuant to this Section 1 with respect to each Master
      (and the applicable corresponding underlying Composition) shall be
      non-exclusive to Mintish LLC, subject to the terms of any Outside
      Agreement(s). For the avoidance of doubt, it is hereby acknowledged and
      agreed that the terms of any Outside Agreement(s) may continue for a
      duration longer than the rights granted to Mintish LLC hereunder.
     </p>
     <p>
      Licensor hereby represents and warrants: (1) there are and there shall be
      no liens, encumbrances or other charges against the Master(s) and/or the
      Composition(s), including, without limitation, any Samples incorporated
      therein by Licensor and/or any third party engaged by Licensor; (2) no
      selections, materials, ideas, or other properties furnished by Licensor
      and embodied or contained in the Master(s) or the Composition(s), nor the
      exercise by Mintish LLC of any of its rights hereunder, will violate or
      infringe upon any law or statutory right of any third party; (3) Licensor
      has the full right, power and authority to agree to these Terms, grant the
      rights conveyed to Mintish LLC hereunder, and perform Licensor’s material
      terms and obligations hereunder; and (4) Licensor has not entered into and
      shall not enter into to any agreement with any third- party record
      company, distributor, music publisher, or any other person or entity that
      would
     </p>
     <p>
      conflict, inhibit, restrict or impair the rights granted to Mintish LLC
      hereunder or the performance of Licensor’s obligations under these Terms.
      Licensee hereby represents and warrants that the use or other exploitation
      of Your Content, including, but not limited to, any musical works embodied
      in your sound recordings, by us and our Licensees as contemplated by this
      Agreement will not infringe or violate the rights of any third party,
      including, without limitation, any privacy rights, publicity rights,
      copyrights, contract rights, or any other intellectual property or
      proprietary rights. Licensee hereby represents and warrants that no
      selections, materials, ideas, or other properties furnished by Licensee
      and embodied or contained in any Derivative Master(s) or Derivative
      Composition(s), nor the exercise by Mintish LLC of any of its rights
      hereunder, will violate or infringe upon any law or statutory right of any
      third party, including without limitation copyright, trademark right or
      right of publicity or privacy. Licensee hereby represents and warrants to
      the extent you are the songwriter of any or all of the musical works
      embodied in Your Content, whether in whole or in part (e.g., as a
      co-writer), you have the full right, power, and authority to grant the
      rights set forth in this Agreement notwithstanding the provisions of any
      agreement you may have entered into with any PRO, whether based in the
      United States or elsewhere, or any music publisher, and that you are
      solely responsible for taking all steps necessary to inform such PRO or
      music publisher of your grant of a royalty free license to us and our
      Licensees for the public performance and communication to the public of
      Your Content, including as Clips, and that no fees or payments of any kind
      whatsoever shall be due to any PRO or music publisher for the use of the
      musical works in Your Content when publicly performed, communicated or
      otherwise transmitted by Mintish LLC or its Licensees.
     </p>
     <p>
      Licensee hereby represents and warrants you have not assigned any of the
      rights in an to the sound recordings embodied in Your Content to any third
      party (e.g., a record label) that obtained exclusive rights in and to such
      sound recordings.
     </p>
     <p>
      Licensee hereby represents and warrants that if it is ever determined that
      you have acted contrary to any other agreements you have in place, you
      shall cause any and all of your respective PRO (Performing Rights
      Organizations), CMOs (Collective Management Organization), publishing
      administrators, agents, Entities, their parent company and subsidiaries,
      or their respective employees, officers, directors, members, managers,
      shareholders, agents, vendors, licensors, licensees, contractors,
      customers, successors, and assigns, as applicable, to license to Mintish
      LLC and/or Mintish LLC’ publishing administrators, agents, Mintish LLC’
      parent company and subsidiaries, agents, vendors, licensors, licensees,
      contractors, customers, successors, and assigns the rights in any of the
      Composition pursuant to the terms and conditions of this agreement.
      Licensee shall issue or shall cause the necessary above parties in this
      paragraph) to issue a non-exclusive, worldwide, and perpetual royalty-free
      licenses for use of the Composition in connection with any Master(s),
      Composition(s), Derivative Master(s) and/or Derivative Composition(s)
      hereunder, including, without limitation, any unauthorized commercial
      exploitation thereof.
     </p>
     <p>
      Licensor and Licensee hereby forever release and covenant not to sue
      Mintish LLC and/or any of the Mintish LLC Parties from any and all claims
      arising out of or in connection with any act or omission of any third
      party in connection with any Master(s), Composition(s), Derivative
      Master(s) and/or Derivative Composition(s) hereunder, including, without
      limitation, any unauthorized commercial exploitation thereof.
     </p>
     <h5>
      YOU UNDERSTAND AND AGREE THAT Mintish LLC IS NOT A PARTY TO ANY AGREEMENTS
      ENTERED INTO BETWEEN LICENSORS AND LICENSEE. YOU ACKNOWLEDGE THAT UNLESS
      EXPRESSLY SET FORTH ON THE SERVICE AS PART OF A SUBSCRIPTION OFFERING,
      Mintish LLC DOES NOT LICENSE MUSIC CONTENT DIRECTLY OR FUNCTION AS MUSIC
      LICENSING COMPANY. THE SERVICE MAY BE USED BY LICENSORS AND LICENSEES TO
      LICENSE OR OBTAIN A LICENSE TO MUSIC CONTENT FOR DEVELOPMENT OF DERIVATIVE
      COMPOSITIONS, BUT YOU AGREE THAT Mintish LLC HAS NO RESPONSIBILITY OR
      LIABILITY TO YOU RELATED TO ANY MUSIC CONTENT LICENSED THROUGH THE USE OF
      THE SERVICE, OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS. Mintish LLC
      HAS NO CONTROL OVER THE CONDUCT OF LICENSORS, LICENSEES, AND OTHER USERS
      OF THE SITE AND SERVICE OR ANY MUSIC CONTENT, AND DISCLAIMS ALL LIABILITY
      IN THIS REGARD TO THE MAXIMUM EXTENT PERMITTED BY LAW. Mintish LLC DOES
      NOT GUARANTEE THE MUSIC CONTENT MADE AVAILABLE ON THE SERVICE BY LICENSORS
      IS CLEAR FROM A RIGHTS USE PERSPECTIVE AND SUCH MUSIC CONTENT MAY INFRINGE
      THIRD PARTY RIGHTS. IT IS SOLELY A LICENSEE’S RESPONSIBILITY TO DETERMINE
      IF ANY MUSIC CONTENT WILL MEET THE LICENSEE’S NEEDS AND EXPECTATIONS.
      Mintish LLC WILL NOT PARTICIPATE IN DISPUTES BETWEEN LICENSORS AND
      LICENSEES. Mintish LLC SHALL NOT HAVE ANY LIABILITY ARISING FROM OR IN ANY
      WAY RELATED TO ANY TRANSACTIONS OR RELATIONSHIP BETWEEN LICENSORS AND
      LICENSEES.
     </h5>
     <h5 className="text_h5">Modifications</h5>
     <p>
      This Privacy Policy may be amended from time to time in order to maintain
      compliance with the law and to reflect any changes to our data collection
      process. When we amend this Privacy Policy we will update the "Effective
      Date" at the top of this Privacy Policy. We recommend that our users
      periodically review our Privacy Policy to ensure that they are notified of
      any updates. If necessary, we may notify users by email of changes to this
      Privacy Policy.
     </p>
     <h5 className="text_h5">Complaints</h5>
     <p>
      If you have any complaints about how we process your personal data, please
      contact us through the contact methods listed in the Contact Information
      section so that we can, where possible, resolve the issue. If you feel we
      have not addressed your concern in a satisfactory manner you may contact a
      supervisory authority. You also have the right to directly make a
      complaint to a supervisory authority. You can lodge a complaint with a
      supervisory authority by contacting the Fernando Llavona.
     </p>
     <h5 className="text_h5">Contact Information</h5>
     <p>
      If you have any questions, concerns or complaints, you can contact our
      data protection officer, Fernando Llavona, at:
     </p>
     <p>Hello@mintish.com</p>
     <p>(787) 598-4934</p>
     <p>Av. El Caño, Guaynabo, 00968, Puerto Rico</p>
     <label className="termsLabel">
      <input
       type="checkbox"
       id="Numbered"
       checked={props.isChecked}
       onChange={props.handleOnChange}
      />
      <span className="termsLabelText">
       "I Agree" to the Terms & Conditions
      </span>
      <span className="checkmark"></span>
     </label>
     <div className="btnOuter">
      <a
       className={
        props.isChecked
         ? 'btn more-btn agreeBtn'
         : 'btn more-btn agreeBtn disabled'
       }
       onClick={props.submit}
      >
       I Agree
      </a>
     </div>
    </div>{' '}
   </div>
  </div>
 );
}
