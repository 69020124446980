import Strings from '../libs/Strings';

export default function CustomAlert(props: any) {
 return (
  <div className="modalOverlay">
   <div className="requestModalpopup">
    <div className="modalHeader">
     <span
      className="crossBtnOuter closeIconOuter cross-Icon"
      onClick={() => props.onCrossButtonClick()}
     >
      <img src="img/art-work/crossBtn.png" alt="" />
     </span>
    </div>
    <img src="img/art-work/successbgimg.svg" width="200px" alt="" />
    <h4>{props.message} </h4>

    <a onClick={props.navigate} className="btn more-btn exploreBtn">
     {Strings.LetGo}
    </a>
   </div>
  </div>
 );
}
