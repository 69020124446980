import {
 postRequest,
 getRequest,
 LoggedInPostRequest,
 LoggedInPatchRequest,
 LoggedInPost,
} from '../libs/Constants';
import { BASE_URL } from '../libs/Environment';
import UserData from './UserData';

export const getAllItemsFromCache = async (cacheName: any) => {
 const body = {
  Type: cacheName,
 };
 var additionalUrl = 'items/type',
  url = BASE_URL;
 if ('caches' in window) {
  var cacheskeysName = await caches.keys(),
   returnData = [],
   isExists = false;
  cacheskeysName.forEach(async (cacheNameData, i) => {
   if (cacheNameData === cacheName) {
    isExists = true;
   }
  });
  if (isExists) {
   const cacheStorage = await caches.open(cacheName);
   const cachedResponse = await cacheStorage.match(url);
   if (cachedResponse !== undefined) {
    returnData = await cachedResponse.json();
    return returnData;
   }
  } else {
   returnData = await postRequest(body, additionalUrl);
   const ApiRespone = new Response(JSON.stringify(returnData));
   caches.open(cacheName).then((cache) => {
    cache.put(url, ApiRespone);
   });

   return returnData;
  }
 } else {
  const returnData = await postRequest(body, additionalUrl);
  return returnData;
 }
};
export const getAllItems = async (cacheName: any) => {
 const body = {
  Type: cacheName,
 };
 var additionalUrl = 'items/type';
 const returnData = await postRequest(body, additionalUrl);
 caches.keys().then((names) => {
  console.log('name is', names);
  names.forEach((name) => {
   caches.delete(name);
  });
 });
 return returnData;
};
export const getItemDetail = async (id: any) => {
 const body = {
  Id: id,
 };
 const additionalUrl = 'items/Id';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const getNewListedItems = async () => {
 const additionalUrl = 'items/toplisted';
 const response = await getRequest(additionalUrl);
 return response;
};

export const loginUser = async (publicAddress: any, id: any) => {
 const body = { M_Id: id, PublicAddress: publicAddress };
 const additionalUrl = 'login';
 const response = await postRequest(body, additionalUrl);
 return response;
};
export const registerUser = async (publicAddress: any, id: any) => {
 const body = { M_Id: id, PublicAddress: publicAddress };
 const additionalUrl = 'register';
 const response = await postRequest(body, additionalUrl);
 return response;
};
export const getItemsByType = async (
 type: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  Type: type,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'items';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const UpdateUser = async (userProfileDta: any, token: any) => {
 const body = userProfileDta;
 const additionalUrl = 'api/user/update';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUser = async (id: any) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/user/id';
 const response = await LoggedInPostRequest(body, additionalUrl);
 return response;
};

export const getUserByToken = async (id: any, token: any) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/user/id';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserOnWalletConnect = async (
 id: any,
 token: any,
 type: any
) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/user/id';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserType = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'api/user/id';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const UpdateUserProfileImage = async (
 userProfileDta: any,
 token: any
) => {
 const body = userProfileDta;
 const additionalUrl = 'api/user/profileimage/upload';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserFavouritesList = async (
 id: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'user/favourites';
 const token = 'Logicease123';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserNFTList = async (
 id: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'user/nft/all';
 const token = 'Logicease123';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserSoldNFTList = async (
 id: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'user/nft/sold';
 const token = 'Logicease123';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserAllCollection = async (
 id: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'collections/all';
 const token = 'Logicease123';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserPurchasedNFTList = async (
 id: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'user/nft/purchased';
 const token = 'Logicease123';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const confirmNFT = async (itemID: any, tokenId: any, token: any) => {
 const body = {
  ItemId: itemID,
  TokenId: tokenId,
  Status: 'pending',
 };
 const additionalUrl = 'api/item/approved';
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};
export const listNFT = async (
 itemID: any,
 tokenId: any,
 saleId: any,
 token: any,
 gasFee: any
) => {
 console.log("List NFt's are", itemID, tokenId, saleId, token);
 const body = {
  ItemId: itemID,
  TokenId: tokenId,
  SaleId: saleId,
  Status: 'approved',
  SellGasfee: gasFee,
 };
 const additionalUrl = 'api/item/approved';
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};
export const soldNFT = async (
 itemID: any,
 tokenId: any,
 saleId: any,
 token: any
) => {
 const body = {
  ItemId: itemID,
  TokenId: tokenId,
  SaleId: saleId,
  Status: 'sold',
 };
 const additionalUrl = 'api/item/approved';
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};

export const addToFavouritesByToken = async (itemID: any, token: any) => {
 const body = {
  ItemId: itemID,
  UserId: localStorage.getItem('userId'),
 };
 const additionalUrl = 'api/user/favourites/set';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const checkUser = async (id: any) => {
 const body = { UserId: id };
 const additionalUrl = 'api/user/id';
 const response = await LoggedInPostRequest(body, additionalUrl);
 return response;
};

export const userRequest = async (userRequest: any) => {
 const body = userRequest;
 const additionalUrl = 'api/userrequest';
 const response = await LoggedInPostRequest(body, additionalUrl);
 return response;
};
export const buyNFT = async (buyDetails: any, token: any) => {
 const body = buyDetails;
 const additionalUrl = 'api/item/purchase';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const createNFT = async (nftData: any) => {
 const body = nftData;
 const additionalUrl = 'api/item/upload';
 const response = await LoggedInPostRequest(body, additionalUrl);
 return response;
};
export const createNFTByToken = async (nftData: any, token: any) => {
 const body = nftData;
 const additionalUrl = 'api/item/upload';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserStatusDetail = async (id: any) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/userrequest/details/id';
 const response = await LoggedInPostRequest(body, additionalUrl);
 return response;
};

export const getUserStatusDetailByToken = async (id: any, token: any) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/userrequest/details/id';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserNFTListByToken = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'api/user/nft/all';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserSoldNFTListByToken = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'api/user/nft/sold';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserFavouritesListByToken = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'api/user/favourites';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};

export const getUserPurchasedNFTListByToken = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  UserId: id,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'api/user/nft/purchased';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getFavouritesByToken = async (userId: any, token: any) => {
 const body = {
  UserId: userId,
  StartIndex: '1',
  EndIndex: '2',
 };
 const additionalUrl = 'api/user/favourites/all';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getNFTByName = async (
 itemNmae: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  ItemName: itemNmae,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'item/search/name';
 const response = await postRequest(body, additionalUrl);
 return response;
};
export const getNFTByPrice = async (
 startIndex: any,
 lastIndex: any,
 type: any,
 sortBy: any
) => {
 const body = {
  StartIndex: startIndex,
  EndIndex: lastIndex,
  Type: type,
  SortBy: sortBy,
 };
 const additionalUrl = 'item/search/category';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const CreateUserCollectionByToken = async (Body: any, token: any) => {
 console.log('query params are ', Body, token);
 const additionalUrl = 'api/collections/create';
 const response = await LoggedInPost(Body, additionalUrl, token);
 return response;
};
export const getUserAllCollectionByToken = async (
 id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  StartIndex: startIndex,
  EndIndex: lastIndex,
  UserId: id,
 };
 const additionalUrl = 'api/collections/all';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserCollectionNameByToken = async (id: any, token: any) => {
 const body = {
  UserId: id,
 };
 const additionalUrl = 'api/collections/name';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getUserNFTByCollection = async (
 Collection_id: any,
 token: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  StartIndex: startIndex,
  EndIndex: lastIndex,
  CollectionId: Collection_id,
 };
 const additionalUrl = 'api/collections/id';
 const response = await LoggedInPost(body, additionalUrl, token);
 return response;
};
export const getHighestCollection = async () => {
 const additionalUrl = 'collections/highest';
 const response = await getRequest(additionalUrl);
 return response;
};

export const getPoPularNftByCollectionId = async (
 collectionId: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  StartIndex: startIndex,
  EndIndex: lastIndex,
  CollectionId: collectionId,
 };
 const additionalUrl = 'collections/listed/id';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const getTopSellers = async () => {
 const additionalUrl = 'items/topsellers';
 const response = await getRequest(additionalUrl);
 return response;
};
export const getTopSellersOnIndexBase = async (
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'items/topsellers/index';
 const response = await postRequest(body, additionalUrl);
 return response;
};
export const sellNFT = async (collectionId: any, token: any) => {
 const body = {
  CollectionId: collectionId,
 };
 const additionalUrl = 'api/collections/popular';
 console.log('requestr params are', body, additionalUrl, token);
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};
export const getUserById = async (userId: any) => {
 const body = {
  Id: userId,
 };
 const additionalUrl = 'admin/user/id';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const getDataByType = async (
 type: any,
 startIndex: any,
 lastIndex: any
) => {
 const body = {
  Type: type,
  StartIndex: startIndex,
  EndIndex: lastIndex,
 };
 const additionalUrl = 'items';
 if (type == 'All') {
  if (UserData.getAllNft().length > 0) {
   let response = UserData.getAllNft();
   return response;
  } else {
   const response = await postRequest(body, additionalUrl);
   UserData.updateAllNft(response);
   return response;
  }
 } else if (type == 'Music') {
  if (UserData.getMusicNft().length > 0) {
   let response = UserData.getMusicNft();
   return response;
  } else {
   const response = await postRequest(body, additionalUrl);
   UserData.updateMusicNft(response);
   return response;
  }
 } else if (type == 'Image') {
  if (UserData.getImageNft().length > 0) {
   let response = UserData.getImageNft();
   return response;
  } else {
   const response = await postRequest(body, additionalUrl);
   UserData.updateImageNft(response);
   return response;
  }
 } else if (type == 'Video') {
  if (UserData.getVideoNft().length > 0) {
   let response = UserData.getVideoNft();
   return response;
  } else {
   const response = await postRequest(body, additionalUrl);
   UserData.updateVideoNft(response);
   return response;
  }
 } else if (type == 'Tours') {
  if (UserData.getToursNft().length > 0) {
   let response = UserData.getToursNft();
   return response;
  } else {
   const response = await postRequest(body, additionalUrl);
   UserData.updateToursNft(response);
   return response;
  }
 }
};

export const sendMail = async (emailId: any) => {
 const body = {
  email: emailId,
 };
 const additionalUrl = 'user/newsletter';
 const response = await postRequest(body, additionalUrl);
 return response;
};

export const onPlaceBid = async (
 bidPrice: any,
 id: any,
 walletaddress: any
) => {
 const body = {
  OfferFlag: '1',
  BidAmount: bidPrice,
  Status: 'Pending for Admin',
  ItemId: id,
  BidWalletAddress: walletaddress,
 };
 const additionalUrl = 'api/item/placebid';
 const response = await LoggedInPatchRequest(
  body,
  additionalUrl,
  localStorage.getItem('userToken')
 );
 return response;
};
export const CheckItemStatus = async (
 itemStatus: any,
 itmeId: any,
 token: any
) => {
 const body = {
  Status: itemStatus,
  Id: itmeId,
 };
 const additionalUrl = 'api/buy';
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};
export const updateItemStatus = async (
 itemStatus: any,
 itmeId: any,
 token: any
) => {
 const body = {
  Status: itemStatus,
  Id: itmeId,
 };
 const additionalUrl = 'api/buy/update';
 const response = await LoggedInPatchRequest(body, additionalUrl, token);
 return response;
};
export const SendMessageToAdmin = async (
 name: any,
 email: any,
 text: any,
 message: any
) => {
 const body = {
  From: email,
  To: 'devmusica8@gmail.com',
  Subject: text,
  Text: message,
  Name: name,
 };
 const additionalUrl = '/email';
 const response = await postRequest(body, additionalUrl);
 return response;
};
