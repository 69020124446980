import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Strings from '../libs/Strings';
import ReactAudioPlayer from 'react-audio-player';

function WalletItem(props: any) {
 return (
  <div className="modalOverlay">
   <div className="itemPreviewModal item_view">
    <span
     className="crossBtnOuter closeIconOuter cross-Icon"
     onClick={() => props.onCrossClick()}
    >
     <img src="img/art-work/crossBtn.png" alt="loading..." />
    </span>
    <div className="row align-items-center aliging_center">
     <div className="container">
      <div className="detailed-img2">
       <div className="image-view">
        {props.Category == 'Music' ? (
         <div className="detailImg image_Details">
          <ReactAudioPlayer
           className="audio_player"
           src={props.Source}
           controls
           controlsList="nodownload"
          />
         </div>
        ) : props.Category == 'Video' ? (
         <video
          className="itemPreviewModalImage"
          width="100%"
          height="100%"
          controls
          controlsList="nodownload"
         >
          <source src={props.Source} type="video/mp4" />
         </video>
        ) : (
         <img
          src={props.Source}
          alt="loading..."
          className="itemPreviewModalImage"
         />
        )}
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}

export default WalletItem;
