import '../App.css';
import { useState } from 'react';
import { sendMail } from '../service/ApiService';
import PopUp from './PopUp';
import Strings from '../libs/Strings';
import {
 API_SUCCESS_MSG,
 DEFAULT_INDEX,
 EMAIL_REGEX,
 VALID_EMAIL_ERROR_MSG,
} from '../libs/Constants';

function Footer() {
 const [showPopUp, setShowPopUp] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [email, setEmail] = useState('');
 const [isSendBtnDisable, setIsSendBtnDisable] = useState(true);
 const onEmailSend = async () => {
  if (!new RegExp(EMAIL_REGEX).test(email)) {
   setValidationMsg(VALID_EMAIL_ERROR_MSG);
   setShowPopUp(true);
  } else {
   const response = await sendMail(email);
   if (response[DEFAULT_INDEX].msg == API_SUCCESS_MSG) {
    setValidationMsg(Strings.ThnaksWillKeepUpdated);
    setShowPopUp(true);
    setEmail('');
    setIsSendBtnDisable(true);
   }
  }
 };

 const onChangeEmailText = (value: any) => {
  setEmail(value);
  if (value.length > 0) {
   setIsSendBtnDisable(false);
  } else {
   setIsSendBtnDisable(true);
  }
 };
 return (
  <footer className="main-footer text-center">
   <div className="widgets-section padding-top-small padding-bottom-small">
    <div className="container">
     <div className="row clearfix">
      <div className="footer-column col-md-4 col-sm-6 col-xs-12">
       <div className="footer-widget about-widget">
        <h3 className="has-line-center">{Strings.AboutUs}</h3>
        <div className="widget-content">
         <div className="text">{Strings.MintishIsTheWorld} </div>
         <ul className="social-links">
          <li>
           <a href="#" className="disabled">
            <span className="fa fa-facebook-f"></span>
           </a>
          </li>
          <li>
           <a href="#" className="disabled">
            <span className="fa fa-twitter"></span>
           </a>
          </li>
          <li>
           <a href="#" className="disabled">
            <span className="fa fa-google-plus"></span>
           </a>
          </li>
          <li>
           <a href="#" className="disabled">
            <span className="fa fa-linkedin"></span>
           </a>
          </li>
          <li>
           <a href="#" className="disabled">
            <span className="fa fa-instagram"></span>
           </a>
          </li>
         </ul>
        </div>
       </div>
      </div>

      <div className="footer-column col-md-4 col-sm-6 col-xs-12">
       <div className="footer-widget contact-widget">
        <h3 className="has-line-center">{Strings.ContactUs}</h3>
        <div className="widget-content">
         <ul className="contact-info">
          <li>
           <div className="icon">
            <span className="flaticon-support"></span>
           </div>
          </li>
          <li>{Strings.Address}</li>
          <li>{Strings.ContactNo}</li>
          <li>{Strings.HelloMintishCom}</li>
         </ul>
        </div>
       </div>
      </div>

      <div className="footer-column col-md-4 col-sm-12 col-xs-12">
       <div className="footer-widget newsletter-widget">
        <h3 className="has-line-center">{Strings.Newsletter}</h3>
        <div className="widget-content">
         <div className="text">{Strings.StayUpdated}</div>
         <div className="newsletter-form">
          <form method="post">
           <div className="form-group">
            <input
             id="email"
             type="email"
             name="field-name"
             placeholder="Your Email"
             value={email}
             onChange={(e: any) => onChangeEmailText(e.target.value)}
            />
            <div
             className={isSendBtnDisable ? 'send-btn disabled' : 'send-btn'}
             onClick={() => onEmailSend()}
            >
             <span className="fa fa-paper-plane-o"></span>
            </div>
           </div>
          </form>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div className="footer-bottom">
    <div className="auto-container">
     <div className="copyright-text">{Strings.CopyrightText}</div>
    </div>
   </div>
   {showPopUp ? (
    <PopUp
     message={validationMsg}
     navigate={() => {
      setShowPopUp(false);
     }}
    />
   ) : null}
  </footer>
 );
}

export default Footer;
