import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ErrorPage from '../components/Error';
import moment from 'moment';
import ListItem from '../components/ListItem';
import Collection from '../components/Collection';
import '../App.css';
import {
 ITEM_DETAIL_PAGE,
 START_INDEX,
 COLLECTION_LAST_INDEX,
 CREATOR_COLLECTION,
 PROFILE_PAGE,
 MY_COLLECTION_SCREEN,
 DEFAULT_INDEX,
} from '../libs/Constants';
import {
 getUserNFTList,
 getUserAllCollection,
 getUserFavouritesList,
 getUserPurchasedNFTList,
 getUserSoldNFTList,
 getUserById,
} from '../service/ApiService';
import UserData from '../service/UserData';
import UserProfile from '../components/UserProfile';
import PopUp from '../components/PopUp';
import Strings from '../libs/Strings';

function CreatorProfileScreen() {
 const navigate = useNavigate();
 let params: any = useLocation();
 const [error, setError] = useState(false);
 const [lastIndex, setLastIndex] = useState(COLLECTION_LAST_INDEX);
 const [collectionType, setCollectionType] = useState(Strings.MyNFT);
 const [loading, setLoading] = useState(false);
 const [userData, setUserData] = useState<any>([]);
 const [isCopy, setIsCopy] = useState(false);
 const [creatorData, setCreatorData] = useState<any>([]);
 const [userCollection, setUserCollection] = useState<any>([]);
 const [createdItems, setCreatedItems] = useState<any>(0);
 const [purchasedItems, setPurchasedItems] = useState<any>(0);
 const [loadMoreData, setLoadMoreData] = useState(false);
 const [hideLoad, setHideLoad] = useState(false);
 const [completeUserCollection, setCompleteUserCollection] = useState<any>([]);
 const userProfileDetails: any = localStorage.getItem('userDetails');

 const onButtonPress = (type: any) => {
  let isExists: boolean = false;
  let itemIndex: any = null;
  completeUserCollection.forEach(async (item: any, i: any) => {
   if (item.type === type) {
    isExists = true;
    itemIndex = i;
   }
  });
  if (isExists) {
   setCollectionType(type);
   if (completeUserCollection[itemIndex].data.length == 0) {
    setHideLoad(true);
   } else {
    setHideLoad(false);
   }
   setUserCollection(completeUserCollection[itemIndex].data);
  } else {
   let id = params.state.CreatorId;
   getUserCollectionByToken(id, type);
  }
 };

 const getMoreItems = async () => {
  setLoadMoreData(true);
  let id = params.state.CreatorId;
  if (collectionType === "My NFT's") {
   const response = await getUserNFTList(
    id,
    lastIndex + START_INDEX,
    lastIndex + COLLECTION_LAST_INDEX
   );
   if (
    response &&
    response[DEFAULT_INDEX] &&
    response[DEFAULT_INDEX].length > 0
   ) {
    let arr = response[DEFAULT_INDEX];
    setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
    setUserCollection([...userCollection, ...arr]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  }
  if (collectionType === 'Collection') {
   const response = await getUserAllCollection(
    id,
    lastIndex + START_INDEX,
    lastIndex + COLLECTION_LAST_INDEX
   );
   if (response && response[0] && response[0].length > 0) {
    let arr = response[0];
    setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
    setUserCollection([...userCollection, ...arr]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  } else if (collectionType === 'Favourites') {
   const response = await getUserFavouritesList(
    id,
    lastIndex + START_INDEX,
    lastIndex + COLLECTION_LAST_INDEX
   );
   if (response && response[0] && response[0].length > 0) {
    let arr = response[0];
    setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
    setUserCollection([...userCollection, ...arr]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  } else if (collectionType === 'Purchased') {
   const response = await getUserPurchasedNFTList(
    id,
    lastIndex + START_INDEX,
    lastIndex + COLLECTION_LAST_INDEX
   );
   if (response && response[0] && response[0].length > 0) {
    let arr = response[0];
    setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
    setUserCollection([...userCollection, ...arr]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  } else if (collectionType === 'Sold') {
   const response = await getUserSoldNFTList(
    id,
    lastIndex + START_INDEX,
    lastIndex + COLLECTION_LAST_INDEX
   );
   if (response && response[0] && response[0].length > 0) {
    let arr = response[0];
    setLastIndex(lastIndex + COLLECTION_LAST_INDEX);
    setUserCollection([...userCollection, ...arr]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  }
  return null;
 };

 const getUserProfileData = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserData(UserData.getuserDetail());
  } else if (userProfileDetails !== null) {
   setUserData(JSON.parse(userProfileDetails));
  }
 };

 const getUserCollectionByToken = async (Id: any, Type: any) => {
  setCollectionType(Type);
  setLoading(true);
  setHideLoad(false);
  if (Type === Strings.MyNFT) {
   const response = await getUserNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: Strings.MyNFT,
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setCreatedItems(response[1][0].total_items);
     setLoading(false);
    } else {
     let obj: any = {
      type: Strings.MyNFT,
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Collection') {
   const response = await getUserAllCollection(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   const response1 = await getUserNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Collection',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
     setCreatedItems(response1[1][0].total_items);
    } else {
     let obj: any = {
      type: 'Collection',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Favourites') {
   const response = await getUserFavouritesList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Favourites',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Favourites',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Purchased') {
   const response = await getUserPurchasedNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Purchased',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Purchased',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  } else if (Type === 'Sold') {
   const response = await getUserSoldNFTList(
    Id,
    START_INDEX,
    COLLECTION_LAST_INDEX
   );
   if (response) {
    if (response[0].length > 0) {
     let obj: any = {
      type: 'Sold',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection(response[0]);
     setLoading(false);
    } else {
     let obj: any = {
      type: 'Sold',
      data: response[0],
     };
     let data: any = [...completeUserCollection];
     data.push(obj);
     setCompleteUserCollection(data);
     setUserCollection([]);
     setHideLoad(true);
     setLoading(false);
    }
   } else {
    setError(true);
    setLoading(false);
   }
  }
  return null;
 };
 const getUser = async (id: any) => {
  const response = await getUserById(id);
  if (response) {
   setCreatorData(response[0][0]);
   setPurchasedItems(response[3][0].total_purchase);
  } else {
   setError(true);
  }
  return null;
 };

 useEffect(() => {
  setLoading(true);
  if (params && params.state) {
   getUserProfileData();
   getUser(params.state.CreatorId);
   getUserCollectionByToken(
    params.state.CreatorId,
    params.state.type ? params.state.type : collectionType
   );
  } else {
   navigate('/');
  }
 }, []);

 return (
  <div className="darker">
   <div className="darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="darker">
      <Header userInfo={userData} />
      <div className="breadcumb-area clearfix creator-Breadcumb">
       <div className="breadcumb-content">
        <div className="container h-100">
         <div className="row h-100 align-items-center">
          <div className="col-12">
           <nav aria-label="breadcrumb" className="breadcumb--con text-center">
            <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
             {Strings.CreatorProfile}
            </h2>
            <ol
             className="breadcrumb justify-content-center wow fadeInUp"
             data-wow-delay="0.4s"
            >
             <li className="breadcrumb-item">
              <Link to="/">{Strings.Home}</Link>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
              {Strings.CreatorProfile}
             </li>
            </ol>
           </nav>
          </div>
         </div>
        </div>
       </div>
      </div>

      <section className="blog-area1 section-padding-100">
       <div className="container">
        <div className="row">
         <UserProfile
          UserDetails={creatorData}
          userNFTdetail={{
           createdItems: createdItems,
           purchasedItmes: purchasedItems,
          }}
          Account={creatorData.walletaddress}
          isHideEditButton={true}
          onCopy={() => setIsCopy(true)}
         />
         <div className="col-12 col-md-9 colCenter">
          <div className="dream-projects-menu dreamProject mb-50">
           <div className="text portfolio-menu" id="changeNfts">
            {CREATOR_COLLECTION.map((item) => {
             return (
              <div
               key={item.key}
               className={
                collectionType === item.type
                 ? 'collectionTypeButton'
                 : 'collectionTypeButtonDisable'
               }
               onClick={() => onButtonPress(item.type)}
              >
               <p className="collectionTitle">{item.type}</p>
              </div>
             );
            })}
           </div>
          </div>

          <div className="row" id="myprofile-collections">
           <div className="container">
            {loading ? (
             <div id="preloaderloadAreaSpecific">
              <div className="preload-contentAreaSpecific">
               <div>
                <img
                 className="animation-loader"
                 src="../img/icons/animationloader.gif"
                 alt=""
                />
               </div>
              </div>
             </div>
            ) : userCollection.length >= 1 ? (
             <div className="container2">
              {userCollection.map((item: any, index: any) => {
               return collectionType === 'Collection' ? (
                <Collection
                 key={item.collection_id}
                 collectionImage={item.image_url}
                 onCardClick={() =>
                  navigate(MY_COLLECTION_SCREEN, {
                   state: [
                    {
                     id: item.collection_id,
                     isCreatorProfile: true,
                     CreatorId: params.state.CreatorId,
                    },
                   ],
                  })
                 }
                 ownerName={
                  creatorData.firstname === null
                   ? Strings.MintishUser
                   : creatorData.firstname
                 }
                 collectionName={item.collection_name}
                />
               ) : (
                <>
                 <ListItem
                  userProfileScreen={{ paddingLeft: '5px' }}
                  isProfileScreen={true}
                  key={item.id}
                  category={item.category}
                  ownerName={
                   item.ownername === null
                    ? Strings.MintishUser
                    : item.ownername
                  }
                  ownerImage={
                   item.ownerimage ? item.ownerimage : 'img/authors/round2.jpg'
                  }
                  itemName={item.itemname}
                  itemPrice={item.itemamount}
                  itemCreatedTime={moment
                   .utc(item.createdat)
                   .local()
                   .startOf('seconds')
                   .fromNow()}
                  itemLike={0}
                  itemBlockChain={item.blockchain}
                  itemImage={item.tokenpath}
                  navigateToProfile={PROFILE_PAGE}
                  navigateToDetailPage={ITEM_DETAIL_PAGE}
                  routesIs={{ Id: item.id }}
                 />
                </>
               );
              })}
              ;
             </div>
            ) : (
             <>
              {loading ? null : (
               <div className="noData">{Strings.NODATAFOUND}</div>
              )}
              :
             </>
            )}
            {hideLoad || loading ? null : (
             <div className="col-12 col-lg-12 text-center">
              {loadMoreData ? (
               <div id="SpecificAreaLoaderUserProfile">
                <div>
                 <img
                  className="animation-loader2"
                  src="../img/icons/animationloader.gif"
                  alt=""
                 />
                </div>
               </div>
              ) : null}
              <div
               className="btn more-btn fadeInUp showMorebtn1"
               data-wow-delay="0.6s"
               onClick={() => getMoreItems()}
              >
               {Strings.ShowMore}
              </div>
             </div>
            )}
           </div>
          </div>
         </div>
        </div>
       </div>
       {isCopy ? (
        <PopUp
         message={Strings.Copied}
         navigate={() => {
          setIsCopy(false);
         }}
        />
       ) : null}
      </section>

      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default CreatorProfileScreen;
