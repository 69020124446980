import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MoralisProvider } from "react-moralis";
export default ReactDOM.render(
 <MoralisProvider
  appId="x44mpNB2Jk8JlVhOvhoU9yqahXqM1AbBOx5VMSm9"
  serverUrl="https://4upz9lk0hbwi.usemoralis.com:2053/server"
 >
  <App />
 </MoralisProvider>,
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
