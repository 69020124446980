import React from 'react';
import { Link } from 'react-router-dom';

export default function SuccessModal(props: any) {
 return (
  <div className="modalOverlay">
   <div className={props.style ? 'requestModal' : 'requestModal2'}>
    <span className="crossBtnOuter" onClick={props.close}>
     <img src="img/art-work/crossBtn.png" alt="" />
    </span>
    {props.isUserProfile ? null : (
     <img className="rocket_img" src={props.modalImage} alt="" />
    )}
    <h4>{props.ModalText}</h4>
    {props.isUserProfile ? null : (
     <a onClick={props.navigate} className="btn more-btn exploreBtn">
      {props.buttonText}
     </a>
    )}
   </div>
  </div>
 );
}
