export const getUploadedImage = (fileInput: any) => {
 let image: any = {};
 if (fileInput && fileInput.files && fileInput.isDefaultNamespace.length > 0) {
  const datafile = fileInput.files[0];
  var imageUrlIs = URL.createObjectURL(datafile);
  image = {
   name: datafile.name,
   imageUrl: imageUrlIs,
   Size: datafile.size,
  };
 }
 return image;
};

export const getImageAspectRatio = (ImageUri: any) => {
 const img = new Image();
 img.src = ImageUri;
 var x = (img.onload = (function () {
  var result: any = 1; // An example result
  if (Number(img.height / img.width) == Number(0.75)) return result;
  else return 0;
 })());
 console.log('value of x is ', x);
 return x;
};
