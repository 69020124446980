import { useEffect, useState } from 'react';
import ListItem from '../components/ListItem';
import moment from 'moment';
import {
 CREATOR_PROFILE_SCREEN,
 DEFAULT_INDEX,
 NO_COLLECTION_ERROR,
} from '../libs/Constants';
import { Link, useNavigate } from 'react-router-dom';
import {
 PROFILE_PAGE,
 WALLET_CONNECT,
 ITEM_DETAIL_PAGE,
 DISCOVER_PAGE,
 COLLECTION_ARRAY,
 NOT_LOGGEDIN_MSG,
 MY_COLLECTION_SCREEN,
 TOP_SELLERS_SCREEN,
} from '../libs/Constants';
import {
 getAllItems,
 getNewListedItems,
 addToFavouritesByToken,
 getFavouritesByToken,
 getHighestCollection,
 getTopSellers,
 getAllItemsFromCache,
} from '../service/ApiService';
import ErrorPage from '../components/Error';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useMoralis } from 'react-moralis';
import Item from '../components/Item';
import Collection from '../components/Collection';
import '../App.css';
import UserData from '../service/UserData';
import PopUp from '../components/PopUp';
import TopSellerCard from '../components/TopSellerCard';
import SuccessModal from '../components/SuccessModal';
import UploadNftModal from '../components/UploadNftModal';
import Strings from '../libs/Strings';

function HomeScreen() {
 const navigate = useNavigate();
 const [itemsData, setItemsData] = useState([]);
 const [error, setError] = useState(false);
 const [filterData, setFilterData] = useState([]);
 const [popularCollection, setPopularCollection] = useState([]);
 const { isAuthenticated } = useMoralis();
 const [isNewListedLoading, setIsNewListedLoading] = useState(false);
 const [isClassifiedNFTLoading, setIsClassifiedNFTLoading] = useState(false);
 const [isPopularCollectionLoading, setIsPopularCollectionLoading] =
  useState(false);
 const [showUploadNFTModal, setShowUploadNFTModal] = useState(false);
 const [collectionType, setCollectionType] = useState('All');
 const [UserDetails, setUserDetails] = useState<any>({});
 const [showNotLogged, setShowNotLogged] = useState(false);
 const [showNoCollectionModal, setShowNoCollectionModal] = useState(false);
 const [isTopSellersLoading, setIsTopSellersLoading] = useState(false);
 const [topSellersData, setTopSellers] = useState([]);
 const userProfileDetails: any = localStorage.getItem('userDetails') as any;
 const userNFT: any = localStorage.getItem('userNFts') as any;
 const userLoginDetail: any = localStorage.getItem('UserloginDetail') as any;

 const getUserCollectionInfo = () => {
  if (Object.keys(UserData.getuserNFt()).length > 0) {
   let userCollection = UserData.getuserNFt();
   if (userCollection.userCollection > 0) {
    setShowUploadNFTModal(true);
   } else {
    setShowNoCollectionModal(true);
   }
  } else if (userNFT) {
   let userCollection = JSON.parse(userNFT);
   if (userCollection.userCollection > 0) {
    setShowUploadNFTModal(true);
   } else {
    setShowNoCollectionModal(true);
   }
  }
 };

 const OnCreateNftClick = () => {
  if (isAuthenticated) {
   getUserCollectionInfo();
  } else {
   navigate(WALLET_CONNECT);
  }
 };

 const getItems = async () => {
  setIsNewListedLoading(true);
  var favao: any = [];
  if (Object.keys(UserData.getuserLoginInfo()).length > 0) {
   let userData = UserData.getuserLoginInfo();
   const res = await getFavouritesByToken(userData.userId, userData.userToken);
   favao = res;
  } else if (JSON.parse(userLoginDetail)) {
   let userData = JSON.parse(userLoginDetail);
   const res = await getFavouritesByToken(userData.userId, userData.userToken);
   favao = res;
  } else {
   favao = [];
  }
  const response = await getNewListedItems();
  if (response) {
   let arr = response;
   arr.map((item: any) => {
    item.favouate = false;
    return item;
   });
   if (favao?.length === 0) {
    setItemsData(arr);
    setIsNewListedLoading(false);
   }
   favao?.map((item: any, index: any) => {
    var inde = arr.findIndex((i: any) => i.id == item.itemid);
    if (inde >= 0) {
     arr[inde].favouate = true;
    }
    if (index === favao.length - 1) {
     setItemsData(arr);
     setIsNewListedLoading(false);
    }
   });
  } else {
   setIsNewListedLoading(false);
  }
 };
 const getFilteredData = async (getdata: string) => {
  setIsClassifiedNFTLoading(true);
  const response = await getAllItems(getdata);
  if (response) {
   setFilterData(response[DEFAULT_INDEX]);
   setIsClassifiedNFTLoading(false);
  } else {
   setIsClassifiedNFTLoading(false);
  }
 };

 const getFilteredDataFromCache = async (getdata: string) => {
  setIsClassifiedNFTLoading(true);
  const response = await getAllItemsFromCache(getdata);
  if (response) {
   setFilterData(response[DEFAULT_INDEX]);
   setIsClassifiedNFTLoading(false);
  } else {
   setIsClassifiedNFTLoading(false);
  }
 };

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserDetails(UserData.getuserDetail());
  } else if (JSON.parse(userProfileDetails)) {
   setUserDetails(JSON.parse(userProfileDetails));
  }
 };

 useEffect(() => {
  getUserprofile();
 }, []);

 const getPopularCollection = async () => {
  if (Object.keys(UserData.getPopularCollection()).length > 0) {
   setPopularCollection(UserData.getPopularCollection());
  } else {
   setIsPopularCollectionLoading(true);
   const response = await getHighestCollection();
   if (response && response.length > 0) {
    setPopularCollection(response);
    UserData.updatePopularCollections(response);
    setIsPopularCollectionLoading(false);
   } else {
    setIsPopularCollectionLoading(false);
   }
  }
 };

 const getTopSellersOfMonth = async () => {
  setIsTopSellersLoading(true);
  const response = await getTopSellers();
  if (response && response.length > 0) {
   setTopSellers(response);
   setIsTopSellersLoading(false);
  } else {
   setIsTopSellersLoading(false);
  }
 };

 useEffect(() => {
  getItems();
  getFilteredData('All');
  getPopularCollection();
  getTopSellersOfMonth();
 }, []);

 const onLikeButtonPress = async (index: any, item: any) => {
  if (isAuthenticated) {
   let userData = JSON.parse(userLoginDetail);
   const res = await addToFavouritesByToken(item.id, userData.userToken);
   let arr: any = [...itemsData];
   arr[index].favouate = !item.favouate;
   setItemsData(arr);
  } else {
   setShowNotLogged(true);
  }
 };

 const exploreMoreItems = () => {
  const element = document.getElementById('exploreMoreSection');
  if (element != null) {
   element.scrollIntoView({
    behavior: 'smooth',
   });
  }
 };
 const onButtonPress = (type: any) => {
  setCollectionType(type);
  getFilteredDataFromCache(type);
 };

 const navigateToCreatorProfile = (id: any) => {
  navigate(CREATOR_PROFILE_SCREEN, {
   state: { CreatorId: id },
  });
 };

 const OnTopSellerClick = (id: any) => {
  if (isAuthenticated) {
   let userData = JSON.parse(userLoginDetail);
   let localId = userData.userId;
   if (localId == id) {
    navigate(PROFILE_PAGE);
   } else {
    navigateToCreatorProfile(id);
   }
  } else {
   navigateToCreatorProfile(id);
  }
 };

 return (
  <div className="darker">
   <div className="darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="darker">
      <Header userInfo={UserDetails} />
      <section className="hero-section moving section-padding" id="home">
       <div className="moving-bg"></div>

       <div className="hero-section-content">
        <div className="container ">
         <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-md-12">
           <div className="welcome-content">
            <div className="promo-section">
             <h3 className="special-head gradient-text">{Strings.MusicNft}</h3>
            </div>
            <h1 className="fadeInUp heading1" data-wow-delay="0.2s">
             {Strings.MarketPlaceText}{' '}
             <span className="gradient-text"> {Strings.SupportsMulti}</span>{' '}
            </h1>
            <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
             <div
              className="btn more-btn mr-3"
              onClick={() => exploreMoreItems()}
             >
              {Strings.EXPLORENFTs}
             </div>
             <div onClick={() => OnCreateNftClick()} className="btn more-btn">
              {' '}
              {Strings.CREATE}{' '}
             </div>
            </div>
           </div>
          </div>
          <div className="col-lg-6"></div>
         </div>
        </div>
       </div>
      </section>

      <div className="clearfix"></div>
      <section className="about-us-area section-padding-100 clearfix">
       <div className="container">
        <div className="row align-items-center">
         <div className="col-12 col-lg-12">
          <div className="who-we-contant">
           <div className="dream-dots text-left fadeInUp" data-wow-delay="0.2s">
            <span className="gradient-text ">{Strings.CreativeCreators}</span>
           </div>
           <div className="about">
            <h4 className="fadeInUp" data-wow-delay="0.3s">
             {Strings.TopSellers}
            </h4>
            <a
             className="gradient-text exploremoreButton"
             onClick={() => navigate(TOP_SELLERS_SCREEN)}
            >
             {Strings.EXPLOREMORE}
             <img src="img/art-work/arrowIcon.png" alt="" />
            </a>
           </div>
          </div>
         </div>

         {isTopSellersLoading ? (
          <div id="preloaderloadAreaSpecificHome">
           <div className="preload-contentAreaSpecific">
            <div>
             <img
              className="animation-loader"
              src="../img/icons/animationloader.gif"
              alt=""
             />
            </div>
           </div>
          </div>
         ) : topSellersData.length > 0 ? (
          <div className="topseller-sec">
           <div className="users-section">
            {topSellersData.map((item: any, index: any) => {
             return (
              <TopSellerCard
               onItemClick={() => OnTopSellerClick(item.UserId)}
               sellerInfo={item}
               Index={index}
              />
             );
            })}
           </div>
          </div>
         ) : (
          <div className="noData">{Strings.NODATAFOUND}</div>
         )}
        </div>
       </div>
      </section>
      <section className="features section-padding-0-100">
       <div className="container" id="listedItem-sec">
        <div className="section-heading text-center">
         <div
          className="dream-dots justify-content-center fadeInUp"
          data-wow-delay="0.2s"
         >
          <span>{Strings.DicoverNewItems}</span>
         </div>
         <h2 className="fadeInUp" data-wow-delay="0.3s">
          {Strings.NewListedItems}
         </h2>
        </div>
        <div className="row">
         <div className="listedItems">
          {isNewListedLoading ? (
           <div id="preloaderloadAreaSpecificHome">
            <div className="preload-contentAreaSpecific">
             <div>
              <img
               className="animation-loader"
               src="../img/icons/animationloader.gif"
               alt=""
              />
             </div>
            </div>
           </div>
          ) : itemsData.length > 0 ? (
           <>
            {itemsData.map((item: any, index: any) => {
             return (
              <>
               <ListItem
                key={item.id}
                category={item.category}
                ownerName={
                 item.ownername === null ? Strings.MintishUser : item.ownername
                }
                ownerImage={
                 item.ownerimage ? item.ownerimage : 'img/authors/round2.jpg'
                }
                itemName={item.itemname}
                itemPrice={item.itemamount}
                itemCreatedTime={moment
                 .utc(item.createdat)
                 .local()
                 .startOf('seconds')
                 .fromNow()}
                itemLike={0}
                itemBlockChain={item.blockchain}
                itemImage={item.tokenpath}
                onIconPress={() => onLikeButtonPress(index, item)}
                navigateToProfile={PROFILE_PAGE}
                navigateToDetailPage={ITEM_DETAIL_PAGE}
                routesIs={{ Id: item.id }}
                showLike={item.favouate}
               />
              </>
             );
            })}
            ;
           </>
          ) : (
           <div className="noData">{Strings.NODATAFOUND}</div>
          )}
         </div>
        </div>
       </div>
      </section>

      <section
       className="features section-padding-0-100 "
       id="exploreMoreSection"
      >
       <div className="container">
        <div className="section-heading text-center">
         <div
          className="dream-dots justify-content-center fadeInUp"
          data-wow-delay="0.2s"
         >
          <span>{Strings.OurTopCollection}</span>
         </div>
         <h2 className="fadeInUp" data-wow-delay="0.3s">
          {Strings.ClassifiedNFTs}
         </h2>
        </div>
        <div className="row">
         <div className="col-12 col-md-12" id="topCollectionpanels">
          <div className="dream-projects-menu mb-50">
           <div className="text portfolio-menu txtT2">
            {COLLECTION_ARRAY.map((item: any, index: any) => {
             return (
              <div
               key={item.key}
               className={
                collectionType === item.type
                 ? 'collectionTypeButton'
                 : 'collectionTypeButtonDisable'
               }
               onClick={() => onButtonPress(item.type)}
              >
               <p className="collectionTitle">{item.type}</p>
              </div>
             );
            })}
           </div>
          </div>
          <div className="wholeRow">
           <div className="list4">
            <div className="row align-items-center">
             <div className="listedItems2">
              {filterData.length >= 1 ? (
               <>
                {filterData.map((item: any, index: any) => {
                 return (
                  <>
                   <Item
                    key={item.id}
                    category={item.category}
                    itemName={item.itemname}
                    ownerName={
                     item.ownername === null
                      ? Strings.MintishUser
                      : item.ownername
                    }
                    itemImage={item.tokenpath}
                    navigateToDetailPage={ITEM_DETAIL_PAGE}
                    routesIs={{ Id: item.id }}
                   />
                  </>
                 );
                })}
                ;
               </>
              ) : isClassifiedNFTLoading ? (
               <div id="preloaderloadAreaSpecificHome">
                <div className="preload-contentAreaSpecific">
                 <div>
                  <img
                   className="animation-loader"
                   src="../img/icons/animationloader.gif"
                   alt=""
                  />
                 </div>
                </div>
               </div>
              ) : (
               <div className="noData">{Strings.NODATAFOUND}</div>
              )}
             </div>
            </div>
            {filterData.length > 0 ? (
             <div className="col-12 col-lg-12 text-center">
              <Link
               to={DISCOVER_PAGE}
               state={{ Type: collectionType }}
               className="btn more-btn fadeInUp"
               data-wow-delay="0.6s"
              >
               {Strings.ShowMore}
              </Link>
             </div>
            ) : null}
           </div>
          </div>
         </div>
        </div>
       </div>
      </section>
      <section className="section-padding-0-70 clearfix">
       <div className="container">
        <div className="section-heading text-center">
         <div
          className="dream-dots justify-content-center fadeInUp"
          data-wow-delay="0.2s"
         >
          <span>{Strings.OurTopCollection}</span>
         </div>
         <h2 className="fadeInUp" data-wow-delay="0.3s">
          {Strings.PopularCollections}
         </h2>
        </div>

        <div className="row">
         {isPopularCollectionLoading ? null : popularCollection.length >= 1 ? (
          <div className="container2">
           {popularCollection.map((item: any, index: any) => {
            return (
             <>
              <Collection
               key={item.collection_id}
               collectionImage={item.image_url}
               onCardClick={() =>
                navigate(MY_COLLECTION_SCREEN, { state: item.collection_id })
               }
               ownerName={
                item.ownername === null ? Strings.MintishUser : item.ownername
               }
               collectionName={item.collection_name}
              />
             </>
            );
           })}
          </div>
         ) : (
          <>
           {isPopularCollectionLoading ? null : (
            <div className="noData">{Strings.NODATAFOUND}</div>
           )}
           :
          </>
         )}
        </div>
       </div>
       {showUploadNFTModal ? (
        <UploadNftModal
         onCrossButtonPress={() => setShowUploadNFTModal(false)}
        />
       ) : null}

       {showNoCollectionModal ? (
        <SuccessModal
         isUserProfile={true}
         style={true}
         close={() => setShowNoCollectionModal(false)}
         ModalText={NO_COLLECTION_ERROR}
        />
       ) : null}
       {showNotLogged ? (
        <PopUp
         message={NOT_LOGGEDIN_MSG}
         navigate={() => {
          setShowNotLogged(false);
          navigate(WALLET_CONNECT);
         }}
        />
       ) : null}
      </section>
      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default HomeScreen;
