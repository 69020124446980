import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ErrorPage from '../components/Error';
import Item from '../components/Item';
import {
 ITEM_DETAIL_PAGE,
 START_INDEX,
 MY_COLLECTION_LAST_INDEX,
 PROFILE_PAGE,
 CREATOR_PROFILE_SCREEN,
 COLLECTION,
 USER_DOES_NOT_EXIST,
} from '../libs/Constants';
import {
 getUserNFTByCollection,
 getPoPularNftByCollectionId,
 getUserById,
} from '../service/ApiService';
import UserData from '../service/UserData';
import '../App.css';
import PopUp from '../components/PopUp';
import Strings from '../libs/Strings';

function MyCollectionScreen() {
 const navigate = useNavigate();
 let params: any = useLocation();
 const [error, setError] = useState(false);
 const [lastIndex, setLastIndex] = useState(MY_COLLECTION_LAST_INDEX);
 const [loading, setLoading] = useState(true);
 const [userData, setUserData] = useState<any>([]);
 const [validationMsg, setValidationMsg] = useState('');
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [hideLoad, setHideLoad] = useState(false);
 const [isFromProfileScreen, setIsFromProfileScreen] = useState(false);
 const [isFromCreatorProfileScreen, setIsFromCreatorProfileScreen] =
  useState(false);
 const [userNFTByCollectionId, setUserNFTByCollectionId] = useState<any>([]);
 const [loadMoreData, setLoadMoreData] = useState(false);
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const checkUserCameFrom = async () => {
  if (params.state[0]) {
   if (isFromCreatorProfileScreen) {
    const response = await getUserById(params.state[0].CreatorId);
    if (response && response[0].length == 0) {
     setValidationMsg(USER_DOES_NOT_EXIST);
     setCreateValidItemModal(true);
    } else {
     navigate(CREATOR_PROFILE_SCREEN, {
      state: { CreatorId: params.state[0].CreatorId, type: COLLECTION },
     });
    }
   } else {
    const response = await getUserById(userLoginDetail.userId);
    if (response && response[0].length == 0) {
     setValidationMsg(USER_DOES_NOT_EXIST);
     setCreateValidItemModal(true);
    } else {
     navigate(PROFILE_PAGE, {
      state: [
       {
        id: userLoginDetail.userId,
        type: COLLECTION,
        token: userLoginDetail.userToken,
       },
      ],
     });
    }
   }
  } else {
   navigate('/');
  }
 };

 const getUserNftByCollection = async (collectionId: any, token: any) => {
  const response = await getUserNFTByCollection(
   collectionId,
   token,
   START_INDEX,
   lastIndex
  );
  if (response) {
   if (response[0].length > 0) {
    setUserNFTByCollectionId(response[0]);
    setLoading(false);
   } else {
    setLoading(false);
    setHideLoad(true);
   }
  } else {
   setHideLoad(true);
   setError(true);
  }
 };
 const getPopularNfts = async (collectionId: any) => {
  const response = await getPoPularNftByCollectionId(
   collectionId,
   START_INDEX,
   lastIndex
  );
  if (response) {
   if (response[0].length > 0) {
    setUserNFTByCollectionId(response[0]);
    setLoading(false);
   } else {
    setLoading(false);
    setHideLoad(true);
   }
  } else {
   setLoading(false);
   setError(true);
  }
 };
 const getMoreUserNftByCollection = async (collectionId: any, token: any) => {
  const response = await getUserNFTByCollection(
   collectionId,
   token,
   lastIndex + START_INDEX,
   lastIndex + MY_COLLECTION_LAST_INDEX
  );
  if (response) {
   if (response[0].length > 0) {
    setLastIndex(lastIndex + 20);
    setUserNFTByCollectionId([...userNFTByCollectionId, ...response[0]]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  } else {
   setError(true);
  }
 };
 const getMorePopularNfts = async (collectionId: any) => {
  const response = await getPoPularNftByCollectionId(
   collectionId,
   lastIndex + START_INDEX,
   lastIndex + MY_COLLECTION_LAST_INDEX
  );
  if (response) {
   if (response[0].length > 0) {
    setLastIndex(lastIndex + 20);
    setUserNFTByCollectionId([...userNFTByCollectionId, ...response[0]]);
    setLoadMoreData(false);
   } else {
    setLoadMoreData(false);
    setHideLoad(true);
   }
  } else {
   setError(true);
  }
 };
 const getUserProfileData = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserData(UserData.getuserDetail());
  } else if (userProfileDetails !== null) {
   setUserData(JSON.parse(userProfileDetails));
  }
 };

 useEffect(() => {
  setLoading(true);
  if (params && params.state) {
   if (params.state[0]) {
    if (params.state[0].isCreatorProfile) {
     setIsFromCreatorProfileScreen(true);
     getUserProfileData();
     getPopularNfts(params.state[0].id);
    } else {
     setIsFromProfileScreen(true);
     getUserProfileData();
     getUserNftByCollection(params.state[0].id, userLoginDetail.userToken);
    }
   } else {
    getUserProfileData();
    getPopularNfts(params.state);
   }
  }
 }, []);

 const getMoreItems = () => {
  setLoadMoreData(true);
  if (params.state[0]) {
   if (params.state[0].isCreatorProfile) {
    getMorePopularNfts(params.state[0].id);
   } else {
    getMoreUserNftByCollection(params.state[0].id, userLoginDetail.userToken);
   }
  } else {
   getMorePopularNfts(params.state);
  }
 };

 return (
  <div className="darker">
   <div className="darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="darker">
      <Header userInfo={userData} />

      <div className="breadcumb-area clearfix myCollectionBreadcumb">
       <div className="breadcumb-content">
        <div className="container h-100">
         <div className="row h-100 align-items-center">
          <div className="col-12">
           <nav aria-label="breadcrumb" className="breadcumb--con text-center">
            <h2 className="title wow fadeInUp" data-wow-delay="0.2s">
             {isFromProfileScreen
              ? Strings.MyCollections
              : isFromCreatorProfileScreen
              ? Strings.Collections
              : Strings.PopularCollections}
            </h2>
            <ol
             className="breadcrumb justify-content-center wow fadeInUp"
             data-wow-delay="0.4s"
            >
             <li className="breadcrumb-item">
              <Link to="/">{Strings.Home}</Link>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
              {isFromProfileScreen
               ? Strings.MyCollections
               : isFromCreatorProfileScreen
               ? Strings.Collections
               : Strings.PopularCollections}{' '}
             </li>
            </ol>
           </nav>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div></div>

      <section className="blog-area section-padding-100">
       <div className="container">
        <div className="wholeRow">
         <div className="column2">
          <div className="morebtn-inner">
           <div className="more-btn5" onClick={() => checkUserCameFrom()}>
            {Strings.BackCollection}
           </div>
          </div>
         </div>
         <div className="row">
          <div className="container">
           <div className="row align-items-center">
            <div className="listedItems2">
             {loading ? (
              <div id="preloaderloadAreaSpecificMyCollection">
               <div className="preload-contentAreaSpecific">
                <div>
                 <img
                  className="animation-loader"
                  src="../img/icons/animationloader.gif"
                  alt=""
                 />
                </div>
               </div>
              </div>
             ) : userNFTByCollectionId.length >= 1 ? (
              <div className="container2">
               {userNFTByCollectionId.map((item: any, index: any) => {
                return (
                 <>
                  <Item
                   key={item.item_id}
                   category={item.category}
                   itemName={item.name}
                   ownerName={
                    isFromProfileScreen
                     ? (userData as any).firstname
                       ? (userData as any).firstname
                       : Strings.MintishUser
                     : item.ownername
                     ? item.ownername
                     : Strings.MintishUser
                   }
                   itemImage={item.token_path}
                   navigateToDetailPage={ITEM_DETAIL_PAGE}
                   routesIs={{ Id: item.item_id }}
                  />
                 </>
                );
               })}
              </div>
             ) : (
              <>
               {loading ? null : (
                <div className="noData">{Strings.NODATAFOUND}</div>
               )}
               :
              </>
             )}
            </div>
           </div>
          </div>
         </div>
        </div>
        <div>
         {hideLoad || loading ? null : (
          <div className="col-12 col-lg-12 text-center">
           {loadMoreData ? (
            <div id="SpecificAreaLoaderMyCollection">
             <div>
              <div>
               <img
                className="animation-loader2"
                src="../img/icons/animationloader.gif"
                alt=""
               />
              </div>
             </div>
            </div>
           ) : null}

           <div
            onClick={() => getMoreItems()}
            className="btn more-btn fadeInUp"
            data-wow-delay="0.6s"
           >
            {Strings.ShowMore}
           </div>
          </div>
         )}
        </div>
       </div>
       {createValidItemModal ? (
        <PopUp
         message={validationMsg}
         navigate={() => {
          setCreateValidItemModal(false);
         }}
        />
       ) : null}
      </section>

      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default MyCollectionScreen;
