import React from 'react';
import { Link } from 'react-router-dom';
import {
 CREATE_ITEM_SCREEN,
 CREATE_MULTIPLE_ITEMS_SCREEN,
} from '../libs/Constants';
import Strings from '../libs/Strings';
export default function uploadNftModal(props: any) {
 return (
  <div className="modalOverlay">
   <div className="selectOptionModal">
    <div className="modalHeader">
     <span
      className="crossBtnOuter closeIconOuter"
      onClick={() => props.onCrossButtonPress()}
     >
      <img src="img/art-work/crossBtn.png" alt="" />
     </span>
    </div>
    <div className="modalHeadings">
     <h4>{Strings.Chooseoneofthe}</h4>
     <p>{Strings.SelectTheRelevant}</p>
    </div>
    <div className="selectOptionModalCenter">
     <div className="singleNftOuter">
      <div className="singleNft">
       <img src="img/art-work/selectOptionModalImage.png" alt="" />
      </div>
      <Link to={CREATE_ITEM_SCREEN} className="btn more-btn singleNftButton ">
       {Strings.SingleNFT}
      </Link>
     </div>
     <div className="multiperNftOuter ">
      <div className="multiperNft">
       <div className="multipleNfts-imgs">
        <img src="img/art-work/selectOptionModalImage2.png" alt="" />
        <img src="img/art-work/selectOptionModalImage2.png" alt="" />
        <img src="img/art-work/selectOptionModalImage2.png" alt="" />
        <img src="img/art-work/selectOptionModalImage2.png" alt="" />
       </div>
      </div>
      <Link
       to={CREATE_MULTIPLE_ITEMS_SCREEN}
       className="btn more-btn singleNftButton">
        {Strings.MultipleNFTs}
      </Link>
     </div>
    </div>
   </div>
  </div>
 );
}
