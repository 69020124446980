import { Link } from 'react-router-dom';
import Strings from '../libs/Strings';
import '../App.css';

function Item(props: any) {
 return (
  <div className="collectionPanels">
   <Link
    to={props.navigateToDetailPage}
    state={props.routesIs}
    onClick={props.onImageClick}
   >
    <div className="pricing-item-1">
     <div className="wraper">
      <div className="panelDiv3">
       <div className="panelDiv2">
        {props.category === 'Video' ? (
         <>
          {' '}
          <div className="playIcon_div">
           <i className="fa fa-play play-icon" aria-hidden="true"></i>
          </div>
          <video width="100%" height="100%">
           <source src={props.itemImage} type="video/mp4" />
          </video>
         </>
        ) : (
         <img
          src={
           props.category === 'Music'
            ? 'img/art-work/MusicPlayer.gif'
            : props.itemImage
          }
          className="img-responsive listImage"
          alt=""
         />
        )}
       </div>
      </div>

      <h3 className="text">{props.itemName}</h3>
      <p className="text">
       <span>{Strings.Owner} : </span>
       {props.ownerName}
      </p>
     </div>
    </div>
   </Link>
  </div>
 );
}

export default Item;
