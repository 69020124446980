import Strings from '../libs/Strings';
import '../App.css';

function Collection(props: any) {
 return (
  <div className="collectionItem1" onClick={() => props.onCardClick()}>
   <a className="service_single_content collection-item ">
    <div className="panelDiv3">
     <div className="collection_icon1">
      <img src={props.collectionImage} alt="" />
     </div>
    </div>
    <div className="collection_info">
     <h6>{props.collectionName}</h6>
     <p>
      {Strings.Owner} : <span className="w-text">{props.ownerName}</span>
     </p>
    </div>
   </a>
  </div>
 );
}

export default Collection;
