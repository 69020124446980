import '../App.css';

function Dropdown(props: any) {
 return (
  <select
   onChange={(evt) => props.setGender(evt.target.value)}
   name="gender"
   id="gender"
   className="inputClass seletct_sec"
  >
   <option>{props.dropDownTitle}</option>
   <option value="MALE">{props.firstOption}</option>
   <option value="FEMALE">{props.secondOption}</option>
   <option value="OTHER">{props.thirdOption}</option>
  </select>
 );
}

export default Dropdown;
