import { Link, useNavigate } from 'react-router-dom';
import {
 DISCOVER_PAGE,
 CONTACT_US,
 PROFILE_PAGE,
 WALLET_CONNECT,
} from '../libs/Constants';
import '../App.css';
import { useMoralis } from 'react-moralis';
import Strings from '../libs/Strings';
function Header(props: any) {
 const navigate = useNavigate();
 const { logout, isAuthenticated } = useMoralis();

 const onLogoutClick = async () => {
  await logout();
  localStorage.clear();
  navigate('/');
 };

 return (
  <nav className="navbar navbar-expand-lg navbar-white fixed-top" id="banner">
   <div className="container">
    <Link className="navbar-brand" to={'/'}>
     <span>
      <img src="img/art-work/mintishLogo.png" alt="" />
     </span>{' '}
    </Link>
    <button
     className="navbar-toggler"
     type="button"
     data-toggle="collapse"
     data-target="#collapsibleNavbar"
    >
     <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="collapsibleNavbar">
     <ul className="navbar-nav ml-auto">
      <li className="nav-item">
       <Link to={'/'} className="nav-link">
        {Strings.Home}
       </Link>
      </li>
      <li className="nav-item dropdown">
       <div className="nav-link dropdown-toggle" data-toggle="dropdown">
        {Strings.Explore}
       </div>
       <div className="dropdown-menu dropdown-menu2">
        {props.all ? (
         <div className="dropdown-item" onClick={() => props.all('All')}>
          {Strings.AllNFTs}
         </div>
        ) : (
         <Link
          to={DISCOVER_PAGE}
          state={{ Type: 'All' }}
          className="dropdown-item"
         >
          {Strings.AllNFTs}
         </Link>
        )}
        {props.music ? (
         <div className="dropdown-item" onClick={() => props.music('Music')}>
          {Strings.Music}
         </div>
        ) : (
         <Link
          to={DISCOVER_PAGE}
          state={{ Type: 'Music' }}
          className="dropdown-item"
         >
          {Strings.Music}
         </Link>
        )}
        {props.image ? (
         <div className="dropdown-item" onClick={() => props.image('Image')}>
          {Strings.Image}
         </div>
        ) : (
         <Link
          to={DISCOVER_PAGE}
          state={{ Type: 'Image' }}
          className="dropdown-item"
         >
          {Strings.Image}
         </Link>
        )}
        {props.tour ? (
         <div className="dropdown-item" onClick={() => props.tour('Tours')}>
          {Strings.Tours}
         </div>
        ) : (
         <Link
          to={DISCOVER_PAGE}
          state={{ Type: 'Tours' }}
          className="dropdown-item"
         >
          {Strings.Tours}
         </Link>
        )}
        {props.video ? (
         <div className="dropdown-item" onClick={() => props.video('Video')}>
          {Strings.Video}
         </div>
        ) : (
         <Link
          to={DISCOVER_PAGE}
          state={{ Type: 'Video' }}
          className="dropdown-item"
         >
          {Strings.Video}
         </Link>
        )}
       </div>
      </li>
      <li className="nav-item">
       <Link className="nav-link" to={CONTACT_US}>
        {Strings.ContactUs}
       </Link>
      </li>
      {isAuthenticated ? (
       <li className="nav-item dropdown">
        <div className="nav-link" data-toggle="dropdown">
         <div className="user">
          <img
           src={
            props.userInfo.imageurl
             ? props.userInfo.imageurl
             : 'img/art-work/user.png'
           }
           width="40"
           alt=""
           className="user__Img"
          />{' '}
         </div>
         <p className="userName">
          {Strings.Hi},
          {props.userInfo.firstname ? props.userInfo.firstname : 'User'}
         </p>
        </div>
        <div className="dropdown-menu dropdown-menu2 dropdownMenu">
         <Link
          className="dropdown-item dropdownItem2"
          to={PROFILE_PAGE}
          state={[
           {
            id: localStorage.getItem('userId'),
            type: "My NFT's",
            token: localStorage.getItem('userToken'),
           },
          ]}
         >
          <img
           src="img/art-work/border.png"
           alt=""
           className="dropdownIcon withBorder"
          />
          <img
           src="img/art-work/userIcon.png"
           alt=""
           className="dropdownIcon"
          />
          {Strings.MyProfile}
         </Link>

         {props.collection ? (
          <div
           className="dropdown-item dropdownItem2"
           onClick={() => props.collection()}
          >
           <img
            src="img/art-work/collection.png"
            alt=""
            className="dropdownIcon"
           />
           {Strings.MyCollection}
          </div>
         ) : (
          <Link
           className="dropdown-item dropdownItem2"
           to={PROFILE_PAGE}
           state={[
            {
             id: localStorage.getItem('userId'),
             type: 'Collection',
             token: localStorage.getItem('userToken'),
            },
           ]}
          >
           <img
            src="img/art-work/collection.png"
            alt=""
            className="dropdownIcon"
           />
           {Strings.MyCollection}
          </Link>
         )}
         {props.favourite ? (
          <div
           onClick={() => props.favourite()}
           className="dropdown-item dropdownItem2"
          >
           <img src="img/art-work/heart.png" className="dropdownIcon" alt="" />
           {Strings.Favourites}
          </div>
         ) : (
          <Link
           to={PROFILE_PAGE}
           state={[
            {
             id: localStorage.getItem('userId'),
             type: 'Favourites',
             token: localStorage.getItem('userToken'),
            },
           ]}
           className="dropdown-item dropdownItem2"
          >
           <img src="img/art-work/heart.png" alt="" className="dropdownIcon" />
           {Strings.Favourites}
          </Link>
         )}
         <div
          className="dropdown-item dropdownItem2"
          onClick={() => onLogoutClick()}
         >
          <img
           src="img/art-work/logOutIcon.png"
           alt=""
           className="dropdownIcon"
          />
          {Strings.LogOut}
         </div>
        </div>
       </li>
      ) : (
       <li className="lh-55px">
        <Link to={WALLET_CONNECT} className="btn login-btn ml-50">
         {Strings.ConnectWallet}
        </Link>
       </li>
      )}
     </ul>
    </div>
   </div>
  </nav>
 );
}

export default Header;
