import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getEllipsisTxt } from '../helpers/formatters';
import { useNavigate } from 'react-router-dom';
import { CREATE_USER_PROFILE } from '../libs/Constants';
import '../App.css';
import Strings from '../libs/Strings';

function UserProfile(props: any) {
 const navigate = useNavigate();
 return (
  <div className="col-12 col-lg-3">
   <div className="service_single_content collection-item">
    <div className="collection_icon">
     {props.isHideEditButton ? null : (
      <div className="editOuter" onClick={() => navigate(CREATE_USER_PROFILE)}>
       {Strings.Edit} <img src="img/art-work/Vector.svg" alt="" />
      </div>
     )}

     <img
      src="img/art-work/profileHeader.jpg"
      className="center-block profileBG"
      alt=""
     />
    </div>
    <span className="aut-info">
     <img
      className="aut_info_img"
      src={
       props.UserDetails.imageurl === null
        ? 'img/art-work/user.png'
        : props.UserDetails.imageurl
      }
      width="50"
      alt=""
     />
    </span>
    <div className="collection_info text-center">
     <h6>
      {props.UserDetails.firstname === null
       ? Strings.MintishUser
       : props.UserDetails.firstname}
     </h6>
     <p className="w-text mr-5p">
      {props.UserDetails.country ? props.UserDetails.country : ' '}{' '}
      {props.UserDetails.city ? props.UserDetails.city : ' '}{' '}
      {props.UserDetails.zipcode ? props.UserDetails.zipcode : ' '}{' '}
     </p>
     <p className="w-text mr-5p">{props.UserDetails.email} </p>

     <div className="search-widget-area mt-15">
      <form action="#" method="post">
       <div className="details-list detailItem item_DetailList">
        <p className="createrBtn padding">
         {' '}
         {props.userNFTdetail.createdItems} <span>{Strings.Created}</span>
        </p>
        <p className="createrBtn padding">
         {props.userNFTdetail.purchasedItmes}
         <span>{Strings.Purchased}</span>
        </p>
       </div>
       <p className="mt-15 mt_pr">{props.UserDetails.bio}</p>
       <div className="formOuter">
        <input
         type="text"
         name="wallet"
         id="wallet"
         className="form"
         value={getEllipsisTxt(props.Account, 6)}
        />
        <CopyToClipboard
         text={props.Account || ''}
         onCopy={() => props.onCopy()}
        >
         <span className="btn">
          <i className="fa fa-copy"></i>
         </span>
        </CopyToClipboard>
       </div>
      </form>
     </div>

     <ul className="social-links mt-15">
      {props.UserDetails.facebooklink ? (
       <li>
        <a href={props.UserDetails.facebooklink} target="_blank">
         <span className="fa fa-facebook-f"></span>
        </a>
       </li>
      ) : null}

      {props.UserDetails.twitterlink ? (
       <li>
        <a href={props.UserDetails.twitterlink} target="_blank">
         <span className="fa fa-twitter"></span>
        </a>
       </li>
      ) : null}

      {props.UserDetails.instagramlink ? (
       <li>
        <a href={props.UserDetails.instagramlink} target="_blank">
         <span className="fa fa-instagram"></span>
        </a>
       </li>
      ) : null}
     </ul>
    </div>
   </div>
  </div>
 );
}

export default UserProfile;
