export const abiCreate = [
 {
  inputs: [],
  stateMutability: 'nonpayable',
  type: 'constructor',
 },
 {
  anonymous: false,
  inputs: [
   {
    indexed: true,
    internalType: 'address',
    name: 'owner',
    type: 'address',
   },
   {
    indexed: true,
    internalType: 'address',
    name: 'approved',
    type: 'address',
   },
   {
    indexed: true,
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'Approval',
  type: 'event',
 },
 {
  anonymous: false,
  inputs: [
   {
    indexed: true,
    internalType: 'address',
    name: 'owner',
    type: 'address',
   },
   {
    indexed: true,
    internalType: 'address',
    name: 'operator',
    type: 'address',
   },
   {
    indexed: false,
    internalType: 'bool',
    name: 'approved',
    type: 'bool',
   },
  ],
  name: 'ApprovalForAll',
  type: 'event',
 },
 {
  anonymous: false,
  inputs: [
   {
    indexed: true,
    internalType: 'address',
    name: 'from',
    type: 'address',
   },
   {
    indexed: true,
    internalType: 'address',
    name: 'to',
    type: 'address',
   },
   {
    indexed: true,
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'Transfer',
  type: 'event',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  name: 'Items',
  outputs: [
   {
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
   },
   {
    internalType: 'address',
    name: 'creator',
    type: 'address',
   },
   {
    internalType: 'string',
    name: 'uri',
    type: 'string',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'to',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'approve',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'owner',
    type: 'address',
   },
  ],
  name: 'balanceOf',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'getApproved',
  outputs: [
   {
    internalType: 'address',
    name: '',
    type: 'address',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'owner',
    type: 'address',
   },
   {
    internalType: 'address',
    name: 'operator',
    type: 'address',
   },
  ],
  name: 'isApprovedForAll',
  outputs: [
   {
    internalType: 'bool',
    name: '',
    type: 'bool',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [],
  name: 'marketplace',
  outputs: [
   {
    internalType: 'address',
    name: '',
    type: 'address',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'string',
    name: 'uri',
    type: 'string',
   },
  ],
  name: 'mint',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'string[]',
    name: 'uri',
    type: 'string[]',
   },
  ],
  name: 'mintMultiple',
  outputs: [
   {
    internalType: 'uint256[]',
    name: '',
    type: 'uint256[]',
   },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [],
  name: 'name',
  outputs: [
   {
    internalType: 'string',
    name: '',
    type: 'string',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'ownerOf',
  outputs: [
   {
    internalType: 'address',
    name: '',
    type: 'address',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'from',
    type: 'address',
   },
   {
    internalType: 'address',
    name: 'to',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'safeTransferFrom',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'from',
    type: 'address',
   },
   {
    internalType: 'address',
    name: 'to',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
   {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes',
   },
  ],
  name: 'safeTransferFrom',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'operator',
    type: 'address',
   },
   {
    internalType: 'bool',
    name: 'approved',
    type: 'bool',
   },
  ],
  name: 'setApprovalForAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'market',
    type: 'address',
   },
  ],
  name: 'setMarketplace',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'bytes4',
    name: 'interfaceId',
    type: 'bytes4',
   },
  ],
  name: 'supportsInterface',
  outputs: [
   {
    internalType: 'bool',
    name: '',
    type: 'bool',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [],
  name: 'symbol',
  outputs: [
   {
    internalType: 'string',
    name: '',
    type: 'string',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'index',
    type: 'uint256',
   },
  ],
  name: 'tokenByIndex',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'owner',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'index',
    type: 'uint256',
   },
  ],
  name: 'tokenOfOwnerByIndex',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'tokenURI',
  outputs: [
   {
    internalType: 'string',
    name: '',
    type: 'string',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [],
  name: 'totalSupply',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'address',
    name: 'from',
    type: 'address',
   },
   {
    internalType: 'address',
    name: 'to',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
  ],
  name: 'transferFrom',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
 },
];
export const abiBuy = [
 {
  inputs: [
   {
    internalType: 'contract ArtToken',
    name: '_token',
    type: 'address',
   },
  ],
  stateMutability: 'nonpayable',
  type: 'constructor',
 },
 {
  anonymous: false,
  inputs: [
   {
    indexed: false,
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
   },
   {
    indexed: false,
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
   {
    indexed: false,
    internalType: 'uint256',
    name: 'price',
    type: 'uint256',
   },
  ],
  name: 'itemAddedForSale',
  type: 'event',
 },
 {
  anonymous: false,
  inputs: [
   {
    indexed: false,
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
   },
   {
    indexed: false,
    internalType: 'address',
    name: 'buyer',
    type: 'address',
   },
   {
    indexed: false,
    internalType: 'uint256',
    name: 'price',
    type: 'uint256',
   },
  ],
  name: 'itemSold',
  type: 'event',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  name: 'activeItems',
  outputs: [
   {
    internalType: 'bool',
    name: '',
    type: 'bool',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
   },
  ],
  name: 'buyItem',
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  name: 'itemsForSale',
  outputs: [
   {
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
   },
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
   {
    internalType: 'address payable',
    name: 'seller',
    type: 'address',
   },
   {
    internalType: 'address payable',
    name: 'contractowner',
    type: 'address',
   },
   {
    internalType: 'uint256',
    name: 'price',
    type: 'uint256',
   },
   {
    internalType: 'bool',
    name: 'isSold',
    type: 'bool',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
 {
  inputs: [
   {
    internalType: 'uint256',
    name: 'tokenId',
    type: 'uint256',
   },
   {
    internalType: 'uint256',
    name: 'price',
    type: 'uint256',
   },
   {
    internalType: 'address',
    name: 'contractowner',
    type: 'address',
   },
  ],
  name: 'putItemForSale',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
 },
 {
  inputs: [],
  name: 'totalItemsForSale',
  outputs: [
   {
    internalType: 'uint256',
    name: '',
    type: 'uint256',
   },
  ],
  stateMutability: 'view',
  type: 'function',
 },
];
