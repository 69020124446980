import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Strings from '../libs/Strings';

function ListItem(props: any) {
 return (
  <div
   className={props.isProfileScreen ? 'panels2' : 'panels2'}
   style={props.userProfileScreen}
  >
   <div className="pricing-item-outer">
    <div
     className={
      props.isProfileScreen
       ? 'pricing-item pricing-item2'
       : 'pricing-item pricing-item2'
     }
    >
     <div className="wraper">
      {props.isShowWalletNft ? (
       <div
        className="panelDiv1 auth__item"
        onClick={() => props.onWalletClick()}
       >
        <div className="panelDiv">
         {props.category === 'Video' ? (
          <>
           <div
           className="playIcon_div2"
           >
            <i className="fa fa-play play-icon1" aria-hidden="true"></i>
           </div>
           <video width="100%" height="100%">
            <source src={props.itemImage} type="video/mp4" />
           </video>
          </>
         ) : (
          <img
           src={
            props.category === 'Music'
             ? 'img/art-work/MusicPlayer.gif'
             : props.itemImage
           }
           className="img-responsive listImage"
           alt="img/authors/2.png"
          />
         )}
        </div>
       </div>
      ) : (
       <Link to={props.navigateToDetailPage} state={props.routesIs}>
        <div className="panelDiv1">
         <div className="panelDiv">
          {props.category === 'Video' ? (
           <>
            <div
            className="playIcon_div2">
             <i className="fa fa-play play-icon1" aria-hidden="true"></i>
            </div>
            <video width="100%" height="100%">
             <source src={props.itemImage} type="video/mp4" />
            </video>
           </>
          ) : (
           <img
            src={
             props.category === 'Music'
              ? 'img/art-work/MusicPlayer.gif'
              : props.itemImage
            }
            className="img-responsive listImage"
            alt="img/authors/2.png"
           />
          )}
         </div>
        </div>
       </Link>
      )}

      <Link to={props.navigateToDetailPage} state={props.routesIs}>
       <h4>{props.itemName}</h4>
      </Link>
      <div className="owner-info">
       <img src={props.ownerImage} alt="img/authors/2.png" />

       <h3>@{props.ownerName}</h3>
      </div>
      <span>
       <span className="g-text">{Strings.ETH}</span> {props.itemPrice}{' '}
       {props.itemBlockChain === 'Ethereum' ? 'ETH' : null}
      </span>
      <div className="admire">
       <div className="adm">
        <i className="fa fa-clock-o"></i>
        {props.itemCreatedTime}
       </div>
       {props.isProfileScreen ? null : (
        <div className="adm addTo-Fav">
         <i
          onClick={props.onIconPress}
          className={props.showLike ? 'fa fa-heart auth__item' : 'fa fa-heart-o auth__item'}
         >
          <span className="addTo-favText">{Strings.AddToFav}.</span>
         </i>
        </div>
       )}
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}

export default ListItem;
