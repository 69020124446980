import { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UserData from '../service/UserData';
import SuccessModal from '../components/SuccessModal';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import Strings from '../libs/Strings';
import PopUp from '../components/PopUp';
import {
 API_SUCCESS_MSG,
 EMAIL_REGEX,
 EMPTY_FIELDS_ERROR_MSG,
 LARGE_NAME_ERROR_MSG,
 VALID_EMAIL_ERROR_MSG,
} from '../libs/Constants';
import { SendMessageToAdmin } from '../service/ApiService';

function ContactUs() {
 const navigate = useNavigate();
 const [UserDetails, setUserDetails] = useState<any>({});
 const [name, setName] = useState<any>('');
 const [email, setEmail] = useState<any>('');
 const [message, setMessage] = useState<any>('');
 const [subject, setSubject] = useState<any>('');
 const [showSuccess, setshowSuccess] = useState(false);
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const [validationMsg, setValidationMsg] = useState('');
 const [createValidItemModal, setCreateValidItemModal] = useState(false);

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserDetails(UserData.getuserDetail());
  } else if (JSON.parse(userProfileDetails) !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
  }
 };

 useEffect(() => {
  getUserprofile();
 }, []);

 const submit = async () => {
  if (!name || !email || !subject || !message) {
   setValidationMsg(EMPTY_FIELDS_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (name.length > 100) {
   setValidationMsg(LARGE_NAME_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (!new RegExp(EMAIL_REGEX).test(email)) {
   setValidationMsg(VALID_EMAIL_ERROR_MSG);
   setCreateValidItemModal(true);
  } else {
   const response = await SendMessageToAdmin(name, email, subject, message);
   if (response && response.msg === API_SUCCESS_MSG) {
    setshowSuccess(true);
   }
  }
 };
 return (
  <div className="App darker">
   <Header userInfo={UserDetails} />
   <div className="breadcumb-area clearfix auto-init contactBreadcumb">
    <div className="breadcumb-content">
     <div className="container h-100">
      <div className="row h-100 align-items-center">
       <div className="col-12">
        <nav aria-label="breadcrumb" className="breadcumb--con text-center">
         <h2 className="w-text title">{Strings.ContactUs}</h2>
         <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item">
           <Link to="/">{Strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
           {Strings.ContactUs}
          </li>
         </ol>
        </nav>
       </div>
      </div>
     </div>
    </div>
   </div>
   <section className="reachUsSection">
    <h3>{Strings.YOUCANREACHTOUS}</h3>
    <div className="container">
     <div className="row contactUsOuter">
      <div className="col-md-4 contactUsArea">
       <img src="img/icons/callIcon.png" alt="" />
       <p>{Strings.ContactNumber}</p>
       <span>{Strings.ContactNo}</span>
      </div>
      <div className="col-md-4 contactUsArea">
       <img src="img/icons/messageIcon.png" alt="" />
       <p>{Strings.Mail}</p>
       <span>{Strings.HelloMintishCom}</span>
      </div>
      <div className="col-md-4 contactUsArea">
       <img src="img/icons/locationIcon.png" alt="" />
       <p>{Strings.Location}</p>
       <span>{Strings.Address}</span>
      </div>
     </div>
    </div>
   </section>
   <section className="section-padding-100 contact_us_area" id="contact">
    <div className="container">
     <div className="row">
      <div className="col-12">
       <div className="section-heading text-center">
        <div
         className="dream-dots justify-content-center fadeInUp"
         data-wow-delay="0.2s"
        >
         <span>{Strings.GetInTouch}</span>
        </div>
        <h2 className="wow fadeInUp d-blue" data-wow-delay="0.3s">
         {Strings.ContactWithUs}
        </h2>
        <p className="wow fadeInUp" data-wow-delay="0.4s"></p>
       </div>
      </div>
     </div>

     <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
       <div className="contact_form">
        <form action="#" method="post" id="main_contact_form">
         <div className="row">
          <div className="col-12">
           <div id="success_fail_info"></div>
          </div>

          <div className="col-12 col-md-6">
           <div className="group wow fadeInUp" data-wow-delay="0.2s">
            <input
             type="text"
             name="name"
             id="name"
             required
             onChange={(evt: any) => {
              setName(evt.target.value);
             }}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{Strings.Name}</label>
           </div>
          </div>
          <div className="col-12 col-md-6">
           <div className="group wow fadeInUp" data-wow-delay="0.3s">
            <input
             type="text"
             name="email"
             id="email"
             required
             onChange={(evt: any) => {
              setEmail(evt.target.value);
             }}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{Strings.Email}</label>
           </div>
          </div>
          <div className="col-12">
           <div className="group wow fadeInUp" data-wow-delay="0.4s">
            <input
             type="text"
             name="subject"
             id="subject"
             required
             onChange={(evt: any) => {
              setSubject(evt.target.value);
             }}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{Strings.Subject}</label>
           </div>
          </div>
          <div className="col-12">
           <div className="group wow fadeInUp" data-wow-delay="0.5s">
            <textarea
             name="message"
             id="message"
             required
             onChange={(evt: any) => {
              setMessage(evt.target.value);
             }}
            ></textarea>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{Strings.Message}</label>
           </div>
          </div>
          <div
           className="col-12 text-center wow fadeInUp"
           data-wow-delay="0.6s"
          >
           <div onClick={() => submit()} className="more-btn">
            {Strings.SendMessage}
           </div>
          </div>
         </div>
        </form>
       </div>
      </div>
     </div>
    </div>
    {showSuccess ? (
     <SuccessModal
      close={() => navigate('/')}
      modalImage={'img/art-work/modalImage.png'}
      ModalText={Strings.ThankYouForSharing}
      buttonText={Strings.EXPLORENFTs}
      navigate={() => navigate('/')}
     />
    ) : null}
    {createValidItemModal ? (
     <PopUp
      message={validationMsg}
      navigate={() => {
       setCreateValidItemModal(false);
      }}
     />
    ) : null}
   </section>
   <Footer />
  </div>
 );
}

export default ContactUs;
