import React from 'react';
import '../App.css';
import Strings from '../libs/Strings';

function TopSellerCard(props: any) {
 return (
  <div className="topSellers">
   <div
    className="creator-sec dd-bg topsellers-info auth__item"
    onClick={() => props.onItemClick()}
   >
    <div className="author-item">
     <div className="author-rank">
      {props.Index >= 9 ? null : 0}
      {props.Index + 1}
     </div>
     <div className="author-img">
      <img
       src={
        props.sellerInfo.imageurl == null
         ? 'img/art-work/user.png'
         : props.sellerInfo.imageurl
       }
       width="70"
       alt=""
      />
     </div>
     <div className="author-info">
      <a>
       <h5 className="author-name">
        {props.sellerInfo.sellername == null
         ? Strings.MintishUser
         : props.sellerInfo.sellername}
       </h5>
      </a>
     </div>
    </div>
   </div>
  </div>
 );
}

export default TopSellerCard;
