import React, { useEffect, useMemo } from 'react';
import HomeScreen from './screens/HomeScreen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ItemDetailScreen from './screens/ItemDetailScreen';
import './App.css';
import WalletConnect from './screens/WalletConnect';
import CreateUserProfile from './screens/CreateUserProfile';
import OurCollectionScreen from './screens/OurCollectionScreen';
import CreatorProfileScreen from './screens/CreatorProfileScreen';
import CreateItemScreen from './screens/CreateItemScreen';
import UerProfileScreen from './screens/UserProfileScreen';
import CreateCollectionScreen from './screens/CreateCollectionScreen';
import MyCollectionScreen from './screens/MyCollectionScreen';
import TopSellersScreen from './screens/TopSellersScreen';
import { useMoralis } from 'react-moralis';
import ContactUs from './screens/ContactUs';
import CreateMultipleItemsScreen from './screens/CreateMultipleItemsScreen';
import ScrollToTop from './helpers/ScrollToTop';
import { checkUser } from './service/ApiService';
import {
 PROFILE_PAGE,
 ITEM_DETAIL_PAGE,
 WALLET_CONNECT,
 CREATE_USER_PROFILE,
 DISCOVER_PAGE,
 CREATOR_PAGE,
 CREATE_ITEM_SCREEN,
 CONTACT_US,
 CREATE_COLLECTION_SCREEN,
 MY_COLLECTION_SCREEN,
 TOP_SELLERS_SCREEN,
 CREATOR_PROFILE_SCREEN,
 CREATE_MULTIPLE_ITEMS_SCREEN,
 CONTRACT_GENERATION,
} from './libs/Constants';
import ContractGeneration from './screens/ContractGeneration';
function App() {
 const {
  isWeb3Enabled,
  enableWeb3,
  isAuthenticated,
  isWeb3EnableLoading,
  logout,
 } = useMoralis();

 const check = async () => {
  console.log('in login Screen');
  const res = await checkUser(localStorage.getItem('userId'));
  if (!res) {
   await logout();
   localStorage.clear();
   setTimeout(() => {
    window.location.reload();
   }, 3000);
  }
 };
 useMemo(() => {
  if (localStorage.getItem('userId')) check();
 }, []);
 useEffect(() => {
  if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
   enableWeb3();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isAuthenticated, isWeb3Enabled]);
 return (
  <Router>
   <ScrollToTop>
    <Routes>
     <Route path="/" element={<HomeScreen />} />
     <Route path={DISCOVER_PAGE} element={<OurCollectionScreen />} />
     <Route path={ITEM_DETAIL_PAGE} element={<ItemDetailScreen />} />
     <Route path={WALLET_CONNECT} element={<WalletConnect />} />
     <Route path={CREATE_USER_PROFILE} element={<CreateUserProfile />} />
     <Route path={CREATE_ITEM_SCREEN} element={<CreateItemScreen />} />
     <Route path={PROFILE_PAGE} element={<UerProfileScreen />} />
     <Route path={CONTACT_US} element={<ContactUs />} />
     <Route path={CONTRACT_GENERATION} element={<ContractGeneration />} />

     <Route
      path={CREATE_COLLECTION_SCREEN}
      element={<CreateCollectionScreen />}
     />
     <Route
      path={CREATE_MULTIPLE_ITEMS_SCREEN}
      element={<CreateMultipleItemsScreen />}
     />
     <Route path={CREATOR_PROFILE_SCREEN} element={<CreatorProfileScreen />} />
     <Route path={MY_COLLECTION_SCREEN} element={<MyCollectionScreen />} />
     <Route path={TOP_SELLERS_SCREEN} element={<TopSellersScreen />} />
    </Routes>
   </ScrollToTop>
  </Router>
 );
}
export default App;
