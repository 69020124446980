// eslint-disable-next-line import/no-anonymous-default-export
export default {
 MusicNft: 'Mint, sell and buy with Music NFTs',
 MarketPlaceText: 'First dedicated Music NFT Market place',
 SupportsMulti: 'Supports Multi Mint NFT',
 EXPLORENFTs: 'EXPLORE NFTs',
 CREATE: 'CREATE',
 TopSellers: 'Top Sellers This Month',
 TopSeller: 'Top Sellers',
 EXPLOREMORE: 'EXPLORE MORE',
 NODATAFOUND: 'NO DATA FOUND',
 CreativeCreators: 'Creative Creators',
 DicoverNewItems: 'Dicover New Items',
 NewListedItems: 'New Listed Items',
 OurTopCollection: 'Our Top Collection',
 ClassifiedNFTs: 'Classified NFTs',
 ShowMore: 'Show More',
 PopularCollections: 'Popular Collections',
 ItemDetails: 'Item Details',
 Home: 'Home',
 MAKEOFFER: 'MAKE OFFER',
 EnterAmount: 'Enter Amount (in ETH)',
 MoreRelevant: 'More Relevant',
 BuyNow: 'Buy Now',
 PlaceBid: 'Place Bid',
 SellNow: 'Sell Now',
 PleasecontactAdmin: 'Please contact Admin : admin123@gmail.com.',
 ItemOwner: 'Item Owner',
 Blockchain: 'Blockchain :',
 ETH: 'ETH',
 TokenStandard: 'Token Standard :',
 ERC: 'ERC-721',
 TokenID: 'Token ID :',
 ContractAddress: 'Contract Address',
 NoCollectionFound: 'No Collection Found',
 Collection: 'Collection :',
 Price: 'Price :',
 CreatedBy: 'Created By :',
 UploadNFT: 'Upload NFT',
 Upload: 'Upload',
 Requiredfields: 'Required fields',
 UploadItemFile: 'Upload Item File',
 supportedFile: 'JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV(Max: 100 MB)',
 Itemname: 'Item name',
 ChooseItemCategory: 'Choose item Category',
 Image: 'Image',
 Music: 'Music',
 Video: 'Video',
 Tours: 'Tours',
 ItemDescription: 'Item Description',
 ItemPriceInETH: 'Item Price in ETH',
 Youritemwillappearhere: '(Your item will appear here)',
 CreateNFT: 'Create NFT',
 UploadNFTs: 'Upload NFTs',
 WelcometoTheWorld: 'Welcome to the world of NFTs ',
 UploadText: 'Upload Image, Video, or Audio',
 Previous: 'Previous',
 SaveItemNext: 'Save item next',
 UPLOADNFTs: 'UPLOAD NFTs',
 Volume: 'Volume',
 Amount: 'Amount',
 LoadMore: 'Load More',
 ContactUs: 'Contact Us',
 YOUCANREACHTOUS: 'YOU CAN REACH TO US',
 ContactNumber: 'Contact Number',
 ContactNo: '787-598-4934',
 Mail: 'Mail',
 HelloMintishCom: 'Hello@mintish.com',
 Location: 'Location',
 Address: 'Av. El Caño, Guaynabo, 00968, Puerto Rico',
 GetInTouch: 'Get in Touch',
 ContactWithUs: 'Contact With Us',
 Name: 'Name',
 Email: 'Email',
 Subject: 'Subject',
 Message: 'Message',
 SendMessage: 'Send Message',
 CreateCollection: 'Create Collection',
 UploadImage: 'Upload Image',
 SupportedCollectionImage: 'Supported Image File : Max Size 20MB',
 CollectionName: 'Collection name',
 CollectionDescription: 'Collection Description',
 upto300character: '(upto 300 character)',
 Create: 'Create',
 CreateYourProfile: 'Create Your Profile',
 CreateProfile: 'Create Profile',
 YourItemPreview: 'Your Item Preview',
 Title: 'Title',
 Confirm: 'Confirm',
 Back: 'Back',
 Next: 'Next',
 HiWelcomeToYourProfile: 'Hi ,Welcome to your profile',
 EditYourDetailsBelow: 'Edit your details below',
 FirstName: 'First Name',
 LastName: 'Last Name',
 TellUsAboutYourself: 'Tell us about yourself (upto 80 characters)',
 EmailAddress: 'Email Address',
 WalletAddress: 'Wallet Address',
 Gender: 'Gender',
 Country: 'Country',
 City: 'City',
 ZipCode: 'Zip Code',
 YourFacebookPage: 'Your Facebook Page',
 YourTwitterPage: 'Your Twitter Page',
 YourInstagramPage: 'Your Instagram Page',
 Save: 'Save',
 CreatorProfile: 'Creator Profile',
 MyCollections: 'MyCollections',
 Collections: 'Collections',
 BackCollection: 'Back Collection',
 ExploreAllNFTs: 'Explore All NFTs',
 APPLYFILTER: 'APPLY FILTER',
 Filter: 'Filter',
 SortBy: 'Sort By',
 HighToLow: 'Price: High to Low',
 LowToHigh: 'Price: Low to High',
 AuthorProfile: 'Author Profile',
 Edit: 'Edit',
 Created: 'Created',
 Purchased: 'Purchased',
 Mintish: 'Mintish',
 Wallet: 'Wallet',
 ConnectWallet: 'Connect Wallet',
 ConnectYourWallet: 'Connect Your Wallet',
 ConnectYourWalletToStart:
  'Connect Your Wallet to Start collecting, Buying and Selling NFTs.',
 LogOut: 'Log Out',
 ConnectwithCoinbase: 'Connect with Coinbase',
 ConnectwithMetaMask: 'Connect with MetaMask',
 Iswearitsalmostdone: "I swear it's almost done.",
 Chooseoneofthe: 'Choose one of the following options',
 SelectTheRelevant:
  'Select the relevant option: uploading single or multiple NFTs',
 SingleNFT: 'Single NFT',
 MultipleNFTs: 'Multiple NFTs',
 Owner: 'Owner',
 LetGo: "Let's Go",
 ErrorCode: '404',
 NothingWasFound: 'Oops! Nothing was found',
 ThePageYouAreLooking:
  ' The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
 Reload: 'Reload',
 AboutUs: 'About Us',
 Newsletter: 'Newsletter',
 MintishIsTheWorld:
  "Mintish is the world's first and only dedicated music NFT marketplace for music lovers.",
 StayUpdated: 'Stay Updated with our latest news. We promise not to spam',
 CopyrightText: 'Copyright Mintish©. All Rights Reserved 2022-23',
 Explore: 'Explore',
 AllNFTs: 'All NFTs',
 Hi: 'Hi',
 MyProfile: 'My Profile',
 MyCollection: 'My Collection',
 Favourites: 'Favourites',
 Logout: 'Logout',
 AddToFav: 'Add to Fav',
 OK: 'OK',
 ThankYouForSharing:
  'Thank you for sharing the details with us.We shall be in touch with you at earliest!',
 Thankyou: 'Thank you for sharing the details',
 Copied: 'Copied',
 Uploadfile: 'Upload file',
 Male: 'Male',
 Female: 'Female',
 other: 'other',
 SelectYourCountry: 'Select your Country',
 MintishUser: 'Mintish User',
 ThnaksWillKeepUpdated: 'Thanks, will keep you updated',
 Select: 'Select',
 SelectAnyCollection: 'Select any collection',
 MyNFT: "My NFT's",
 IMAGE: 'IMAGE',
 TOURS: 'TOURS',
 VIDEO: 'VIDEO',
 MUSIC: 'MUSIC',
 nftCreated: 'nft Created',
 Processing: 'Processing',
 Item: 'Item ',
 updatedSuccessfully: ' updated successfully',
 savedSuccessfully: ' saved successfully',
};
