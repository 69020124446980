import ReactAudioPlayer from 'react-audio-player';
import Strings from '../libs/Strings';
import '../App.css';

function ItemPreviewModal(props: any) {
 return (
  <div className="modalOverlay">
   <div className="itemPreviewModal">
    <span
     className="crossBtnOuter closeIconOuter"
     onClick={() => props.onCrossButtonClick()}
    >
     <img src="img/art-work/crossBtn.png" alt="" />
    </span>
    <h3>{Strings.YourItemPreview}</h3>
    <div className="container">
     <div className="col-md-12 borderBottom"></div>
    </div>
    <div className="row align-items-center">
     <div className="container">
      <div className="detailed-img2">
       <div className="image-view">
        {props.Category === 'Music' ? (
         <div
          className="detailImg"
          style={{
           display: 'flex',
           alignItems: 'flex-end',
           backgroundImage: 'url(../img/art-work/music.png)',
          }}
         >
          <ReactAudioPlayer
           style={{ width: '100%' }}
           src={props.source}
           controls
           controlsList="nodownload"
          />
         </div>
        ) : props.Category === 'Video' ? (
         <video
          className="itemPreviewModalImage"
          width="100%"
          height="100%"
          controls
          controlsList="nodownload"
         >
          <source src={props.source} type="video/mp4" />
         </video>
        ) : (
         <img src={props.source} alt="" className="itemPreviewModalImage" />
        )}
       </div>
      </div>
      <div className="image-Details">
       <div className="textView">
        <h3>
         {Strings.Title}
         <span>
          <h5 className="descriptionText">{props.ItemName}</h5>
         </span>
        </h3>
        <h3>
         {Strings.ItemDescription}
         <span>
          <div className="descriptionText_div">
           <h5 className="descriptionText">{props.ItemDescription}</h5>
          </div>
         </span>
        </h3>
        <h3>
         {Strings.Price}
         <span>
          <h5 className="descriptionText">{props.ItemPrice}</h5>
         </span>
        </h3>
       </div>
      </div>
     </div>
     {props.isMultipleItems ? (
      <div className="col-12 text-center backButton_div">
       <div>
        <button
         className={
          props.ModalCount > 0
           ? 'more-btn more_btn backNextBtn'
           : 'more-btn more_btn backNextBtn btn disabled'
         }
         onClick={() => props.showBackPress()}
        >
         {Strings.Back}
        </button>
       </div>
       <div className="pagging-Div">
        <div>
         <p className="pagging">
          {props.ModalCount + 1}/{props.ArrayLenght}
         </p>
        </div>
       </div>
       <div>
        <button
         className={
          props.ModalCount < props.ArrayLenght - 1
           ? 'more-btn more_btn backNextBtn'
           : 'more-btn more_btn backNextBtn btn disabled'
         }
         onClick={() => props.onNextPress()}
        >
         {Strings.Next}
        </button>
       </div>
      </div>
     ) : null}
     <div className="col-12 text-center">
      <button
       className="more-btn mb-15 more_btn"
       onClick={() => props.onConfirm()}
      >
       {Strings.Confirm}
      </button>
     </div>
    </div>
   </div>
  </div>
 );
}

export default ItemPreviewModal;
