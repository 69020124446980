class UserData {
 userDetail: any = {};
 userNft: any = {};
 userLoginInfo: any = {};
 allNft: any = [];
 userId: any;
 userToken: any;
 musicNft: any = [];
 imageNft: any = [];
 videoNft: any = [];
 toursNft: any = [];
 userCollection: any = [];
 userAllNfts: any = [];
 userFavourites: any = [];
 userPurchased: any = [];
 userSold: any = [];
 PopularCollections: any = [];
 constructor() {
  this.userDetail = {};
 }
 updatePopularCollections(value: any) {
  this.PopularCollections = value;
 }
 getPopularCollection() {
  return this.PopularCollections;
 }
 updateUserDetail(value: any) {
  this.userDetail = value;
 }
 updateUserPurchased(value: any) {
  this.userPurchased = value;
 }
 updateUserSold(value: any) {
  this.userSold = value;
 }
 updateUserNft(value: any) {
  this.userNft = value;
 }
 updateUserFavourites(value: any) {
  this.userFavourites = value;
 }
 updateAllNft(value: any) {
  this.allNft = value;
 }
 updateMusicNft(value: any) {
  this.musicNft = value;
 }
 updateUserAllNfts(value: any) {
  this.userAllNfts = value;
 }
 updateImageNft(value: any) {
  this.imageNft = value;
 }
 updateUserCollection(value: any) {
  this.userCollection = value;
 }
 updateVideoNft(value: any) {
  this.videoNft = value;
 }
 updateToursNft(value: any) {
  this.toursNft = value;
 }
 updateUserLoginInfo(value: any) {
  this.userLoginInfo = value;
 }
 getuserDetail() {
  return this.userDetail;
 }
 getUserSold() {
  return this.userSold;
 }
 getUserFavourites() {
  return this.userFavourites;
 }
 getAllNft() {
  return this.allNft;
 }
 getUserCollection() {
  return this.userCollection;
 }
 getMusicNft() {
  return this.musicNft;
 }
 getVideoNft() {
  return this.videoNft;
 }
 getImageNft() {
  return this.imageNft;
 }
 getToursNft() {
  return this.toursNft;
 }
 getUserPurchased() {
  return this.userPurchased;
 }
 getuserLoginInfo() {
  return this.userLoginInfo;
 }
 getuserNFt() {
  return this.userNft;
 }
 getUserAllNFt() {
  return this.userAllNfts;
 }

 setUserId(value: any) {
  this.userId = value;
 }
 getUserId() {
  return this.userId;
 }

 setUserToken(value: any) {
  this.userToken = value;
 }
 getUserToken() {
  return this.userToken;
 }
}

export default new UserData();
