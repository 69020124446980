import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useMoralis } from 'react-moralis';
import {
 createNFTByToken,
 getUserCollectionNameByToken,
} from '../service/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import {
 PROFILE_PAGE,
 DESCRIPTION_MAX_SIZE,
 LONG_DESCRIPTION_ERROR_MSG,
 ITEM_NAME_MAX_SIZE,
 CREATE_ABI,
 CREATE_ITEM,
 EMPTY_FIELDS_ERROR_MSG,
 LARGE_ITEM_NAME,
 VALID_AMOUNT_ERR_MSG,
 LARGE_ITEM_IMAGE_ERROR_MSG,
 IMAGE_EXTENSION_ERROR_MSG,
 VALID_MUSIC_FILE_ERROR_MSG,
 VALID_VIDEO_FILE_ERROR_MSG,
 MORALIS_ERROR_CODE,
 USER_DENIED_TRANSACTION,
 BLOCKS_ERROR,
 FILENAME_CONTAINS_INVALID_CHARACTERS,
 UNSUCCESSFUL_TRANSACTION,
 LATIN_RANGE_ERROR_MSG,
 LESS_ITEM_ERROR_MSG,
} from '../libs/Constants';
import UserData from '../service/UserData';
import Footer from '../components/Footer';
import { abiCreate } from '../libs/Contracts';
import PopUp from '../components/PopUp';
import '../App.css';
import UserProfile from '../components/UserProfile';
import Strings from '../libs/Strings';
import ItemPreviewModal from '../components/ItemPreviewModal';

function CreateMultipleItemsScreen() {
 const navigate = useNavigate();
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [loader, setLoader] = useState(false);
 const [UserDetails, setUserDetails] = useState<any>({});
 const [currentItemObject, setCurrentItemObject] = useState<any>({
  imageUri: null,
  itemName: null,
  description: null,
  itemPrice: null,
  category: null,
  imageName: null,
  imageSize: null,
  collectionId: null,
  collectionName: null,
  uploadedFile: null,
 });
 const [userNFT, setUserNFT] = useState<any>({});
 const [completeItmesArray, setCompleteItmesArray] = useState<any>([]);
 const [showCreateItemModal, setShowCreateItemModal] = useState(false);
 const [userCollectionName, setUserCollectionName] = useState<any>([]);
 const { account, Moralis, web3 } = useMoralis();
 const [collectionID, setCollectionID] = useState(null);
 const [isNavigateProfile, setIsNavigateProfile] = useState(false);
 const [isCopy, setIsCopy] = useState(false);
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const useNftDetail: any = localStorage.getItem('userNFts');
 const [showItemOnModal, setShowItemOnModal] = useState<any>({});
 const [count, setCount] = useState<number>(0);
 const [modalCount, setModalCount] = useState<number>(0);
 let emptyObject = {
  imageUri: null,
  itemName: null,
  description: null,
  itemPrice: null,
  category: null,
  imageName: null,
  imageSize: null,
  collectionId: null,
  collectionName: null,
  uploadedFile: null,
 };

 const onDropDownClick = async (id: any, token: any) => {
  const response = await getUserCollectionNameByToken(id, token);
  if (response) {
   setUserCollectionName(response);
   setCurrentItemObject((prev: any) => {
    prev = { ...prev, collectionId: response[0].collection_id };
    return prev;
   });
   setCollectionID(response[0].collection_id);
  }
  return null;
 };

 const setUploadImage = () => {
  const fileInput = document.getElementById('file');
  const datafile = (fileInput as any).files[0];
  var imageUrl = URL.createObjectURL(datafile);
  setCurrentItemObject((prev: any) => {
   prev = {
    ...prev,
    imageName: datafile.name,
    imageSize: datafile.size,
    imageUri: imageUrl,
    uploadedFile: datafile,
   };
   return prev;
  });
  (document.getElementById('file') as any).value = '';
 };

 const pushObjectInArray = () => {
  if (count < 5) {
   let array = [...completeItmesArray];
   if (array[count]) {
    let obj = { ...currentItemObject };
    obj.collectionId = obj.collectionId ? obj.collectionId : collectionID;
    array[count] = obj;
    setCompleteItmesArray(array);
    setCount(count + 1);
    if (count == 4) {
     setCurrentItemObject(array[count]);
    } else {
     setCurrentItemObject(array[count + 1] ? array[count + 1] : emptyObject);
    }
    setValidationMsg(Strings.Item + (count + 1) + Strings.updatedSuccessfully);
    setCreateValidItemModal(true);
   } else {
    let obj = { ...currentItemObject };
    obj.collectionId = obj.collectionId ? obj.collectionId : collectionID;
    array.push(obj);
    setCompleteItmesArray(array);
    if (count + 1 < 5) {
     setCurrentItemObject(emptyObject);
     setCount(count + 1);
    }
    setValidationMsg(Strings.Item + (count + 1) + Strings.savedSuccessfully);
    setCreateValidItemModal(true);
   }
  }
 };
 const onCrossButtonClick = () => {
  setShowCreateItemModal(false);
  setModalCount(0);
 };
 const showValidateModal = () => {
  if (
   currentItemObject.category === null ||
   currentItemObject.itemName === null ||
   currentItemObject.description === null ||
   currentItemObject.imageName === null ||
   currentItemObject.itemPrice === 0
  ) {
   setValidationMsg(EMPTY_FIELDS_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (currentItemObject.itemName.length > ITEM_NAME_MAX_SIZE) {
   setValidationMsg(LARGE_ITEM_NAME);
   setCreateValidItemModal(true);
  } else if (currentItemObject.itemPrice <= 0) {
   setValidationMsg(VALID_AMOUNT_ERR_MSG);
   setCreateValidItemModal(true);
  } else if (currentItemObject.imageSize >= 104857600) {
   setValidationMsg(LARGE_ITEM_IMAGE_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (currentItemObject.description.length > DESCRIPTION_MAX_SIZE) {
   setValidationMsg(LONG_DESCRIPTION_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (
   currentItemObject.category.toUpperCase() === Strings.IMAGE ||
   currentItemObject.category.toUpperCase() === Strings.TOURS
  ) {
   let ext = currentItemObject.imageName.split('.')[1].toUpperCase();
   if (
    ext === 'JPG' ||
    ext === 'PNG' ||
    ext === 'JPEG' ||
    ext === 'GIF' ||
    ext === 'SVG'
   ) {
    pushObjectInArray();
   } else {
    setValidationMsg(IMAGE_EXTENSION_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else if (currentItemObject.category.toUpperCase() === Strings.MUSIC) {
   let ext = currentItemObject.imageName.split('.')[1].toUpperCase();
   if (ext === 'MP3' || ext === 'WAV') {
    pushObjectInArray();
   } else {
    setValidationMsg(VALID_MUSIC_FILE_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else if (currentItemObject.category.toUpperCase() === Strings.VIDEO) {
   let ext = currentItemObject.imageName.split('.')[1].toUpperCase();
   if (ext === 'WEBM' || ext === 'MP4') {
    pushObjectInArray();
   } else {
    setValidationMsg(VALID_VIDEO_FILE_ERROR_MSG);
    setCreateValidItemModal(true);
   }
  } else {
   pushObjectInArray();
  }
 };
 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0 &&
   UserData.getuserDetail() != null
  ) {
   setUserDetails(UserData.getuserDetail());
   setUserNFT(UserData.getuserNFt());
  } else if (userProfileDetails !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
   setUserNFT(JSON.parse(useNftDetail));
  } else {
   navigate('/');
  }
 };

 useEffect(() => {
  getUserprofile();
  onDropDownClick(userLoginDetail.userId, userLoginDetail.userToken);
 }, []);

 async function mintToken(_uri: any) {
  const contract = new (web3 as any).eth.Contract(abiCreate, CREATE_ABI);
  const receipt1 = await contract.methods
   .mintMultiple(_uri)
   .send({ from: window.ethereum.selectedAddress });
  let tokenArray: any = [];
  const gasFee =
   (receipt1.effectiveGasPrice * receipt1.gasUsed) /
   (1000000000000000000 * receipt1.events.Transfer.length);
  const gasFeee = gasFee.toFixed(6);
  receipt1.events.Transfer.forEach((element: any, index: any) => {
   tokenArray.push({
    tokenId: element.returnValues.tokenId,
    gasFeee: gasFeee,
   });
  });

  return tokenArray;
 }
 const createNFT = async (
  nftData: any,
  index: any,
  arrayLength: any,
  isNavigate: boolean
 ) => {
  try {
   const response = await createNFTByToken(nftData, userLoginDetail.userToken);
   if (index >= arrayLength) {
    setLoader(false);
   }
   if (isNavigate) {
    navigate(PROFILE_PAGE);
   }
  } catch (error) {
   setLoader(false);
  }
 };
 const checkNavigate = () => {
  if (isNavigateProfile) {
   navigate(PROFILE_PAGE);
  }
 };

 const getTokenIdByMint = async (
  metadataURI: any,
  imageUri: any,
  data: any
 ) => {
  try {
   const tokenId = await mintToken(metadataURI);

   data.forEach((element: any, index: any) => {
    const tokenIndex = metadataURI.findIndex(
     (item: any) => item === element.metadataURIData
    );
    let nftData: any = {
     Id: userLoginDetail.userId,
     Category: element.category,
     Name: element.itemName,
     Description: element.description,
     ExternalLink: element.metadataURIData,
     Amount: element.itemPrice,
     TokenPath: element.imageURIData,
     ContractAddress: CREATE_ABI,
     TokenId: tokenId[tokenIndex].tokenId,
     CreatedGasfee: tokenId[index].gasFeee,
     CollectionId: element.collectionId,
     Status: Strings.nftCreated,
    };
    createNFT(nftData, index, data.length, true);
   });
  } catch (error: unknown) {
   setLoader(false);
   console.log('error ', error);
   if (JSON.stringify((error as any).code) == MORALIS_ERROR_CODE) {
    setValidationMsg(USER_DENIED_TRANSACTION);
    setCreateValidItemModal(true);
   } else if (error == BLOCKS_ERROR) {
    setIsNavigateProfile(true);
    data.forEach((element: any, index: any) => {
     let nftData: any = {
      Id: userLoginDetail.userId,
      Category: element.category,
      Name: element.itemName,
      Description: element.description,
      ExternalLink: element.metadataURIData,
      Amount: element.itemPrice,
      TokenPath: element.imageURIData,
      ContractAddress: CREATE_ABI,
      CreatedGasfee: '0',
      TokenId: '0',
      CollectionId: element.collectionId,
      Status: Strings.Processing,
     };
     createNFT(nftData, index, data.length, false);
    });
    setValidationMsg(UNSUCCESSFUL_TRANSACTION);
    setCreateValidItemModal(true);
   } else {
    alert(error);
   }
  }
 };

 const minting = async () => {
  setLoader(true);
  let metaDataUri: any = [];
  let imageUriData: any = [];
  completeItmesArray.map(async (element: any) => {
   try {
    const fileM = new Moralis.File(
     element.uploadedFile.name,
     element.uploadedFile
    );
    await fileM.saveIPFS();
    const imageURI = (fileM as any).ipfs();
    const metadata = {
     name: element.itemName,
     description: element.description,
     image: imageURI,
    };
    const metadataFile = new Moralis.File('metadata.json', {
     base64: btoa(JSON.stringify(metadata)),
    });
    await metadataFile.saveIPFS();
    const metadataURI = (metadataFile as any).ipfs();
    element.metadataURIData = metadataURI;
    element.imageURIData = imageURI;
    metaDataUri.push(metadataURI);
    imageUriData.push(imageURI);
   } catch (error: any) {
    setLoader(false);
    console.log('error is', error);
    if (
     JSON.stringify((error as any).message) ==
     '"Filename contains invalid characters."'
    ) {
     setValidationMsg(FILENAME_CONTAINS_INVALID_CHARACTERS);
     setCreateValidItemModal(true);
    } else if (JSON.stringify((error as any).stack)) {
     setValidationMsg(LATIN_RANGE_ERROR_MSG);
     setCreateValidItemModal(true);
    } else {
     alert(error);
    }
   }
   if (metaDataUri.length === completeItmesArray.length) {
    setLoader(true);
    getTokenIdByMint(metaDataUri, imageUriData, completeItmesArray);
   }
  });
  setCompleteItmesArray(completeItmesArray);
 };
 const showPreviousItem = () => {
  let data = [...completeItmesArray];
  let previousItem = data[count - 1];
  setCount(count - 1);
  setCurrentItemObject(previousItem);
 };

 const showBackPress = () => {
  if (modalCount > 0) {
   let data = [...completeItmesArray];
   let previousItem = data[modalCount - 1];
   var videoElement = document.getElementById('videoTag') as any;
   if (previousItem.category == 'Video' && videoElement) {
    videoElement.pause();
    videoElement.removeAttribute('src');
    videoElement.load();
   }
   setShowItemOnModal(previousItem);
   setModalCount(modalCount - 1);
  }
 };

 const onNextPress = () => {
  if (modalCount < completeItmesArray.length - 1) {
   let data = [...completeItmesArray];
   let previousItem = data[modalCount + 1];
   var videoElement = document.getElementById('videoTag') as any;
   if (previousItem.category == 'Video' && videoElement) {
    videoElement.pause();
    videoElement.removeAttribute('src');
    videoElement.load();
   }
   setShowItemOnModal(previousItem);
   setModalCount(modalCount + 1);
  }
 };

 const onDropDownValueChange = (value: any) => {
  setCurrentItemObject((prev: any) => {
   prev = {
    ...prev,
    collectionId: value,
   };
   return prev;
  });
  setCollectionID(value);
 };

 const onUploadItemClick = () => {
  if (completeItmesArray.length == 1) {
   setValidationMsg(LESS_ITEM_ERROR_MSG);
   setCreateValidItemModal(true);
  } else {
   setShowCreateItemModal(true);
   let data = [...completeItmesArray];
   let currentItem = data[0];
   setShowItemOnModal(currentItem);
  }
 };

 return (
  <div className="App darker">
   {loader ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}

   <Header userInfo={UserDetails} />
   <div className="breadcumb-area clearfix auto-init collectionBreadcumb">
    <div className="breadcumb-content">
     <div className="container h-100">
      <div className="row h-100 align-items-center">
       <div className="col-12">
        <nav aria-label="breadcrumb" className="breadcumb--con text-center">
         <h2 className="w-text title">{Strings.UploadNFTs}</h2>
         <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item">
           <Link to="/">{Strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
           {Strings.UploadNFTs}
          </li>
         </ol>
        </nav>
       </div>
      </div>
     </div>
    </div>
   </div>
   <section className="blog-area section-padding-100">
    <div className="container">
     <div className="row">
      <UserProfile
       UserDetails={UserDetails}
       userNFTdetail={userNFT}
       Account={account}
       onCopy={() => setIsCopy(true)}
      />
      <div className="col-12 col-lg-8">
       <div className="creator-sec dd-bg">
        <div className="who-we-contant">
         <div className="dream-dots text-left" data-wow-delay="0.2s">
          <span className="gradient-text">{Strings.WelcometoTheWorld}</span>
         </div>
         <h4 className="uploadText t3">{Strings.UploadText} </h4>
         <p className="requireFields">
          <span className="star">*</span>
          {Strings.Requiredfields}
         </p>
         <p className="w-text uploadItem">{Strings.UploadItemFile}</p>
        </div>
        <div className="contact_form">
         <div className="row">
          <div className="col-12">
           <div id="success_fail_info"></div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group-file">
            <p className="g-text">{Strings.supportedFile}</p>
            <span className="file-wrapper new_Btn more-btn upload_Button">
             <input
              type="file"
              name="upload"
              id="file"
              onChange={(e) => setUploadImage()}
             />
             <span className="button1">
              {' '}
              {currentItemObject.imageName === null
               ? 'UPLOAD FILE'
               : currentItemObject.imageName}
             </span>
            </span>
           </div>
          </div>
         </div>
        </div>
       </div>

       <div className="contact_form contact-form2">
        <div className="row">
         <div className="col-12">
          <div className="create_multiItems">
           <p className="pagging">{count < 5 ? count + 1 : 5}/5</p>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <input
             type="text"
             value={
              currentItemObject.itemName == null
               ? ''
               : currentItemObject.itemName
             }
             onChange={(e) =>
              setCurrentItemObject((prev: any) => {
               prev = { ...prev, itemName: e.target.value };
               return prev;
              })
             }
             required
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.Itemname}
             <span className="star">*</span>
            </label>
           </div>
          </div>

          <div className="col-12 col-md-12">
           <div className="mb-15 mb-15-2">
            <p>{Strings.ChooseItemCategory}</p>
            <div className="filers-list">
             {CREATE_ITEM.map((item: any, index: any) => {
              return (
               <div
                className={
                 currentItemObject.category === item.type
                  ? 'filter-item filter filterItem fillerItem4'
                  : 'filter-item filterItem fillerItem4'
                }
                onClick={() =>
                 setCurrentItemObject((prev: any) => {
                  prev = { ...prev, category: item.type };
                  return prev;
                 })
                }
               >
                {item.type}
               </div>
              );
             })}
            </div>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <textarea
             name="Description"
             value={
              currentItemObject.description == null
               ? ''
               : currentItemObject.description
             }
             onChange={(e: any) => {
              setCurrentItemObject((prev: any) => {
               prev = { ...prev, description: e.target.value };
               return prev;
              });
             }}
             required
            ></textarea>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.ItemDescription}
             <span className="star">*</span>
            </label>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <input
             type="number"
             value={
              currentItemObject.itemPrice === null
               ? ''
               : currentItemObject.itemPrice
             }
             onChange={(evt: any) => {
              setCurrentItemObject((prev: any) => {
               prev = { ...prev, itemPrice: evt.target.value };
               return prev;
              });
             }}
             required
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.ItemPriceInETH}
             <span className="star">*</span>
            </label>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group collection-section1">
            <div className="collection__sec1">
             <p>
              {Strings.Collection}{' '}
              <span className="appearText">
               {Strings.Youritemwillappearhere}
              </span>
             </p>
            </div>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group collection-section">
            <div className="collection__sec">
             <select
              className="inputClass seletct_sec"
              value={currentItemObject.collectionId}
              onChange={(evt: any) => onDropDownValueChange(evt.target.value)}
             >
              {userCollectionName.map((x: any, y: any) => (
               <option key={x.collection_id} value={x.collection_id}>
                {x.collection_name}
               </option>
              ))}
             </select>
            </div>
           </div>
          </div>
          <div className="col-12 text-center button_Div">
           <div className="button_Div2">
            <div
             className={
              count < 1 ? 'more-btn mb-15 btn disabled' : 'more-btn mb-15'
             }
             onClick={() => showPreviousItem()}
            >
             {Strings.Previous}
            </div>
           </div>
           <div>
            <button
             className="more-btn mb-15"
             onClick={() => showValidateModal()}
            >
             {Strings.SaveItemNext}
            </button>
           </div>
          </div>
          {showCreateItemModal ? (
           <ItemPreviewModal
            onCrossButtonClick={() => onCrossButtonClick()}
            Category={showItemOnModal.category}
            source={showItemOnModal.imageUri}
            ItemName={showItemOnModal.itemName}
            ItemDescription={showItemOnModal.description}
            ItemPrice={showItemOnModal.itemPrice}
            ModalCount={modalCount}
            isMultipleItems={true}
            onConfirm={() => minting()}
            ArrayLenght={completeItmesArray.length}
            onNextPress={() => onNextPress()}
            showBackPress={() => showBackPress()}
           />
          ) : null}
         </div>
        </div>
       </div>
      </div>
      <div className="col-12 col-lg-3"></div>
      <div className="col-12 col-lg-8 text-center button_Div">
       <div>
        <button
         className={
          completeItmesArray.length > 0
           ? 'more-btn mb-15'
           : 'more-btn mb-15 btn disabled'
         }
         onClick={() => onUploadItemClick()}
        >
         {Strings.UPLOADNFTs}
        </button>
       </div>
      </div>
     </div>
    </div>
    {createValidItemModal ? (
     <PopUp
      message={validationMsg}
      navigate={() => {
       setShowCreateItemModal(false);
       setCreateValidItemModal(false);
       checkNavigate();
      }}
     />
    ) : null}
    {isCopy ? (
     <PopUp
      message={Strings.Copied}
      navigate={() => {
       setIsCopy(false);
      }}
     />
    ) : null}
   </section>
   <Footer />
  </div>
 );
}

export default CreateMultipleItemsScreen;
