import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useMoralis } from 'react-moralis';
import { getEllipsisTxt } from '../helpers/formatters';
import {
 API_ERROR_MSG,
 API_SUCCESS_MSG,
 DEFAULT_INDEX,
 EMAIL_REGEX,
 FACEBOOK_REGEX,
 INSTAGRAM_REGEX,
 LARGE_LAST_NAME_ERROR_MSG,
 LARGE_NAME_ERROR_MSG,
 LONG_DESCRIPTION_MSG,
 LONG_EMAIL_MSG,
 MANDATORY_FEILDS_ERROR_MSG,
 MAX_FACEBOOK_LENGHT,
 MAX_INSTAGRAM_LENGHT,
 MAX_TWITTER_LENGHT,
 MAX_ZIP_CODE,
 PROFILE_PAGE,
 TWITTER_REGEX,
 VALID_EMAIL_ERROR_MSG,
} from '../libs/Constants';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Dropdown from '../components/Dropdown';
import {
 UpdateUser,
 UpdateUserProfileImage,
 getUserByToken,
} from '../service/ApiService';
import UserData from '../service/UserData';
import Modal from '../components/Modal';
import SuccessModal from '../components/SuccessModal';
import PopUp from '../components/PopUp';
import Strings from '../libs/Strings';

function CreateUserProfile() {
 const navigate = useNavigate();
 const [userData, setUserData] = useState<any>({});
 const [error, setError] = useState(false);
 const [showModal, setShowModal] = useState(false);
 const { account, Moralis } = useMoralis();
 const [userProfile, setUserProfile] = useState('');
 const [loading, setLoading] = useState(false);
 const [loader, setLoader] = useState(false);
 const [wrongfacebookLink, setwrongFackbookLink] = useState(false);
 const [wrongtwitterLink, setwrongTwitterLink] = useState(false);
 const [wronginstagramLink, setwronginstagramLink] = useState(false);
 const [agree1, setAgree1] = useState(false);
 const [agree2, setAgree2] = useState(false);
 const [gettc, setGettc] = useState(false);
 const [proimage, setProImage] = useState({});
 const [licenceModal, setLicenceModal] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [createValidInputModal, setCreateValidInputModal] = useState(false);
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );

 const onFileChange = (e: any) => {
  var image = e.target.files[DEFAULT_INDEX];
  var imageUrl = URL.createObjectURL(image);
  setUserProfile(imageUrl);
  setUserData((prev: any) => {
   prev = { ...prev, imageurl: imageUrl };
   return prev;
  });
  setProImage(e.target.files[DEFAULT_INDEX]);
 };

 const getUserById = async (id: any, token: any) => {
  const response = await getUserByToken(id, token);
  if (response && response[DEFAULT_INDEX].firstname === null) {
   let data = {
    bio: '',
    city: '',
    country: '',
    email: '',
    facebooklink: '',
    firstname: '',
    gender: '',
    googlelink: '',
    imageurl: '',
    instagramlink: '',
    lastname: '',
    twitterlink: '',
    walletaddress: '',
    zipcode: '',
   };
   setGettc(true);
   setUserData(data);
  } else if (response.length > 0) {
   setUserData(response[DEFAULT_INDEX]);
  } else {
   setError(true);
  }
  return null;
 };

 useEffect(() => {
  setLoading(true);
  if (Object.keys(UserData.getuserLoginInfo()).length > 0) {
   let userData = UserData.getuserLoginInfo();
   getUserById(userData.userId, userData.userToken);
  } else if (userLoginDetail) {
   getUserById(userLoginDetail.userId, userLoginDetail.userToken);
  } else {
   navigate('/');
  }
  setLoading(false);
 }, []);
 const onSuccessNavigate = () => {
  setShowModal(false);
  navigate(PROFILE_PAGE);
 };
 const saveUserProfileUpdate = async () => {
  setLoader(true);
  const userToken = localStorage.getItem('userToken');
  if (userProfile) {
   const datafile: any = proimage;
   const fileM = new Moralis.File((datafile as any).name, datafile);
   await fileM.saveIPFS();
   const imageURI = (fileM as any).ipfs();
   let userProfileimg = {
    ImageUrl: imageURI,
    UserId: userLoginDetail.userId,
   };

   const response = await UpdateUserProfileImage(userProfileimg, userToken);
   console.log(response);
  }

  let userProfileData = {
   Id: userLoginDetail.userId,
   FirstName: userData.firstname,
   LastName: userData.lastname ? userData.lastname : ' ',
   Gender: userData.gender ? userData.gender : ' ',
   Country: userData.country,
   City: userData.city ? userData.city : ' ',
   ZipCode: userData.zipcode,
   Bio: userData.bio ? userData.bio : ' ',
   EmailAddress: userData.email,
   LinkGoogle: ' ',
   LinkFacebook: userData.facebooklink,
   LinkTwitter: userData.twitterlink,
   LinkInstagram: userData.instagramlink,
  };

  const response = await UpdateUser(userProfileData, userToken);
  if (response[0].message === API_SUCCESS_MSG) {
   UserData.updateUserDetail(userProfileData);
   setLicenceModal(false);
   setShowModal(true);
   setLoader(false);
  } else {
   setValidationMsg(API_ERROR_MSG);
   setCreateValidInputModal(true);
   setLicenceModal(false);
   setLoader(false);
  }
 };
 const validate = () => {
  if (
   !userData.firstname ||
   !userData.email ||
   !userData.country ||
   !userData.zipcode
  ) {
   setValidationMsg(MANDATORY_FEILDS_ERROR_MSG);
   setCreateValidInputModal(true);
  } else if (userData.firstname.length > 100) {
   setValidationMsg(LARGE_NAME_ERROR_MSG);
   setCreateValidInputModal(true);
  } else if (userData.lastname != ' ' && userData.lastname.length > 100) {
   setValidationMsg(LARGE_LAST_NAME_ERROR_MSG);
   setCreateValidInputModal(true);
  } else if (userData.bio != ' ' && userData.bio.length > 80) {
   setValidationMsg(LONG_DESCRIPTION_MSG);
   setCreateValidInputModal(true);
  } else if (userData.email.length > 150) {
   setValidationMsg(LONG_EMAIL_MSG);
   setCreateValidInputModal(true);
  } else if (!new RegExp(EMAIL_REGEX).test(userData.email)) {
   setValidationMsg(VALID_EMAIL_ERROR_MSG);
   setCreateValidInputModal(true);
  } else if (
   userData.facebooklink &&
   !new RegExp(FACEBOOK_REGEX).test(userData.facebooklink)
  ) {
   const element = document.getElementById('exploreMoreSection');
   if (element != null) {
    element.scrollIntoView({
     behavior: 'smooth',
    });
   }
   setwrongFackbookLink(true);
   setwronginstagramLink(false);
   setwrongTwitterLink(false);
  } else if (userData.facebooklink && userData.facebooklink.length > 300) {
   setValidationMsg(MAX_FACEBOOK_LENGHT);
   setCreateValidInputModal(true);
  } else if (
   userData.instagramlink &&
   !new RegExp(INSTAGRAM_REGEX).test(userData.instagramlink)
  ) {
   const element = document.getElementById('exploreMoreSection');
   if (element != null) {
    element.scrollIntoView({
     behavior: 'smooth',
    });
   }
   setwrongFackbookLink(false);
   setwronginstagramLink(true);
   setwrongTwitterLink(false);
  } else if (userData.instagramlink && userData.instagramlink.length > 300) {
   setValidationMsg(MAX_INSTAGRAM_LENGHT);
   setCreateValidInputModal(true);
  } else if (
   userData.twitterlink &&
   !new RegExp(TWITTER_REGEX).test(userData.twitterlink)
  ) {
   const element = document.getElementById('exploreMoreSection');
   if (element != null) {
    element.scrollIntoView({
     behavior: 'smooth',
    });
   }
   setwrongFackbookLink(false);
   setwronginstagramLink(false);
   setwrongTwitterLink(true);
  } else if (userData.twitterlink && userData.twitterlink.length > 300) {
   setValidationMsg(MAX_TWITTER_LENGHT);
   setCreateValidInputModal(true);
  } else if (userData.zipcode.length > 50) {
   setValidationMsg(MAX_ZIP_CODE);
   setCreateValidInputModal(true);
  } else if (gettc) {
   setLicenceModal(true);
   setwrongFackbookLink(false);
   setwronginstagramLink(false);
   setwrongTwitterLink(false);
  } else {
   saveUserProfileUpdate();
   setwrongFackbookLink(false);
   setwronginstagramLink(false);
   setwrongTwitterLink(false);
  }
 };

 return (
  <div className="App darker">
   {loader ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}
   {loading ? (
    <div id="preloader">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : (
    <div>
     <Header userInfo={userData} />
     <div className="breadcumb-area clearfix auto-init UserProfile-Breadcumb">
      <div className="breadcumb-content">
       <div className="container h-100">
        <div className="row h-100 align-items-center">
         <div className="col-12">
          <nav
           aria-label="breadcrumb"
           className="breadcumb--con text-center breadcumb-con"
          >
           <h2 className="w-text title">{Strings.CreateYourProfile}</h2>
           <ol className="breadcrumb justify-content-center">
            <li className="breadcrumb-item">
             <Link to="/">{Strings.Home}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
             {Strings.CreateProfile}
            </li>
           </ol>
          </nav>
         </div>
        </div>
       </div>
      </div>
     </div>
     {licenceModal ? (
      <Modal
       isCheck={agree1}
       handle={() => setAgree1(!agree1)}
       isChecked={agree2}
       handleOnChange={() => setAgree2(!agree2)}
       submit={() => saveUserProfileUpdate()}
      />
     ) : null}
     <section className="contact_us_area userProfileMainContainer" id="contact">
      <div className="container">
       <div className="row">
        <div className="col-md-12">
         <div className="userProfile">
          {userData?.imageurl ? (
           <img src={userData?.imageurl} className="userProfileimage" alt="" />
          ) : (
           <img
            src={'img/authors/userImage.png'}
            className="userProfileimage"
            alt=""
           />
          )}
          <div className="imageCircle">
           <input
            type="file"
            className="inputFile"
            onChange={(e) => onFileChange(e)}
           />
           <img src="img/art-work/Vector.svg" alt="" />
          </div>
         </div>
        </div>
       </div>
       <div className="row">
        <div className="col-md-12 userProfileForm">
         <div className="userProfileFormContainer">
          <form className="userForm">
           <p className="gradient">{Strings.HiWelcomeToYourProfile}</p>
           <h2>{Strings.EditYourDetailsBelow}</h2>
           <div className="col-md-9 firstLastNameOuter">
            <div className="col-md-5 forPadding">
             <label>
              <p className="labelData">{Strings.FirstName}*</p>
             </label>
             <input
              value={userData.firstname}
              placeholder="First Name"
              type="text"
              className="inputClass"
              onChange={(e) =>
               setUserData((prev: any) => {
                prev = { ...prev, firstname: e.target.value };
                return prev;
               })
              }
             />
            </div>
            <div className="col-md-5 forPaddingRight">
             <label>
              <p className="labelData">{Strings.LastName}</p>
             </label>
             <input
              value={userData.lastname}
              placeholder="Last Name"
              type="text"
              className="inputClass"
              onChange={(e) =>
               setUserData((prev: any) => {
                prev = { ...prev, lastname: e.target.value };
                return prev;
               })
              }
             />
            </div>
           </div>
           <label>
            <p className="labelData">{Strings.TellUsAboutYourself}</p>
           </label>
           <input
            value={userData.bio}
            placeholder=""
            type="text"
            className="inputClass"
            onChange={(e) =>
             setUserData((prev: any) => {
              prev = { ...prev, bio: e.target.value };
              return prev;
             })
            }
           />
           <label>
            <label>
             <p className="labelData">{Strings.EmailAddress}*</p>
            </label>
            <input
             value={userData.email}
             placeholder="Email"
             type="text"
             className="inputClass"
             onChange={(e) =>
              setUserData((prev: any) => {
               prev = { ...prev, email: e.target.value };
               return prev;
              })
             }
            />
            <label>
             <p className="labelData">{Strings.WalletAddress}*</p>
            </label>
            <input
             type="text"
             className="inputClass"
             value={getEllipsisTxt(account, 6)}
            />
            <p className="labelData">{Strings.Gender}</p>
            <Dropdown
             setGender={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, gender: e };
               return prev;
              })
             }
             dropDownTitle={
              userData.gender ? userData.gender : '-- Choose Gender --'
             }
             firstOption={Strings.Male}
             secondOption={Strings.Female}
             classes="inputClass"
             thirdOption={Strings.other}
             placeholder={userData.gender}
            />
           </label>

           <label>
            <p className="labelData">{Strings.Country}*</p>
            <CountryDropdown
             defaultOptionLabel={
              userData.country ? userData.country : Strings.SelectYourCountry
             }
             classes="inputClass"
             value={userData.country}
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, country: e };
               return prev;
              })
             }
            />
           </label>

           <label>
            <p className="labelData">{Strings.City}</p>
            <RegionDropdown
             country={userData.country ? userData.country : ''}
             classes="inputClass"
             defaultOptionLabel={userData.city}
             value={userData.city}
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, city: e };
               return prev;
              })
             }
            />
           </label>

           <label id="exploreMoreSection">
            <p className="labelData">{Strings.ZipCode}*</p>
            <input
             value={userData.zipcode}
             type="text"
             className="inputClass"
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, zipcode: e.target.value };
               return prev;
              })
             }
            />
           </label>
           <div className="myText">
            <label className="label">
             <p className="labelData">{Strings.YourFacebookPage}</p>
             <p className="socialIcons">
              <span className="fa fa-facebook-f"></span>
             </p>
            </label>
            {userData.facebooklink &&
            userData.facebooklink.length > 0 ? null : (
             <span className="tooltiptext">
              Please Enter link format as 'https://www.facebook.com/'
             </span>
            )}

            <input
             value={userData.facebooklink}
             placeholder=""
             type="text"
             className={wrongfacebookLink ? 'inputClass2' : 'inputClass'}
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, facebooklink: e.target.value };
               return prev;
              })
             }
            />
           </div>
           <div className="myText">
            <label className="label">
             <p className="labelData">{Strings.YourTwitterPage}</p>
             <p className="socialIcons">
              <span className="fa fa-twitter"></span>
             </p>
            </label>
            {userData.twitterlink && userData.twitterlink.length > 0 ? null : (
             <span className="tooltiptext">
              Please Enter link format as 'https://twitter.com/'
             </span>
            )}
            <input
             value={userData.twitterlink}
             placeholder=""
             type="text"
             className={wrongtwitterLink ? 'inputClass2' : 'inputClass'}
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, twitterlink: e.target.value };
               return prev;
              })
             }
            />
           </div>
           <div className="myText">
            <label className="label">
             <p className="labelData">{Strings.YourInstagramPage}</p>
             <p className="socialIcons">
              <span className="fa fa-instagram"></span>
             </p>
            </label>
            {userData.instagramlink &&
            userData.instagramlink.length > 0 ? null : (
             <span className="tooltiptext">
              Please Enter link format as 'https://www.instagram.com/'
             </span>
            )}
            <input
             value={userData.instagramlink}
             placeholder=""
             type="text"
             className={wronginstagramLink ? 'inputClass2' : 'inputClass'}
             onChange={(e: any) =>
              setUserData((prev: any) => {
               prev = { ...prev, instagramlink: e.target.value };
               return prev;
              })
             }
            />
           </div>
           <div className="buttonContainer">
            <div className="more-btn" onClick={() => validate()}>
             {Strings.Save}
            </div>
           </div>
          </form>
         </div>
        </div>
       </div>
      </div>

      {createValidInputModal ? (
       <PopUp
        message={validationMsg}
        navigate={() => {
         setCreateValidInputModal(false);
        }}
       />
      ) : null}

      {showModal ? (
       <SuccessModal
        close={() => onSuccessNavigate()}
        modalImage={'img/art-work/rocket.png'}
        ModalText={Strings.Thankyou}
        buttonText={Strings.LetGo}
        navigate={() => onSuccessNavigate()}
       />
      ) : null}
     </section>

     <Footer />
    </div>
   )}
  </div>
 );
}

export default CreateUserProfile;
