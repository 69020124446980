import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useMoralis } from 'react-moralis';
import { CreateUserCollectionByToken } from '../service/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import {
 PROFILE_PAGE,
 COLLECTION_MAX_IMAGE_SIZE,
 DESCRIPTION_MAX_SIZE,
 FILE,
 COLLECTION,
 LARGE_IMAGE_ERROR_MSG,
 EMPTY_FIELDS_ERROR_MSG,
 LONG_DESCRIPTION_ERROR_MSG,
 ITEM_NAME_MAX_SIZE,
 API_SUCCESS_MSG,
 IMAGE_EXTENSION_ERROR_MSG,
 LARGE_COLLECTION_NAME_ERROR,
} from '../libs/Constants';
import UserData from '../service/UserData';
import Footer from '../components/Footer';
import PopUp from '../components/PopUp';
import { getUploadedImage } from '../libs/ImageUploadService';
import UserProfile from '../components/UserProfile';
import '../App.css';
import Strings from '../libs/Strings';

function CreateCollectionScreen() {
 const navigate = useNavigate();
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [validationMsg, setValidationMsg] = useState('');
 const [name, setName] = useState('');
 const [description, setDescription] = useState('');
 const [userNFT, setUserNFT] = useState<any>({});
 const [loader, setLoader] = useState(false);
 const [UserDetails, setUserDetails] = useState<any>({});
 const [proimage, setProImage] = useState({});
 const [extension, setExtension] = useState(null);
 const [isCopy, setIsCopy] = useState(false);
 const [imageInformationObject, setImageInformationObject] = useState<any>({});
 const { Moralis, account } = useMoralis();
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const useNftDetail: any = localStorage.getItem('userNFts');

 const setUploadImage = async () => {
  const fileInput = document.getElementById(FILE);
  const datafile = (fileInput as any).files[0];
  const imageInformation = getUploadedImage(fileInput);
  setImageInformationObject(imageInformation);
  setExtension(datafile.name.split('.')[1].toUpperCase());
  setProImage(datafile);
 };

 const showValidateModal = async () => {
  if (name === '' || description === '' || imageInformationObject.name === '') {
   setValidationMsg(EMPTY_FIELDS_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (name.length > ITEM_NAME_MAX_SIZE) {
   setValidationMsg(LARGE_COLLECTION_NAME_ERROR);
   setCreateValidItemModal(true);
  } else if (imageInformationObject.Size > COLLECTION_MAX_IMAGE_SIZE) {
   setValidationMsg(LARGE_IMAGE_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (
   extension !== 'JPG' &&
   extension !== 'PNG' &&
   extension !== 'JPEG' &&
   extension !== 'GIF' &&
   extension !== 'SVG'
  ) {
   setValidationMsg(IMAGE_EXTENSION_ERROR_MSG);
   setCreateValidItemModal(true);
  } else if (description.length > DESCRIPTION_MAX_SIZE) {
   setValidationMsg(LONG_DESCRIPTION_ERROR_MSG);
   setCreateValidItemModal(true);
  } else {
   setLoader(true);
   try {
    const datafile: any = proimage;
    const fileM = new Moralis.File((datafile as any).name, datafile);
    await fileM.saveIPFS();
    const imageURI = (fileM as any).ipfs();
    CreateUserCollection(name, imageURI, description);
   } catch (error: unknown) {
    if (JSON.stringify((error as any).message)) {
     setValidationMsg(JSON.stringify((error as any).message));
     setCreateValidItemModal(true);
    }
    setLoader(false);
   }
  }
 };

 const getUserprofile = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0 &&
   UserData.getuserDetail() != null
  ) {
   setUserDetails(UserData.getuserDetail());
   setUserNFT(UserData.getuserNFt());
  } else if (userProfileDetails !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
   setUserNFT(JSON.parse(useNftDetail));
  } else {
   navigate('/');
  }
 };

 useEffect(() => {
  getUserprofile();
 }, []);

 const navigateToProfile = () => {
  navigate(PROFILE_PAGE, {
   state: [
    {
     id: userLoginDetail.userId,
     type: COLLECTION,
     token: userLoginDetail.userToken,
    },
   ],
  });
 };

 const CreateUserCollection = async (
  name: any,
  image: any,
  description: any
 ) => {
  let data = {
   Name: name,
   Description: description,
   ImageUrl: image,
   UserId: userLoginDetail.userId,
  };
  const response = await CreateUserCollectionByToken(
   data,
   userLoginDetail.userToken
  );
  if (response[0].msg === API_SUCCESS_MSG) {
   setLoader(false);
   navigateToProfile();
  } else {
   setLoader(false);
   console.log('error');
  }
 };

 return (
  <div className="App darker">
   {loader ? (
    <div id="preloaderload">
     <div className="preload-content">
      <div>
       <img
        className="animation-loader"
        src="../img/icons/animationloader.gif"
        alt=""
       />
      </div>
     </div>
    </div>
   ) : null}

   <Header userInfo={UserDetails} />
   <div className="breadcumb-area clearfix auto-init collectionBreadcumb">
    <div className="breadcumb-content">
     <div className="container h-100">
      <div className="row h-100 align-items-center">
       <div className="col-12">
        <nav aria-label="breadcrumb" className="breadcumb--con text-center">
         <h2 className="w-text title">{Strings.CreateCollection}</h2>
         <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item">
           <Link to="/">{Strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
           {Strings.CreateCollection}
          </li>
         </ol>
        </nav>
       </div>
      </div>
     </div>
    </div>
   </div>
   <section className="blog-area section-padding-100">
    <div className="container">
     <div className="row">
      <UserProfile
       UserDetails={UserDetails}
       userNFTdetail={userNFT}
       Account={account}
       onCopy={() => setIsCopy(true)}
      />

      <div className="col-12 col-lg-8">
       <div className="creator-sec dd-bg">
        <div className="who-we-contant">
         <div className="dream-dots text-left" data-wow-delay="0.2s">
          <span className="gradient-text">{Strings.WelcometoTheWorld}</span>
         </div>
         <h4 className="uploadText">{Strings.UploadImage}</h4>
         <p className="requireFields">
          <span className="star">*</span>
          {Strings.Requiredfields}
         </p>
         <p className="w-text uploadItem">{Strings.UploadItemFile}</p>
        </div>
        <div className="contact_form">
         <div className="row">
          <div className="col-12">
           <div id="success_fail_info"></div>
          </div>

          <div className="col-12 col-md-12">
           <div className="group-file">
            <p className="g-text">{Strings.SupportedCollectionImage}</p>
            <div className="new_Btn more-btn">
             <span className="file-wrapper new_Btn more-btn">
              <input
               type="file"
               id="file"
               name="upload"
               className="fileChoose"
               onChange={(e) => setUploadImage()}
              />
              <span className="button1">
               {imageInformationObject.name
                ? imageInformationObject.name
                : Strings.Uploadfile}
              </span>
             </span>
            </div>
           </div>
          </div>
          <div className="col-12 col-md-12">
           <div className="group">
            <input
             type="text"
             name="name"
             id="name"
             onChange={(evt: any) => {
              setName(evt.target.value);
             }}
             required
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.CollectionName}
             <span className="star">*</span>
            </label>
           </div>
          </div>

          <div className="col-12">
           <div className="group">
            <textarea
             name="Description"
             id="Description"
             onChange={(evt: any) => {
              setDescription(evt.target.value);
             }}
             required
            ></textarea>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
             {Strings.CollectionDescription}
             <span className="star">*</span>
             <span className="appearText">{Strings.upto300character}</span>
            </label>
           </div>
          </div>

          <div className="col-12 text-center">
           <button
            className="more-btn mb-15"
            onClick={() => showValidateModal()}
           >
            {Strings.Create}
           </button>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    {createValidItemModal ? (
     <PopUp
      message={validationMsg}
      navigate={() => {
       setCreateValidItemModal(false);
      }}
     />
    ) : null}
    {isCopy ? (
     <PopUp
      message={Strings.Copied}
      navigate={() => {
       setIsCopy(false);
      }}
     />
    ) : null}
   </section>
   <Footer />
  </div>
 );
}

export default CreateCollectionScreen;
