import {
 CONTENT_TYPE,
 PUBLIC_JWT_TOKEN,
 BASE_URL,
 POST_REQUEST,
 GET_REQUEST,
 PUT,
} from './Environment';

export const START_INDEX = 1;
export const LAST_INDEX = 20;
export const DEFAULT_INDEX = 0;
export const FILE = 'file';
export const MY_COLLECTION_LAST_INDEX = 8;
export const PROFILE_PAGE = '/profile';
export const ITEM_DETAIL_PAGE = '/ItemDetail';
export const WALLET_CONNECT = '/WalletConnect';
export const DISCOVER_PAGE = '/discover';
export const CREATE_USER_PROFILE = '/userProfile';
export const TOP_SELLERS_SCREEN = '/topSellersScreen';
export const NFTBYNAME_STARTINDEX = 1;
export const NFTBYNAME_LASTINDEX = 20;
export const COLLECTION = 'Collection';
export const CREATOR_PAGE = '/creatorScreen';
export const CREATE_ITEM_SCREEN = '/createItemScreen';
export const CREATOR_PROFILE_SCREEN = '/creatorProfileScreen';
export const CREATE_COLLECTION_SCREEN = '/createCollectionScreen';
export const MY_COLLECTION_SCREEN = '/myCollectionScreen';
export const CONTACT_US = '/contactUs';
export const CONTRACT_GENERATION = '/contract/generation';
export const CREATE_MULTIPLE_ITEMS_SCREEN = '/createMultipleItmesScreen';
export const EMPTY_FIELDS_ERROR_MSG = 'Please Fill All Fields';
export const LARGE_IMAGE_ERROR_MSG = 'Please upload file less than 20 MB';
export const LARGE_ITEM_IMAGE_ERROR_MSG = 'Please upload file less than 100 MB';
export const NO_COLLECTION_ERROR = 'Please create atleast one collection.';
export const USER_DENIED_TRANSACTION = 'User denied transaction signature';
export const LARGE_ITEM_NAME = 'Item Name can be up to 100 characters';
export const MORE_RELEVANT_FIRST_INDEX = 1;
export const VALID_AMOUNT_ERR_MSG = 'Please Enter A Valid Amount';
export const API_SUCCESS_MSG = 'success';
export const MORE_RELEVANT_LAST_INDEX = 4;
export const MORALIS_ERROR_CODE = '4001';
export const USER_DOES_NOT_EXIST = 'User does not exist';
export const THANK_YOU_FORSHARING =
 'Thank you for sharing the details with us.We shall be in touch with you at earliest!';
export const FILENAME_CONTAINS_INVALID_CHARACTERS =
 'Filename contains invalid characters.';
export const LARGE_NAME_ERROR_MSG = 'First Name can be up to 100 characters';
export const EMAIL_REGEX = '[a-z0-9]+@[a-z]+.[a-z]{2,3}';
export const VALID_EMAIL_ERROR_MSG = 'Please Enter Valid Email Address';
export const IMAGE_EXTENSION_ERROR_MSG =
 'Please Enter Valid Files For Image or Tours: JPG, JPEG, PNG, GIF, SVG';
export const LESS_ITEM_ERROR_MSG = 'Please Create Atleast 2 Item';
export const MANDATORY_FEILDS_ERROR_MSG = 'Please Enter mandatory fields';
export const VALID_MUSIC_FILE_ERROR_MSG =
 'Please Enter Valid Files For Music: MP3, WAV';
export const LARGE_LAST_NAME_ERROR_MSG =
 'Last Name can be up to 100 characters';
export const LONG_DESCRIPTION_MSG = 'Description can be up to 80 characters';
export const LONG_EMAIL_MSG = 'Email length can be up to 150 characters';
export const VALID_VIDEO_FILE_ERROR_MSG =
 'Please Enter Valid Files For Video: WEBM, MP4';
export const LATIN_RANGE_ERROR_MSG =
 'Error: Failed to execute.The string to be encoded contains characters outside of the Latin 1 range.';
export const ASPECTRATIO_ERROR_MSG =
 'Please maintain aspectRatio- 4:3(like 400*300)';
export const UNSUCCESSFUL_TRANSACTION =
 'Unsuccessful transaction.Please contact Admin : admin123@gmail.com.';
export const BLOCKS_ERROR =
 'Error: Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!';
export const OFFER_REQUEST =
 'Your Offer request has been sent to the item owner.You shall be notified shortly';
export const LONG_DESCRIPTION_ERROR_MSG =
 'Description can be up to 300 characters';
export const LONG_EMAIL_ERROR_MSG = 'Email length can be up to 150 characters';
export const CREATE_ABI = '0xB281685ce93A60c80e7093E419cf25b41534c0D2';
export const BUY_ABI = '0x53B14CA0F4Ce5e23C2BCD5Ba202Bf0BD71f62cC0';
export const FACEBOOK_REGEX =
 '(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?';
export const INSTAGRAM_REGEX =
 '(?:(?:http|https)://)?(?:www.)?instagram.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?';
export const TWITTER_REGEX =
 '(?:(?:http|https)://)?(?:www.)?twitter.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?';
export const MAX_FACEBOOK_LENGHT = 'Facebook link can be up 300 characters';
export const MAX_INSTAGRAM_LENGHT = 'Instagram link can be up 300 characters';
export const MAX_TWITTER_LENGHT = 'Twitter link can be up 300 characters';
export const MAX_ZIP_CODE = 'Zipcode can be up to 50 characters';
export const LARGE_COLLECTION_NAME_ERROR =
 'Collection Name can be up to 100 characters';
export const NFT_NOT_AVAILABLE = 'NFT is not available';
export const ETHEREUM_ERROR = 'Something went wrong from ethereum server';
export const API_ERROR_MSG = 'Sorry !Something wants wrong';
export const OWNER_WALLET_ADDRESS =
 '0x6c19Cf6d7B4b3Be886286Dd63C5c0Dfca15c091f';
export const COLLECTION_LAST_INDEX = 6;
export const COLLECTION_MAX_IMAGE_SIZE = 20971520;
export const DESCRIPTION_MAX_SIZE = 300;
export const ITEM_NAME_MAX_SIZE = 100;
export const COLLECTION_ARRAY = [
 { key: 1, type: 'All' },
 { key: 2, type: 'Music' },
 { key: 3, type: 'Video' },
 { key: 4, type: 'Image' },
 { key: 5, type: 'Tours' },
];
export const CREATE_ITEM = [
 { key: 1, type: 'Image' },
 { key: 2, type: 'Music' },
 { key: 3, type: 'Video' },
 { key: 4, type: 'Tours' },
];
export const OUR_COLLECTION_ARRAY = [
 { key: 1, type: 'All NFTs' },
 { key: 2, type: 'Music' },
 { key: 3, type: 'Video' },
 { key: 4, type: 'Image' },
 { key: 5, type: 'Tours' },
];

export const USER_COLLECTION = [
 { key: 1, type: "My NFT's" },
 { key: 2, type: 'Favourites' },
 { key: 3, type: 'Purchased' },
];
export const CREATOR_COLLECTION = [
 { key: 1, type: 'Collection' },
 { key: 2, type: "My NFT's" },
 { key: 3, type: 'Favourites' },
 { key: 4, type: 'Purchased' },
 { key: 5, type: 'Sold' },
];

export const NOT_LOGGEDIN_MSG = 'Not Logged in';

export const HEADERS = {
 Accept: CONTENT_TYPE,
 'Content-Type': CONTENT_TYPE,
 'x-access-token': PUBLIC_JWT_TOKEN,
};

export const LOGGEDINHEADER = {
 Accept: CONTENT_TYPE,
 'Content-Type': CONTENT_TYPE,
 'x-access-token': localStorage.getItem('userToken'),
 'Access-Control-Allow-Origin': '*',
};

export const postRequest = async (BODY, additionalUrl) => {
 try {
  const response = await fetch(BASE_URL + additionalUrl, {
   method: POST_REQUEST,
   headers: HEADERS,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  return json;
 } catch (err) {
  console.log('error is', err);
  return err;
 }
};

export const LoggedInPostRequest = async (BODY, additionalUrl) => {
 console.log('request parms are', BODY, LOGGEDINHEADER, additionalUrl);
 try {
  const response = await fetch(BASE_URL + additionalUrl, {
   method: POST_REQUEST,
   headers: LOGGEDINHEADER,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  return json;
 } catch (err) {
  console.log('error is  from constant', err);
  return null;
 }
};

export const LoggedInPatchRequest = async (BODY, additionalUrl, token) => {
 try {
  const response = await fetch(BASE_URL + additionalUrl, {
   method: PUT,
   headers: {
    Accept: CONTENT_TYPE,
    'Content-Type': CONTENT_TYPE,
    'x-access-token': token,
   },
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  return json;
 } catch (err) {
  console.log('error is  from constant', err);
  return null;
 }
};

export const getRequest = async (additionalUrl, BODY) => {
 console.log('get request params are', additionalUrl, BODY);
 try {
  const response = await fetch(BASE_URL + additionalUrl, {
   method: GET_REQUEST,
   headers: HEADERS,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  return json;
 } catch (err) {
  console.log('error is', err);
  return false;
 }
};

export const LoggedInPost = async (BODY, additionalUrl, token) => {
 try {
  const response = await fetch(BASE_URL + additionalUrl, {
   method: POST_REQUEST,
   headers: {
    Accept: CONTENT_TYPE,
    'Content-Type': CONTENT_TYPE,
    'x-access-token': token,
   },
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  return json;
 } catch (err) {
  console.log('error is  from constant', err);
  return null;
 }
};
