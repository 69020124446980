import React from 'react';
import { Link } from 'react-router-dom';
import Strings from '../libs/Strings';
export default function PopUp(props: any) {
 return (
  <div className="modalOverlay">
   <div
    className={
     props.isProfileScreen ? 'requestModalpopup2 popUp' : 'requestModalpopup2 '
    }
   >
    <h4>{props.message} </h4>
    <a onClick={props.navigate} className="btn more-btn exploreBtn">
     {Strings.OK}
    </a>
   </div>
  </div>
 );
}
