import { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
 START_INDEX,
 LAST_INDEX,
 DEFAULT_INDEX,
 PROFILE_PAGE,
 CREATOR_PROFILE_SCREEN,
} from '../libs/Constants';
import { getTopSellersOnIndexBase } from '../service/ApiService';
import Footer from '../components/Footer';
import ErrorPage from '../components/Error';
import UserData from '../service/UserData';
import { useMoralis } from 'react-moralis';
import { useNavigate } from 'react-router-dom';
import Strings from '../libs/Strings';

function TopSellersScreen() {
 const navigate = useNavigate();
 const [loading, setLoading] = useState(false);
 const [UserDetails, setUserDetails] = useState<any>({});
 const [error, setError] = useState(false);
 const userProfileDetails: any = localStorage.getItem('userDetails');
 const [lastIndex, setLastIndex] = useState(LAST_INDEX);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const [loadMore, setLoadMore] = useState(false);
 const [topSellersData, setTopSellersData] = useState<any>([]);
 const { isAuthenticated } = useMoralis();
 const userLoginDetail: any = JSON.parse(
  localStorage.getItem('UserloginDetail') as any
 );

 const getUserProfileData = async () => {
  if (
   Object.keys(UserData.getuserDetail()).length > 0 &&
   Object.keys(UserData.getuserNFt()).length > 0
  ) {
   setUserDetails(UserData.getuserDetail());
  } else if (userProfileDetails !== null) {
   setUserDetails(JSON.parse(userProfileDetails));
  }
 };
 useEffect(() => {
  getUserProfileData();
 }, []);

 const getTopSellersByIndex = async () => {
  const response = await getTopSellersOnIndexBase(START_INDEX, lastIndex);
  if (response && response[DEFAULT_INDEX].length > 0) {
   setTopSellersData(response[DEFAULT_INDEX]);
   setLoading(false);
  } else {
   setLoading(false);
  }
 };
 const getMoreItems = async () => {
  setLoadMore(true);
  const response = await getTopSellersOnIndexBase(
   lastIndex + 1,
   lastIndex + LAST_INDEX
  );
  if (response && response[DEFAULT_INDEX].length > 0) {
   setLastIndex(lastIndex + 20);
   setTopSellersData([...topSellersData, ...response[DEFAULT_INDEX]]);
   setLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoadMore(false);
  }
 };

 const navigateToCreator = (userId: any) => {
  navigate(CREATOR_PROFILE_SCREEN, {
   state: { CreatorId: userId },
  });
 };

 const OnTopSellerClick = (id: any) => {
  if (isAuthenticated) {
   let localId = userLoginDetail.userId;
   if (localId == id) {
    navigate(PROFILE_PAGE);
   } else {
    navigateToCreator(id);
   }
  } else {
   navigateToCreator(id);
  }
 };
 useEffect(() => {
  setLoading(true);
  getTopSellersByIndex();
 }, []);

 return (
  <div className="App darker">
   <div className="App darker">
    {error ? (
     <ErrorPage onReload={() => window.location.reload()} />
    ) : (
     <div className="App darker">
      <Header userInfo={UserDetails} />

      <section className="features section-padding-100 discoverOuter">
       <div className="container padding-0-50">
        <div className="container_div">
         <h2>{Strings.TopSeller}</h2>
        </div>
        <table
         id="dtVerticalScroll"
         className="table table-responsive sellerTable"
         cellSpacing="0"
        >
         <tr>
          <th className="topSeller_collection">{Strings.Collection}</th>

          <th>{Strings.Volume}</th>
          <th>{Strings.Amount}</th>
         </tr>
         {loading ? (
          <div id="preloaderloadAreaSpecificHome topsellerLoader">
           <div className="preload-contentAreaSpecific">
            <div>
             <img
              className="animation-loader"
              src="../img/icons/animationloader.gif"
              alt=""
             />
            </div>
           </div>
          </div>
         ) : topSellersData.length > 0 ? (
          <>
           {topSellersData.map((item: any, index: any) => {
            return (
             <tr>
              <td className="topSeller_collectionitem">
               <div
                className="author-item auth__item"
                onClick={() => OnTopSellerClick(item.UserId)}
               >
                <div className="author-rank">
                 {' '}
                 {index >= 9 ? null : 0}
                 {index + 1}
                </div>
                <div className="author-img">
                 <img
                  src={
                   item.profile_image === null
                    ? 'img/art-work/user.png'
                    : item.profile_image
                  }
                  width="70"
                  alt=""
                 />
                </div>
                <div className="author-info">
                 <a>
                  <h5 className="author-name Users12">
                   {item.sellername == null
                    ? Strings.MintishUser
                    : item.sellername}
                  </h5>
                 </a>
                </div>
               </div>
              </td>
              <td>{item.volume}</td>
              <td>
               <img
                className="eth-icon"
                src="img/art-work/ethimage.png"
                alt=""
               />
               {item.amount}
              </td>
             </tr>
            );
           })}
           <>
            {hideLoadMore || loading ? null : (
             <div className="col-12 col-lg-12 text-center">
              {loadMore ? (
               <div id="SpecificAreaLoader" className=" sellerShowMore-loader">
                <div>
                 <img
                  className="animation-loader2"
                  src="../img/icons/animationloader.gif"
                  alt=""
                 />
                </div>
               </div>
              ) : null}
              <button
               onClick={() => getMoreItems()}
               className="btn more-btn fadeInUp"
               data-wow-delay="0.6s"
              >
               {Strings.LoadMore}
              </button>
             </div>
            )}
           </>
          </>
         ) : (
          <div>{Strings.NODATAFOUND}</div>
         )}
        </table>
       </div>
      </section>
      <Footer />
     </div>
    )}
   </div>
  </div>
 );
}

export default TopSellersScreen;
