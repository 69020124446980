import Strings from '../libs/Strings';

export default function WrongEventModal(props: any) {
 return (
  <div className="modalOverlay">
   <div className="requestModalpopup">
    <img src="img/art-work/wrongwent.jpg" alt="" />
    <h4>{props.message} </h4>

    <a onClick={props.navigate} className="btn more-btn exploreBtn">
     {Strings.OK}
    </a>
   </div>
  </div>
 );
}
